
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { isValidEmail } from '../../Helpers/TeamsHelper'

import useTranslation from '../../Hooks/UseTranslation'

import InputFormControl from '../InputFormControl/InputFormControl'

const TeamMembersSelector = (props: any) => {

    const { t } = useTranslation()

    const [ criteria, setCriteria ] = useState('')

    const [ inputFocus, setInputFocus ] = useState(false)
    const [ inputBlurred, setInputBlurred ] = useState(false)

    const [ searched, setSearched ] = useState(false)
    const [ searching, setSearching ] = useState(false)

    const [ mailEnabled, setMailEnabled ] = useState(false)
    const [ email, setEmail ] = useState('')

    const [ sendingMail, setSendingMail ] = useState(false)

    const searchUsers = async () => {

        if (!criteria) {

            return
        }

        setSearching(true)

        await props.searchDispatch(criteria)

        setSearching(false)
        setSearched(true)
    }

    const sendMail = async () => {

        if (!isValidEmail(email)) {

            Swal.fire({
                title: t('TEAMMEMBER_SELECTOR:InvalidMailAlertTitle'),
                text: t('TEAMMEMBER_SELECTOR:InvalidMailAlertContent'),
                icon: 'error',
                // showCancelButton: true,
                // cancelButtonText: 'Aceptar',
                confirmButtonText: t('TEAMMEMBER_SELECTOR:InvalidMailAlertConfirmButton'),
                width: '500px',
            })

            return
        }

        setSendingMail(true)

        await props.sendMailDispatch(email)

        setSendingMail(false)
    }

    const cancel = () => {

        setCriteria('')
        setInputFocus(false)
        setInputBlurred(false)

        setSearched(false)

        props.onHide()
    }

    const handleAddUserToTeam = async (user: any) => {

        await props.onAddUser(user)

        props.searchDispatch(criteria)
    }

    return (
        <>

            <Modal
                show={props.show}
                onHide={() => cancel()}
                backdrop="static"
                size="lg"
                keyboard={true}
                onEntered={() => setTimeout(() => setInputFocus(true), 300)}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('TEAMMEMBER_SELECTOR:Title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="form-group row">
                            <div className="col-12">
                                <div className="input-group">
                                <InputFormControl
                                    autoFocus={true}
                                    placeholder={t('TEAMMEMBER_SELECTOR:InputCriteriaPlaceholder')}
                                    value={criteria}
                                    onChange={(value: string) => setCriteria(value)}
                                    onEnter={() => searchUsers()}
                                    onBlur={() => setInputBlurred(true)}
                                    style={{ height: 34 }}
                                />
                                    <div className="input-group-append">
                                        <button className="btn btn-normal"
                                            onClick={() => searchUsers()}
                                        >
                                            <span className="icon-buscar"></span> {t('TEAMMEMBER_SELECTOR:SearchButton')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <ProjectTeamsSearchResult
                            criteria={criteria}
                            users={props.users}
                            searchStatus={props.usersLoadResultStatus}
                            mailSent={this.state.mailSent}
                            onAddUser={(user: any) => this.inviteUserToTeam(user)}
                            onSendEmail={(email: string) => this.sendInviteMail(email)}
                        /> */}
                        {searching && t('TEAMMEMBER_SELECTOR:Searching')}
                        {searched && (
                            <>
                                {!props.users.length ? (
                                    <>
                                        <div className="alert alert-secondary">
                                            {t('TEAMMEMBER_SELECTOR:NoUsersFound')}
                                        </div>
                                        {!mailEnabled ? (
                                            <button className="btn btn-raised btn-raised-primary"
                                                onClick={() => setMailEnabled(true)}
                                            >
                                                <i className="far fa-envelope fa-fw"></i> {t('TEAMMEMBER_SELECTOR:SendMailInvitationButton')}
                                            </button>
                                        ): (
                                            <div className="input-group">
                                                <InputFormControl
                                                    type="email"
                                                    placeholder={t('TEAMMEMBER_SELECTOR:InputMailPlaceholder')}
                                                    value={email}
                                                    onChange={(value: string) => setEmail(value)}
                                                    onEnter={() => sendMail()}
                                                    autoFocus={true}
                                                    disabled={sendingMail}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-raised btn-raised-primary"
                                                        onClick={() => sendMail()}
                                                        disabled={sendingMail}
                                                    >
                                                        {sendingMail ? (
                                                            <>
                                                                <i className="fas fa-spinner fa-spin fa-fw"></i> {t('TEAMMEMBER_SELECTOR:SendingInvitationButton')}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="far fa-envelope fa-fw"></i> {t('TEAMMEMBER_SELECTOR:SendInvitationButton')}
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {t('TEAMMEMBER_SELECTOR:UserListNameColumn')}
                                                </th>
                                                <th style={{ width: '160px'}}>
                                                    {t('TEAMMEMBER_SELECTOR:UserListActionColumn')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.users.map((user: any, i: number) => (
                                                <tr key={i}>
                                                    <td>{user.name} {user.surname}</td>
                                                    <td>
                                                        {user.isInTeam &&  t('TEAMMEMBER_SELECTOR:UserListUserAlreadyInTeam')}
                                                        {!user.isInTeam && user.hasPendingInvite && t('TEAMMEMBER_SELECTOR:UserListUserIsAlreadyInvited')}
                                                        {!user.isInTeam && !user.hasPendingInvite && (
                                                            <button className="btn btn-secondary"
                                                                onClick={() => handleAddUserToTeam(user)}
                                                            >
                                                                <span className="icon-agregar"></span> {' '}
                                                                {user._id !== props.myId ? t('TEAMMEMBER_SELECTOR:UserListInviteUserButton') : t('TEAMMEMBER_SELECTOR:UserListInviteMeButton')}
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary"
                        onClick={() => cancel()}
                    >
                        <span className="icon-cancelar"></span> {' '}
                        {t('TEAMMEMBER_SELECTOR:CloseButton')}
                    </button>
                    {/* <button className="btn btn-primary"
                        onClick={() => addUserToTeam()}
                        // disabled={state.teamName === ''}
                    >
                        <span className="icon-guardar"></span> Agregar
                    </button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TeamMembersSelector
