import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Tab, Nav, Button, Card, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";

import config from "../../config";
import navigation from "../../navigation";

import { useAuth } from "../../Hooks/UseAuth";
import { useQuery } from "../../Hooks/UseQuery";
import useTranslation from "../../Hooks/UseTranslation";

import SessionActions from "../../Redux/Actions/SessionActions";
import SessionService from "../../Services/SessionService";

import InterfaceActions from "../../Redux/Actions/InterfaceActions";
import ProjectsActions from "../../Redux/Actions/ProjectsActions";

import MainLayout from "../../Components/MainLayout/MainLayout";
import InputFormControl from "../../Components/InputFormControl/InputFormControl";
import Toast from "../../Components/Toast/Toast";
import AvatarUploader from "../../Components/AvatarUploader/AvatarUploader";
import MainLayoutContentHeader from '../../Components/MainLayout/MainLayoutContentHeader';
import MainLayoutContentBody from '../../Components/MainLayout/MainLayoutContentBody';
import { setLanguage } from "../../Redux/Actions/TranslationsActions";

const ProfilePage = () => {

  const { t, language } = useTranslation();

  const { userData, update, logout } = useAuth()

  const query = useQuery();
  const project_id = query.get("project_id");

  const dispatch = useDispatch();

  const project = useSelector((state: any) => state.ProjectsReducer.rowData);
  const loadProjectStatus = useSelector(
    (state: any) => state.ProjectsReducer.loadRowStatus
  );

  const [ newUserData, setNewUserData ] = useState({
    name: userData?.name ?? "",
    surname: userData?.surname ?? "",
    email: userData?.email ?? "",
    phone: userData?.phone ?? "",
    workPhone: userData?.workPhone ?? "",
    address: userData?.address ?? "",
    avatar: userData?.avatar ?? "",
    account: userData?.account ?? "",
   });

  const [ avatarUploaded, setAvatarUploaded ] = useState<any>({});

  const [ editable, setEditable ] = useState(false);
  const [ editablePass, setEditablePass ] = useState(false);

  const [ updating, setUpdating ] = useState(false);
  const [ changingPassword, setChangingPassword ] = useState(false);

  const [ pass, setPass ] = useState({
    actual: "",
    new: "",
    new2: "",
  });

  //CODIGO NUEVO
  const [ tab, setTab ] = useState('USUARIO')
  const changeTab = (tab: string) => {

    if (!editable) {

      setTab(tab)
    }
  }
  //CODIGO NUEVO
  const [ menu, submenu ] = navigation.getNavigation(
    project ? project._id : null,
    "",
    ""
  );

  const loadProject = () => {
    if (loadProjectStatus !== "none") return;

    if (!project_id) return;

    dispatch(ProjectsActions.getRowAction(project_id));
  };
  loadProject();

  const reset = () => {
    setEditable(false);
  };

  const resetPass = () => {
    setPass({
      actual: "",
      new: "",
      new2: "",
    });

    setEditablePass(false);
  };

  const handleUpdate = async () => {
    dispatch(InterfaceActions.showLoader());

    setUpdating(true);

    await update({
      name: newUserData.name,
      surname: newUserData.surname,
      phone: newUserData.phone,
      workPhone: newUserData.workPhone,
      address: newUserData.address,
      avatar: avatarUploaded._id ? avatarUploaded : newUserData.avatar,
    })

    Toast.show(
      t('PROFILE:ProfileUpdatedToastTitle'),
      t('PROFILE:ProfileUpdatedToastContent')
    )

    setUpdating(false);

    dispatch(InterfaceActions.hideLoader());

    setEditable(false);
  };

  const changePassword = async () => {
    if (!pass.actual || !pass.new || !pass.new2) {
      Toast.show(
        t('PROFILE:UpdatePasswordMissingDataToastTitle'),
        t('PROFILE:UpdatePasswordMissingDataToastContent')
      );
      return;
    }

    if (pass.new !== pass.new2) {
      Toast.show(
        t('PROFILE:UpdatePasswordNoMatchingToastTitle'),
        t('PROFILE:UpdatePasswordNoMatchingToastContent')
      );
      return;
    }

    dispatch(InterfaceActions.showLoader());

    setChangingPassword(true);

    await dispatch(SessionActions.changePasswordAction(pass.actual, pass.new));

    setChangingPassword(false);

    dispatch(InterfaceActions.hideLoader());

    setEditablePass(false);

    setPass({
      actual: "",
      new: "",
      new2: "",
    });
  };

  const handleChangeLanguage = async (language: string) => {

    dispatch(InterfaceActions.showLoader());

    await dispatch(SessionActions.changeLanguageAction(language));

    await dispatch(setLanguage(language))

    dispatch(InterfaceActions.hideLoader());
  }

  const onUploadAvatar = (image: any) => {

    setNewUserData({ ...newUserData, avatar: image.fullPath });

    setAvatarUploaded(image);
  };

  const handleEliminarCuenta = () => {

    Swal.fire({
      title: t('PROFILE:DeleteAccountModalTitle'),
      text: t('PROFILE:DeleteAccountModalContent'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('PROFILE:DeleteAccountModalAcceptButton'),
      cancelButtonText: t('PROFILE:DeleteAccountModalCancelButton'),
    }).then(async (result) => {

      if (result.isConfirmed) {

        dispatch(InterfaceActions.showLoader())

        const { error } = await SessionService.deleteAccount()

        if (error) {

          dispatch(InterfaceActions.hideLoader())

          if (error === 'transfer_projects_first') {

            Swal.fire({
              text: t('PROFILE:DeleteAccountTransferFirstTitle'),
              icon: 'info',
              confirmButtonText: t('PROFILE:DeleteAccountTransferFirstAccept'),
            })
          }

          return
        }

        logout()

        dispatch(InterfaceActions.hideLoader())

        Swal.fire(
          t('PROFILE:AccountDeletedTitle'),
          t('PROFILE:AccountDeletedContent'),
          'success'
        );
      }
    });
  };

  useEffect(() => {
    // Check if the "Cambiar contraseña" option is selected and the input is editable
    if (tab === 'CONFIGURACION' && editablePass) {
      // Focus on the input field for the current password
      document.getElementById('currentPasswordInput')?.focus();
    }
  }, [tab, editablePass]);


  return (
    <MainLayout
      page="profile"
      project={project_id && project ? project : null}
      username={`${newUserData.name} ${newUserData.surname}`}
      menuBar={project_id ? menu : null}
      avatar={
        userData?.avatar
          ? `${config.apiHost}/${userData.avatar}`
          : ""
      }
      noBottomBar={!!project_id}
      subMenuBar={submenu}>
      <MainLayoutContentHeader>
        {/* CODIGO NUEVO DEL BOTON EDITAR  */}
        <div className='ul-widget__head-label flex-grow-1'>
          <h3 className='ul-widget__head-title'>
            {t('PROFILE:PageTitle')}
          </h3>
        </div>
        <div className='text-right'>
          <>
            {tab !== 'CONFIGURACION' && !editable && (
              <button
                className="btn btn-link btn-link-header"
                onClick={() => setEditable(true)}
              >
                <span className="icon-editar"></span> {t('PROFILE:PageEditButton')}
              </button>
            )}
            {editable && (
              <>
                <button
                  className="btn btn-link btn-link-header"
                  onClick={() => reset()}
                  disabled={updating}
                >
                  <span className="icon-cancelar"></span> {t('PROFILE:PageCancelButton')}
                </button>
                &nbsp;
                <button
                  className="btn btn-primary"
                  onClick={() => handleUpdate()}
                  disabled={updating}
                >
                  <span className="icon-guardar"></span>{" "}
                  {updating
                    ? t('PROFILE:PageSaveButtonSaving')
                    : t('PROFILE:PageSaveButtonIdle')}
                </button>
              </>
            )}
          </>
          <>
            {tab !== 'USUARIO' && !editablePass && (
              <button
                className="btn btn-secondary"
                onClick={() => setEditablePass(true)}>
                <span className="icon-editar"></span> {t('PROFILE:ChangePasswordButton')}
              </button>
            )}
            {tab !== 'USUARIO' && editablePass && (
              <>
                <button
                  className="btn btn-secondary mr-1"
                  onClick={() => resetPass()}>
                  <span className="icon-cancelar"></span> {t('PROFILE:UpdatePasswordCancelButton')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => changePassword()}>
                  <span className="icon-guardar"></span>{" "}
                  {changingPassword
                    ? t('PROFILE:UpdatePasswordSaveButtonSaving')
                    : t('PROFILE:UpdatePasswordSaveButtonIdle')}
                </button>
              </>
            )}
          </>
        </div>

        {/* CODIGO NUEVO DEL BOTON EDITAR  */}
        {/* CODIGO NUEVO USUARIO Y CONFIGURACION */}
        <div className="ul-widget__head-nav">
          <Tab.Container>
            <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
              <Nav.Item as="li">
                <Nav.Link active={tab === 'USUARIO'} onClick={() => changeTab('USUARIO')}>
                  <span className="icon-usuario"></span> <span className="hide-on-mobile"> {t('PROFILE:UserTab')}</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link active={tab === 'CONFIGURACION'} onClick={() => changeTab('CONFIGURACION')}>
                  <span className="icon-configuracion"></span>  <span className="hide-on-mobile"> {t('PROFILE:SettingsTab')}</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
        {/* CODIGO NUEVO */}
      </MainLayoutContentHeader>
      <MainLayoutContentBody>

        <Tab.Content className="no-padding">
          <Tab.Pane active={tab === 'USUARIO'}>
            <div className="col-md-8 pb-3 " >
              <div className="row">
                <div className="card ">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-5 mb-2">
                        <AvatarUploader
                          mode="html5-canvas-upload"
                          baseUrl={config.apiHost}
                          endpoint={`/upload/canvas`}
                          image={newUserData.avatar ?? ""}
                          onUpload={(image: string) => onUploadAvatar(image)}
                          disabled={!editable}
                        />
                        <div className="profile-name">{newUserData.account}</div>
                        <div className="profile-email">{newUserData.email}</div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group row">
                          <label htmlFor="" className="col-xl-4 col-form-label">
                            Nombres
                          </label>
                          <div className="col-xl-8">
                            <InputFormControl
                              value={newUserData.name}
                              onChange={(value: string) =>
                                setNewUserData({ ...newUserData, name: value })
                              }
                              readOnly={!editable}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="" className="col-xl-4 col-form-label">
                            Apellidos
                          </label>
                          <div className="col-xl-8">
                            <InputFormControl
                              value={newUserData.surname}
                              onChange={(value: string) =>
                                setNewUserData({ ...newUserData, surname: value })
                              }
                              readOnly={!editable}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="" className="col-xl-4 col-form-label">
                            Teléfono Personal
                          </label>
                          <div className="col-xl-8">
                            <InputFormControl
                              type="phone"
                              value={newUserData.phone}
                              onChange={(value: string) =>
                                setNewUserData({ ...newUserData, phone: value })
                              }
                              readOnly={!editable}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="" className="col-xl-4 col-form-label">
                            Teléfono Laboral
                          </label>
                          <div className="col-xl-8">
                            <InputFormControl
                              type="phone"
                              value={newUserData.workPhone || ""}
                              onChange={(value: string) =>
                                setNewUserData({ ...newUserData, workPhone: value })
                              }
                              readOnly={!editable}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="" className="col-xl-4 col-form-label">
                            Dirección
                          </label>
                          <div className="col-xl-8">
                            <textarea
                              className="form-control"
                              rows={3}
                              defaultValue={newUserData.address || ""}
                              onChange={(
                                e: React.ChangeEvent<HTMLTextAreaElement>
                              ) =>
                                setNewUserData({
                                  ...newUserData,
                                  address: e.target.value,
                                })
                              }
                              readOnly={!editable}></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane active={tab === 'CONFIGURACION'}>
            <Row className="justify-content-md-center">
              <div className="col-md-4 pb-3">
                <Card>
                  <Card.Body>
                    <h5 className="card-title mb-3">{t('PROFILE:ChangeLanguageTitle')}</h5>
                    <div className="form-group">
                      <label htmlFor="languageSelect">
                        {t('PROFILE:ChangeLanguageLabel')}
                      </label>
                      <select
                        id="languageSelect"
                        value={language}
                        className="form-control"
                        onChange={(e) => {
                          const selectedLanguage = e.target.value;
                          // Add your logic to change the language here

                          handleChangeLanguage(selectedLanguage);
                        }}
                      >
                        <option value="es">Español</option>
                        <option value="en">English</option>
                      </select>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Row>

            <Row className="justify-content-md-center">
              <div className="col-md-4 pb-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      {t('PROFILE:ChangePasswordTitle')}
                    </h5>
                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-5 col-form-label">
                        C{t('PROFILE:ChangePasswordCurrentLabel')}
                      </label>
                      <div className="col-sm-7">
                        <InputFormControl
                          type="password"
                          id="currentPasswordInput" // Add this line
                          placeholder={t('PROFILE:ChangePasswordCurrentPlaceholder')}
                          value={pass.actual}
                          onChange={(value: string) => setPass({ ...pass, actual: value })}
                          readOnly={!editablePass}
                          autoFocus
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-5 col-form-label">
                        {t('PROFILE:ChangePasswordNewLabel')}
                      </label>
                      <div className="col-sm-7">
                        <InputFormControl
                          type="password"
                          placeholder={t('PROFILE:ChangePasswordNewPlaceholder')}
                          value={pass.new}
                          onChange={(value: string) =>
                            setPass({ ...pass, new: value })
                          }
                          readOnly={!editablePass}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-5 col-form-label">
                        {t('PROFILE:ChangePasswordReNewLabel')}
                      </label>
                      <div className="col-sm-7">
                        <InputFormControl
                          type="password"
                          placeholder={t('PROFILE:ChangePasswordReNewPlaceholder')}
                          value={pass.new2}
                          onChange={(value: string) =>
                            setPass({ ...pass, new2: value })
                          }
                          readOnly={!editablePass}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row className="justify-content-md-center">
              <div className="col-md-4 pb-3">
                <Card>
                  <Card.Body>
                    <h5 className="card-title mb-3">
                      {t('PROFILE:DeleteAccountTitle')}
                    </h5>
                    <p className="pb-2">
                      {t('PROFILE:DeleteAccountContent')}
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button variant="outline-danger" onClick={handleEliminarCuenta}>
                        {t('PROFILE:DeleteAccountButton')}
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Row>
          </Tab.Pane>

        </Tab.Content>
      </MainLayoutContentBody>
    </MainLayout>
  );
};

export default ProfilePage;
