
import React from "react";
import { Link } from "react-router-dom";

import { useChat } from '../../../Hooks/UseChat';
import useTranslation from "../../../Hooks/UseTranslation";

import TreeProjectItem from "../ChatProjectsTreeItem/TreeProjectItem";

import "./ChatProjectsTree.scss";

interface Props {
  onSelectItem?: () => void;
}

const ChatProjectsTree = ({ onSelectItem }: Props) => {

  const { t } = useTranslation();

  const { projectsTree } = useChat()

  const [ showprojects, setShowprojects ] = React.useState(false)

  const handleSelectChat = () => {

    setShowprojects(false)

    if (onSelectItem) onSelectItem();
  }

  return (
    <div className={`chat-projects-tree ${showprojects && 'open'}`}>
      {/* <button className="chat-projects-pl4nner-ai cursor-pointer"
        onClick={() => setShowprojects(!showprojects)}
      >
        Planner AI
      </button> */}
      <button className="chat-projects-tree-toggler cursor-pointer"
        onClick={() => setShowprojects(!showprojects)}
      >
        {t('CHAT:SidebarTitle')} <i className="fas fa-chevron-up"></i>
      </button>

      <div className={`chat-projects-tree-content ${!projectsTree.length && 'empty'}`}>
        {!projectsTree.length && (
          <>
            {t('CHAT:SidebarNoProjectsYetOne')} <br />
            {t('CHAT:SidebarNoProjectsYetTwo')} <Link to='/'>{t('CHAT:SidebarNoProjectsYetThree')}</Link>
          </>
        )}
        {projectsTree.map((project: any, index: number) => (
          <TreeProjectItem
            key={index}
            project={project}
            onSelectItem={() => handleSelectChat()}
          />
        ))}
      </div>
    </div>
  )
}

export default ChatProjectsTree;

