
import moment from 'moment'
import React, { useRef, useState } from 'react'

import { useAuth } from '../../Hooks/UseAuth'
import useTranslation from '../../Hooks/UseTranslation'

import { createComment } from '../../Services/TasksService'

import UserAvatar from '../UserAvatar/UserAvatar'
import CommentItem from './CommentItem'

import './CommentList.scss'

type Props = {
    list: any,
    task_id: string,
    onChange?: any,
    readOnly?: boolean,
}

const CommentsList = (props: Props) => {

    const { t} = useTranslation()

    const textRef: any = useRef(null)

    const { userData } = useAuth()

    const [ commentInput, setCommentInput ] = useState('')

    const handleComment = async () => {

        const content = commentInput.trim()

        setCommentInput('')

        if (!userData) return

        const { data, error } = await createComment(props.task_id, userData.id, content)

        if (error) {

            console.error(error)
            return
        }

        if (textRef.current) {

            textRef.current.focus()
        }

        if (props.onChange) props.onChange(data)
    }

    const handleEnterKey = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (e.key === 'Enter' && !e.shiftKey) {

            handleComment()
        }

        if (e.key === 'Enter' && e.shiftKey) {

            setCommentInput(commentInput)
        }
    }

    return (
        <div className="comments-list">
            {props.onChange && userData && (
                <div className="new-comment mb-2">
                    <div className="mr-2">
                        <UserAvatar user={userData} size="lg" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="username">
                            {userData.name} {userData.surname}
                        </div>
                        <textarea
                          ref={textRef}
                          className="form-control mb-1" rows={2}
                          placeholder={t('COMMENTS_LIST:InputMessagePlaceholder')}
                          value={commentInput}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCommentInput(e.target.value)}
                          onKeyUp={handleEnterKey}
                          readOnly={props.readOnly}
                          autoFocus={true}
                          id="subjectInput"
                        ></textarea>

                        <div className="text-right">
                            <button className="btn btn-primary"
                                onClick={() => handleComment()}
                                disabled={commentInput.length === 0 || props.readOnly}
                            >
                                <span className="icon-mensajes"></span> {' '}
                                {t('COMMENTS_LIST:CommentButton')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="comments">
                {props.list && props.list.length > 0 && (
                    <>
                        {props.list.slice(0).reverse().map((_comment: any, i: number) => (
                            <CommentItem key={`${i}-${_comment._id}`} comment={_comment} />
                        ))}
                    </>
                )}

            </div>
        </div>
    )
}

export default CommentsList
