
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Card } from "react-bootstrap";

import config from "../../../config";
import navigation from "../../../navigation";
import { TaskWithId } from "../../../Types/Tasks";
import { GanttColumn } from "../../../Components/GantV2/types";

import { useAuth } from "../../../Hooks/UseAuth";
import useTranslation from "../../../Hooks/UseTranslation";

import { getWeekNavigator } from "../../../Helpers/LookaheadHelper";
import TeamsHelper from "../../../Helpers/TeamsHelper";
import {
  IGetItemsListOptions,
  filterByColorCodes,
  filterByGroups,
  filterByStatus,
  filterByTypes,
  filterByUsers,
  getCollapsedGroups,
  getGanttItems,
  getItemFromTaskList,
  getNonWorkingDays,
  getWeeklyResult,
  saveCollapsedGroups
} from "../../../Helpers/ProgrammationHelper";
import History from "../../../Helpers/History";

import ProjectsActions from "../../../Redux/Actions/ProjectsActions";
import TasksActions from "../../../Redux/Actions/TasksActions";
import TeamsActions from "../../../Redux/Actions/TeamsActions";
import ColorCodeActions from "../../../Redux/Actions/ColorCodeActions";
import InterfaceActions from "../../../Redux/Actions/InterfaceActions";
import { loadProgrammationAction } from "../../../Redux/Actions/ProgrammationActions";
import { regenerateWeekly, setWeeklyAction, setWeeklyCollapsedGroupsAction, setWeeklyGanttItemsAction } from "../../../Redux/Actions/WeeklyActions";

import { getStatusName } from "../../../Components/TaskManager/TaskEditorHelper";
import Gantt from "../../../Components/GantV2/Gantt";
import MainLayout from "../../../Components/MainLayout/MainLayout";
import MainLayoutContentBody from "../../../Components/MainLayout/MainLayoutContentBody";
import MainLayoutContentHeader from "../../../Components/MainLayout/MainLayoutContentHeader";
import TaskEditor from "../../../Components/TaskManager/TaskEditor";
import TaskFilters from "../../../Components/TaskFilters/TaskFilters";
import UserAvatar from "../../../Components/UserAvatar/UserAvatar";
import Icon from "../../../Components/Icon/Icon";

const WeeklyPage = (props: any) => {

  const { t } = useTranslation()

  const sidebarRefBtn = useRef<HTMLButtonElement>(null)

  const dispatch = useDispatch()

  const { userData, ability } = useAuth()

  const project_id = props.match.params._id
  const week = props.match.params.week

  const projectLoadStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)
  const project = useSelector((state: any) => state.ProjectsReducer.rowData)
  const teams = useSelector((state: any) => state.TeamsReducer.result)
  const programmation = useSelector((state: any) => state.ProgrammationReducer)
  const weekly = useSelector((state: any) => state.WeeklyReducer)

  const teamsMembersIds = useMemo(() => TeamsHelper.getAllMembersFromProject(teams).map((user: any) => user._id), [ teams ])

  const [ showTaskFilters, setShowTaskFilters ] = useState(false)

  const savedFilters = localStorage.getItem(`${project_id}-weekly-filters`)

  const [ filters, setFilters ] = useState(savedFilters ? JSON.parse(savedFilters) : {
    user: '',
    state: '',
    team: '',
    group: '',
    code: '',
    type: '',
    start: '',
    end: '',
  })

  const [ mySettings, setMySettings ] = useState(project?.mySettings)
  const [ showSidebar, setShowSidebar ] = useState(false)
  const [ showTaskEditor, setShowTaskEditor ] = useState(false)
  const [ showGroupEditor, setShowGroupEditor ] = useState(false)

  const [ task, setTask ] = useState<TaskWithId | undefined>()
  const [ group, setGroup ] = useState({}) //TODO: use undefined instead of empty object

  const navigator = useMemo(() => {

    if (!project._id) return {
      prev: '',
      next: '',
      startDay: '',
      endDay: '',
      week: 0,
      range: []
    }

    return getWeekNavigator(project.startsAt, project.realEnd, 1, week)
  }, [ project, week ])

  const navigateToWeek = (week?: any) => {

    dispatch(regenerateWeekly())

    if (week) {

      History.push(`/project/${project_id}/programmation-weekly/${week}`)
    } else {

      History.push(`/project/${project_id}/programmation-weekly`)
    }
  }

  const nonWorkingDays = useMemo(() => {

    if (!project._id) return []

    return getNonWorkingDays(weekly.start, weekly.end, project)
  }, [ project._id, weekly.start, weekly.end ])

  const [ menu, submenu ] = navigation.getNavigation(
    props.match.params._id,
    'programmation',
    'weekly'
  )

  const columns: GanttColumn[] = [
    {
      label: t('WEEKLY:TypeColumn'),
      field: 'type',
      width: 50,
      render: (field: any) => !field ? null : field.substring(0, 1).toUpperCase()
    },
    {
      label: t('WEEKLY:ColorCodeColumn'),
      field: 'colorCodeName',
      width: 60,
    },
    {
      label: t('WEEKLY:PositionColumn'),
      field: 'position',
      width: 100,
    },
    {
      label: t('WEEKLY:TitleColumn'),
      field: 'name',
      width: 300,
    },
    {
      label: t('WEEKLY:StatusColumn'),
      field: 'status',
      width: 100,
      render: (field: any) => getStatusName(field)
    },
    {
      label: t('WEEKLY:StartsAtColumn'),
      field: 'startsAt',
      width: 80,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('WEEKLY:EndsAtColumn'),
      field: 'endsAt',
      width: 80,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('WEEKLY:ResponsiblesColumn'),
      field: 'responsibles',
      width: 80,
      render: (field: any, item: any) => {

        const isField = !!field
        const isFieldArray = isField && Array.isArray(field)

        if (isField && isFieldArray) {

          const responsibles = field.filter((user: any) => teamsMembersIds.includes(user._id))

          return responsibles.map((user: any) => <UserAvatar key={user._id} user={user} size="xs" />)
        }

        return null
      }
    },
    {
      label: t('WEEKLY:ReleasesAtColumn'),
      field: 'releasesAt',
      width: 80,
      render: (field: any) => field ? moment.utc(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('WEEKLY:ActualStartColumn'),
      field: 'startedAt',
      width: 90,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('WEEKLY:ActualEndColumn'),
      field: 'endedAt',
      width: 90,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: t('WEEKLY:CommentsColumn'),
      field: 'comments',
      width: 150,
      render: (field: any) => {

        if (!field) return null

        return `${field.comment}...`
      }
    }
  ]

  const loadProject = () => {

    dispatch(ProjectsActions.getRowAction(project_id))
  }

  const loadTeams = async () => {

    await dispatch(TeamsActions.getResultAction(project_id))
  }

  const loadProgrammation = async () => {

    await dispatch(loadProgrammationAction(project_id))
  }

  const loadWeekly = (withFilters?: any) => {

    if (programmation.loadStatus !== 'loaded') return

    const options = {
      ...programmation,
      list: JSON.parse(JSON.stringify(programmation.list)),
      tasks: JSON.parse(JSON.stringify(programmation.tasks)),
      navigator,
    }

    let weeklyResult = getWeeklyResult(options)

    const newFilters = withFilters || filters

    if (newFilters.team) {

      const teams_ids = newFilters.team.split(',')

      const teamsFound = teams.filter((team: any) => teams_ids.includes(team._id))

      const users_ids: string[] = []

      teamsFound.forEach((team: any) => {

        team.users.forEach((user: any) => {

          if (!users_ids.includes(user._id))
            users_ids.push(user._id)
        })
      })

      weeklyResult = filterByUsers(weeklyResult, users_ids)
    } else if (newFilters.user)
      weeklyResult = filterByUsers(weeklyResult, newFilters.user.split(','))

    if (newFilters.group) {

      weeklyResult = filterByGroups(weeklyResult, newFilters.group.split(','))
    } else if (newFilters.code)
      weeklyResult = filterByColorCodes(weeklyResult, newFilters.code.split(','))

    if (newFilters.type)
      weeklyResult = filterByTypes(weeklyResult, newFilters.type.split(','))

    if (newFilters.state)
      weeklyResult = filterByStatus(weeklyResult, newFilters.state.split(','))

    const collapsedGroups = getCollapsedGroups(project_id, 'weekly')

    dispatch(setWeeklyAction(weeklyResult, navigator.startDay, navigator.endDay, navigator.week, collapsedGroups))
  }

  const loadColorCodes = async () => {

    await dispatch(ColorCodeActions.getResult(project._id))
  }

  const hasActiveFilters = () => {

    const values = Object.values(filters)
    const found = values.findIndex(f => f !== '')

    return !!(found >= 0)
  }

  const handleChangeFilters = (filters: any) => {

    localStorage.setItem(`${project_id}-weekly-filters`, JSON.stringify(filters))

    loadWeekly(filters)
    setFilters(filters)
  }

  const handleEditItem = async (item_id: string) => {

    const { item, parent } = getItemFromTaskList(weekly.result, item_id)

    if (item.items) {

      setGroup(item || {})
      setShowGroupEditor(true)
    } else {

      setTask(item)
      setGroup(parent || {})
      setShowTaskEditor(true)
    }
  }

  const handleCloseTaskEditor = () => {

    setTask(undefined)
    setGroup({})
    setShowTaskEditor(false)
  }

  const handleTaskUpdated = async () => {

    setShowTaskEditor(false)
  }

  const handleConfirmedOrRejected = async (task_id: string, action: 'confirm' | 'reject') => {

    await dispatch(TasksActions.confirmOrReject(task_id, action))

    setShowTaskEditor(false)
  }

  const handleTaskDeleted = async (task: any) => {

    //TODO: pass the dispatch action to task editor
    await dispatch(TasksActions.remove(task, project._id))

    setShowTaskEditor(false)
  }

  const handleToggleCollapse = async (group_id: string) => {

    const collapsedGroups = getCollapsedGroups(project_id, 'weekly')

    if (collapsedGroups.includes(group_id)) {

      collapsedGroups.splice(collapsedGroups.indexOf(group_id), 1)
    } else {

      collapsedGroups.push(group_id)
    }

    saveCollapsedGroups(project_id, 'weekly', collapsedGroups)

    dispatch(setWeeklyCollapsedGroupsAction(collapsedGroups))
  }

  const handleShowWorkflows = (groups: string[]) => {

    saveCollapsedGroups(project_id, 'weekly', groups)

    dispatch(setWeeklyCollapsedGroupsAction(groups))
  }

  const toggleSidebar = (target?: any) => {

    if (!target) {

      if (showSidebar) updateSettings()

      setShowSidebar(!showSidebar)
    } else {

      if (sidebarRefBtn.current) {

        const isButtonClicked = sidebarRefBtn.current.contains(target)

        if (!isButtonClicked) {

          // trigger click because if not mySettings is not updated
          sidebarRefBtn.current.click()
        }
      }
    }
  }

  const handleUpdateSetting = async (key: string, value: any) => {

    let field = key

    if (field === 'dayWidth') {

      field = 'ganttWeeklyColumnSize'
    } else if (field === 'rowHeight') {

      field = 'ganttWeeklyRowSize'
    } else if (field === 'hideNonWorkingDays') {

      field = 'ganttWeeklyHideNonWorkingDays'
    } else if (field === 'showVerticalBorders') {

      field = 'ganttWeeklyShowVerticalBorders'
    } else if (field === 'columnsHidden') {

      field = 'ganttHideWeeklyFields'
    } else if (field === 'divisorPosition') {

      field = 'ganttWeeklyDivisorPosition'
    }

    await updateSettings({
      ...mySettings,
      [ field ]: value
    })
  }

  const updateSettings = async (settings = mySettings) => {

    let areDifferent = false

    for (const key in settings) {

      if (key !== 'setted' && settings[ key ] !== project.mySettings[ key ]) {

        areDifferent = true
      }
    }

    console.log('areDifferent', areDifferent)

    if (areDifferent) {

      await dispatch(ProjectsActions.updateSettings(project.mySettings._id, settings))
    }

    setMySettings(settings)
  }

  useEffect(() => {

    if (projectLoadStatus === 'none') loadProject()

    if (projectLoadStatus === 'loaded') {

      loadTeams()
      loadColorCodes()

      setMySettings(project.mySettings)
    }
  }, [ projectLoadStatus ])

  useEffect(() => {

    if (projectLoadStatus === 'loaded') {

      if (project.ability) ability.update(project.ability)

      if (programmation.loadStatus === 'none') {

        loadProgrammation()
      }
    }
  }, [ projectLoadStatus, programmation.loadStatus ])

  useEffect(() => {

    if (programmation.loadStatus !== 'loaded') return

    if (weekly.loadStatus === 'none') {

      loadWeekly()
    }
  }, [ programmation.loadStatus, weekly.loadStatus ])

  useEffect(() => {

    if (weekly.loadStatus !== 'loaded') return
    if (weekly.loadGanttItemsStatus !== 'none') return

    const options: IGetItemsListOptions = {
      itemsList: weekly.result,
      collapsedGroups: weekly.collapsedGroups,
      schema: 'weekly',
      nonWorkingDays,
      stretchUpToday: mySettings?.stretchUpToday,
      showOnlyThisUserTasks: project.mySettings.showOnlyMyTasks ? userData?.id : undefined,
      week: navigator.week,
      ganttStart: weekly.start,
      ganttEnd: weekly.end,
    }

    const ganttItems = getGanttItems(options);

    // cortamos las barras de los items que se salen del rango de fechas
    ganttItems.forEach((item: any) => {

      item.barStart = moment(item.barStart).isBefore(moment(weekly.start)) ? weekly.start : item.barStart;
      item.barEnd = moment(item.barEnd).isAfter(moment(weekly.end)) ? weekly.end : item.barEnd;

      item.bars?.forEach((bar: any) => {

        bar.barStart = moment(bar.barStart).isBefore(moment(weekly.start)) ? weekly.start : bar.barStart;
        bar.barEnd = moment(bar.barEnd).isAfter(moment(weekly.end)) ? weekly.end : bar.barEnd;
      })
    })

    dispatch(setWeeklyGanttItemsAction(ganttItems))
  }, [ weekly.loadGanttItemsStatus, weekly.result, mySettings ]);

  useEffect(() => {

    const projectLoaded = projectLoadStatus === 'loaded'
    const taskListLoaded = weekly.loadStatus === 'loaded'

    if (!projectLoaded || !taskListLoaded) {

      dispatch(InterfaceActions.showLoader())
    } else {

      dispatch(InterfaceActions.hideLoader())
    }

  }, [ projectLoadStatus, weekly.loadStatus ])

  return (
    <MainLayout
      project={project}
      avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
      username={`${userData?.name} ${userData?.surname}`}
      menuBar={menu}
      rightTitle={project.shortName}
      subMenuBar={submenu}
    >
      <MainLayoutContentHeader>
        <div className='ul-widget__head-label flex-grow-1'>
          <h3 className="ul-widget__head-title">
            <span>
              {t('WEEKLY:Title')}
            </span>
            {(weekly.start !== '' && weekly.end !== '') && (
              <small className="d-block d-sm-inline">
                ({t('WEEKLY:DateFrom')} {' '}
                {moment(weekly.start).format('DD/MM/YYYY')} {' '}
                {t('WEEKLY:DateTo')} {' '}
                {moment(weekly.end).format('DD/MM/YYYY')})
              </small>
            )}
          </h3>
        </div>
        <div className="ul-widget__head-toolbar">
          <button className={`btn btn-link btn-link-header ${weekly.loadStatus === 'loading' ? 'active' : ''} d-inline-block`}
              onClick={() => loadProgrammation()}
              disabled={weekly.loadStatus === 'loading'}
            >
              <Icon name="sync" fas fw spin={weekly.loadStatus === 'loading'} />
              <span className="d-none d-sm-inline"> {t('WEEKLY:RefreshButton')}</span>
            </button>
          <button className="btn btn-link btn-link-header"
            onClick={() => navigateToWeek(navigator.prev)}
            disabled={navigator.prev === ''}
          >
            <i className="icon-anterior"></i>
            <span className="d-none d-sm-inline"> {t('WEEKLY:PreviousButton')}</span>
          </button>
          <button className="btn btn-link btn-link-header"
            onClick={(e) => navigateToWeek()}
          >
            <i className="icon-semana_actual"></i>
          </button>
          <button className="btn btn-link btn-link-header"
            onClick={(e) => navigateToWeek(navigator.next)}
            disabled={navigator.next === ''}
          >
            <span className="d-none d-sm-inline">{t('WEEKLY:NextButton')} </span>
            <i className="icon-siguiente"></i>
          </button>
          <button className="btn btn-link btn-link-header">
            <span className="icon-direccionar"></span>
            <span className="d-none d-sm-inline"> {t('WEEKLY:ShowPendingButton')}</span>
          </button>
          <button className={`btn btn-link btn-link-header ${hasActiveFilters() ? 'active' : ''}`}
            onClick={() => setShowTaskFilters(true)}
          >
            <span className="icon-filtro"></span>
            <span className="d-none d-sm-inline"> {t('WEEKLY:FilterButton')}</span>
          </button>
          <button className={`btn btn-link btn-link-header ${showSidebar ? 'active' : ''}`}
            ref={sidebarRefBtn}
            onClick={() => toggleSidebar()}
          >
            <span className="icon-configuracion"></span>
            <span className="d-none d-sm-inline"> {t('WEEKLY:SettingsButton')}</span>
          </button>
        </div>
        <div className='ul-widget__head-nav'>

        </div>
      </MainLayoutContentHeader>
      <MainLayoutContentBody>
        <Card className="table-card">
          {project._id && (
            <Gantt
              schema="weekly"
              start={weekly.start}
              end={weekly.end}
              items={weekly.ganttItems}
              columns={columns}
              nonWorkingDays={nonWorkingDays}
              dayWidth={mySettings?.ganttWeeklyColumnSize || 100}
              maxDayWidth={240}
              rowHeight={mySettings?.ganttWeeklyRowSize || 32}
              maxRowHeight={160}
              hideNonWorkingDays={mySettings?.ganttWeeklyHideNonWorkingDays || false}
              showVerticalBorders={mySettings?.ganttWeeklyShowVerticalBorders || false}
              divisorPosition={mySettings?.ganttWeeklyDivisorPosition}
              useCodeColors={mySettings?.useCodeColors || false}
              stretchUpToday={mySettings?.stretchUpToday || false}
              columnsHidden={mySettings?.ganttHideWeeklyFields || []}
              showSidebar={showSidebar}
              onSelectItem={handleEditItem}
              onToggleCollapse={handleToggleCollapse}
              onShowWorkflows={(groups: string[]) => handleShowWorkflows(groups)}
              onClickOutside={toggleSidebar}
              onUpdateSetting={handleUpdateSetting}
              sortEnabled={false}
              lineBaseTasks={false}
            />
          )}
        </Card>
      </MainLayoutContentBody>

      <TaskFilters
        show={showTaskFilters}
        type="weekly"
        filters={filters}
        onChange={handleChangeFilters}
        onHide={() => setShowTaskFilters(false)}
      />

      <TaskEditor
        show={showTaskEditor}
        task={task}
        group={group}
        taskList={weekly.result}
        nonWorkingDays={nonWorkingDays}
        project={project}
        projectMembers={TeamsHelper.getAllMembersFromProject(teams)}
        projectStartDate={project.startsAt}
        projectEndDate={project.endsAt}
        onHide={() => handleCloseTaskEditor()}
        onUpdated={handleTaskUpdated}
        onConfirmOrReject={handleConfirmedOrRejected}
        onDelete={handleTaskDeleted}
        canConfirmOrReject={ability.can('confirm', 'task')}
        canDelete={ability.can('delete', 'task')}
        canUpdate={() => {

          const isPl4nner = ability.can('manage', 'all')
          const isAssistant = project.assistants?.includes(userData?.id)
          const isCollaborator = task?.responsibles.map((r: any) => r._id).includes(userData?.id)

          return isPl4nner || isAssistant || isCollaborator
        }}
      />
    </MainLayout>
  )
}

export default WeeklyPage;
