import React, { useEffect, useState } from 'react';
import { Modal, Col, Row, Card } from 'react-bootstrap';

import config from '../../config';

import History from '../../Helpers/History';

import { useAuth } from '../../Hooks/UseAuth';
import { useChat } from '../../Hooks/UseChat';
import useTranslation from '../../Hooks/UseTranslation';

import ChatSidebar from '../../Components/ChatComponents/ChatSidebar/ChatSidebar';
import MainLayout from '../../Components/MainLayout/MainLayout';
import MainLayoutContentHeader from '../../Components/MainLayout/MainLayoutContentHeader';
import MainLayoutContentBody from '../../Components/MainLayout/MainLayoutContentBody';
import ChatContent from '../../Components/ChatComponents/ChatContent/ChatContent';

const ChatPage: React.FC = (props: any) => {

  const { t } = useTranslation();

  const {
    chat_id
  } = props.match.params

  const { userData } = useAuth()

  const { joinChat, currentChat, messages, sendMessage } = useChat();

  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
  const [ showModal, setShowModal ] = useState(false);

  const openChatModal = () => {
    setShowModal(true);
  };

  const closeChatModal = () => {
    setShowModal(false);
  };

  const handleClose = () => {

    if (!currentChat) History.goBack();

    setShowModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    if (currentChat && !chat_id) joinChat([ '', chat_id ]);
  }, [ chat_id ])

  return (
    <MainLayout
      username={`${userData?.name} ${userData?.surname}`}
      avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
      noBottomBar
      page='chat'
    >
      <MainLayoutContentHeader>
        <div className="ul-widget__head-label flex-grow-1 chatPage">
          <h3 className="ul-widget__head-title">Chat</h3>
          <div className='ul-widget__head-toolbar d-block d-lg-none'>
            <button className="btn btn-secondary" onClick={() => openChatModal()}>
              <i className="fas fa-fw fa-bars"></i>
            </button>
          </div>
        </div>
      </MainLayoutContentHeader>
      <MainLayoutContentBody>
        <Row>
          <Col className="d-none d-lg-block">
            <Card style={{ overflow: 'hidden', height: '100%' }}>
              <ChatSidebar />
            </Card>
          </Col>
          <Col lg={9}>
            <Card style={{ height: '100%' }}>
              <ChatContent
                project
                chat={currentChat}
                messages={messages}
                onSendMessage={(message) => sendMessage(message)}
                disabled={false}
              />
            </Card>
          </Col>
        </Row>
      </MainLayoutContentBody>

      <Modal
        className='modal-chat-sidebar'
        show={windowWidth < 992 && (showModal || !currentChat)}
        onHide={closeChatModal}
      >
        <div style={{ height: '100%' }}>
          <ChatSidebar onSelectChat={() => closeChatModal()} />
        </div>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            <span className="icon-cancelar"></span> {t('CHAT:ChatModalCloseButton')}
          </button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};

export default ChatPage;
