
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import config from "../../../config";

import { usePlan } from "../../../Hooks/UsePlan";
import useTranslation from "../../../Hooks/UseTranslation";

import ProjectsService from "../../../Services/ProjectsService";

import ProjectsActions from "../../../Redux/Actions/ProjectsActions";

interface Props {
  show: boolean
  title: string;
  projects: any[];
  maxProjectsAllowed: number;
  onHide: () => void;
}

const TooManyArchivedProjectsModal = (props: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { plan } = usePlan()

  const [ processing, setProcessing ] = useState<string[]>([])

  const handleRestore = async (project_id: string) => {

    setProcessing([ ...processing, project_id ])
    try {
      await ProjectsService.restoreProject(project_id);

      await dispatch(ProjectsActions.getResultAction())

      setProcessing(processing.filter((id) => id !== project_id))

    } catch (error) {
      console.error('Error restaurando proyecto:', error)
    }
  }

  const handleDelete = async (project_id: string) => {

    setProcessing([ ...processing, project_id ])
    try {
      await ProjectsService.del(project_id)

      await dispatch(ProjectsActions.getResultAction())

      setProcessing(processing.filter((id) => id !== project_id))
    } catch (error) {
      console.error('Error eliminando proyecto:', error)
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:ContentA')} {props.maxProjectsAllowed}{' '}
          {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:ContentB')}
        </p>
        <p>
          <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i> {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:ContentC')}
          </a> {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:ContentD')}
        </p>
        <p>
          {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:ContentE')}
        </p>
        <div className="table-responsive">
          <div className="list-group">
            {props.projects.map((project) => (
              <div
                key={project._id}
                className={`list-group-item`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{project.name}</strong>
                  </div>
                  <div className="btns-group">
                    {processing.includes(project._id) && (
                      <span>
                        <i className="fas fa-spinner fa-spin"></i>
                        <span className="d-none d-sm-inline"> {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:Processing')}</span>
                      </span>
                    )}
                    {!processing.includes(project._id) && (
                      <>
                        <button className="btn btn-secondary"
                          onClick={() => handleRestore(project._id)}
                          disabled={!plan.can('create', 'Project')}
                        >
                          {/* <i className="fas fa-archive"></i> */}
                          <img height="20" style={{ verticalAlign: '-5px'}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADqklEQVR4nO1YS2gTURQd8YP4QVQsIioqIooLRRDEhYK6ceVCBu3cG0ubebc/m3vT+sOFURDBhbhWwaIr9342iiCIFHRRRRDBulAqVNSF1mKtP17aJC/TZDKZjGkKOfAgJPe9OefdefedG8uqo4466qijjjoKo8PuWNDutC+2pgsIZBuBXFDA/QQ8TCh/9VDIY/o7hXJZQWK7VWtwj8hmQr6TIVxqKJQHrZjcYtUCFHALIY8WIfpNoXwhlD+TfgP5oTBxdKrJn8knrF8VuaEc3q/f/0wcYs98gsReAuklkJ8eMeemhHwrcKNnZ5/FY7yp1Dw31r2BQPryRSSbq8M6QwK6VhLId4PEPSKaF3R+U1NqLqHczs4HHm5t4jVWtUDI14yHv2xpOb6w7DWI5hHIc2MTrlrVQBv2NOh3Pftgh3dVWHZ/Tqx13qpe1clWmUeVruc6vJVibKd2p2ZZ1YBCuZVLOyes6QLbtme2HO5coYCf5jLQtdOqZbjpasMXCfmVAv7tvYzaHVkXxXMU8HVj/V+E/IGQHyoQDlMgNGYolBMKecTPEsTt5JJKyZN9cpGv9QD56KJAeYuiXJ1sC9KVZ1AhD+gRZdVQ+Rko7J+AzwYjDyyeHejT1sC2U3OsKp01d/yidAnkjcmlZCaam7uWEfBXg3xvlOXNdeQAgYzpoT9bJZD2UKbLBfnoeyYI5JSRsn4imh0V+fSllXemeER/F0iEmQmQrqLBCvlx7oZNOkHJxTG5nkDe6qE/FyH/efK7zZ+DiHAdVoZ1uV9cAPBQJrCj6djyoAII5LSxQ6eDkQ8uov1Q9ypjzqCPAPmRCSzn0OoKUahaeMmbZTm/RLOvCM3FiB0tSoRQ3udO/NG1lQqYKLdZkgr4ihF3xSNuoCivWGJ1oAykfX2u5ePoBIzvsDeOjAz5CkChQGcg77Agf+iMdS6tRED6cDvJnszBLhQX98R4oVtTM5O+fbTulBTyOyMLT/TdEFZA2Lg88sB3jTlDJb2RvnXHDVVWxCdCSbmQ3KHNmx7e9jFKAbZtz5yoOpQuzUbF0n24FQQUkzZTRAFfNOKi7IlCQBv2NBDyC19DNx5/JhD57MNiso+AXxcXIZeiEEBO0ilBfCjwznuhfZDCxEECvqkbmYwTTTc1jd0b/1MGfulSqauNtg1h+4Gy8L8OcdUw7QTEQ9b3mhGgQt6wNShAyvI4NSOAQrrMmhEQ1uf79Q1TgnI7rVKd25QgbK9bU3DL/LchSvwDzfeDZ1meZRIAAAAASUVORK5CYII="></img>
                          <span className="d-none d-sm-inline"> {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:Restore')}</span>
                        </button>
                        <button className="btn btn-danger"
                          onClick={() => handleDelete(project._id)}
                        >
                          <i className="fas fa-trash"></i>
                          <span className="d-none d-sm-inline"> {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:Delete')}</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          {t('TOO_MANY_ARCHIVED_PROJECTS_MODAL:Close')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TooManyArchivedProjectsModal
