
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import config from "../../config";

import { useFeedback } from "../../Hooks/UseFeedback";
import useTranslation from "../../Hooks/UseTranslation";

import { EMethods, request } from "../../Services/ApiService";
import { sendFeedback } from "../../Services/FeedbackService";

import ImageCanvasReader from "../ImageCanvasReader/ImageCanvasReader";

const FeedbackForm = () => {

  const { t } = useTranslation()

  const { isVisible, hide } = useFeedback()

  const [ subject, setSubject ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ image, setImage ] = useState('')
  const [ uploadingImage, setUploadingImage ] = useState(false)

  const [ sendingFeedback, setSendingFeedback ] = useState(false)

  const handleCancel = () => {

    setSubject('')
    setMessage('')
    setImage('')

    hide()
  }

  const handleFileRead = async (file: any) => {
    setUploadingImage(true);

    try {
      const res = await request(
        EMethods.post,
        "UPLOAD_IMAGE",
        `${config.apiHost}/upload/canvas`,
        file
      );

      if (!res.data.success) throw new Error(res.data.error);

      setImage(res.data.file._id);

      setUploadingImage(false);
    } catch (error) {
      //TODO: Handle error
    }
  };

  const handleSubmit = async () => {

    setSendingFeedback(true)

    const data = {
      subject,
      message,
      image,
      url: config.apiHost + window.location.pathname,
    }

    const { error } = await sendFeedback(data)

    if (error) {

      console.log(error)
    }

    setSendingFeedback(false)

    setSubject('')
    setMessage('')
    setImage('')
    hide()
  }

  return (
    <Modal
      show={isVisible}
      size="lg"
      centered
      onHide={hide}
      onEntered={() => document.getElementById("subjectInput")?.focus()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('FEEDBACK_FORM:Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <div className="form-group">
      <input
        type="text"
        className="form-control"
        placeholder={t('FEEDBACK_FORM:InputSubjectPlaceholder')}
        name="asunto"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        id="subjectInput"
      />
      </div>

        <div className="form-group">
          <textarea
            className="form-control"
            name="feedback"
            placeholder={t('FEEDBACK_FORM:InputMessagePlaceholder')}
            rows={3}
            cols={40}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        <div className="form-group imagen">
          <label htmlFor="myImage">
            {t('FEEDBACK_FORM:InputAttachImageLabel')}
          </label>
          <ImageCanvasReader
            folder="feedback"
            onFileRead={handleFileRead}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => handleCancel()}
        >
          <span className="icon-cancelar"></span> {' '}
          {t('FEEDBACK_FORM:CancelButton')}
        </button>
        <button
          className="btn btn-primary aceptar"
          onClick={() => handleSubmit()}
          disabled={uploadingImage || sendingFeedback}
        >
          <span className="icon-aceptar"></span> {' '}
          {t('FEEDBACK_FORM:SendButton')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default FeedbackForm
