
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import config from "../../../config";

import useTranslation from "../../../Hooks/UseTranslation";

import TeamsService from "../../../Services/TeamsService";
import TeamsActions from "../../../Redux/Actions/TeamsActions";

interface Props {
  show: boolean
  title: string;
  teams: any[];
  maxTeamsAllowed: number;
  onHide: () => void;
}

const TooManyTeamsModal = (props: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [ processing, setProcessing ] = useState<string[]>([])

  const handleDelete = async (team: any) => {

    setProcessing([ ...processing, team._id ])
    try {
      await TeamsService.deleteRow(team._id)

      await dispatch(TeamsActions.getResultAction(team.project))

      setProcessing(processing.filter((id) => id !== team._id))
    } catch (error) {
      console.error('Error eliminando equipo:', error)
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('TOO_MANY_TEAMS_MODAL:ContentA')} {props.maxTeamsAllowed} {t('TOO_MANY_TEAMS_MODAL:ContentB')}
        </p>
        <p>
          <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i> {t('TOO_MANY_TEAMS_MODAL:ContentC')}
          </a> {t('TOO_MANY_TEAMS_MODAL:ContentD')}
        </p>
        <p>
          {t('TOO_MANY_TEAMS_MODAL:ContentE')}
        </p>
        <div className="table-responsive">
          <div className="list-group">
            {props.teams.map((team) => (
              <div
                key={team._id}
                className={`list-group-item`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{team.name}</strong>
                  </div>
                  <div className="btns-group">
                    {processing.includes(team._id) && (
                      <span>
                        <i className="fas fa-spinner fa-spin"></i>
                        <span className="d-none d-sm-inline"> {t('TOO_MANY_TEAMS_MODAL:Processing')}</span>
                      </span>
                    )}
                    {!processing.includes(team._id) && (
                      <>
                        <button className="btn btn-danger"
                          onClick={() => handleDelete(team)}
                        >
                          <i className="fas fa-trash"></i>
                          <span className="d-none d-sm-inline"> {t('TOO_MANY_TEAMS_MODAL:Delete')}</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          {t('TOO_MANY_TEAMS_MODAL:Close')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TooManyTeamsModal
