import { SET_LANGUAGE, LOAD_TRANSLATIONS } from "../Actions/TranslationsActions";

const initialState = {
  language: "en",
  translations: {
    en: { welcome: "Welcome", hello: "Hello" },
    es: { welcome: "Bienvenido", hello: "Hola" },
  },
};

const translationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case LOAD_TRANSLATIONS:
      return {
        ...state,
        language: action.payload.language,
        translations: {
          ...state.translations,
          [action.payload.language]: action.payload.texts,
        },
      };
    default:
      return state;
  }
};

export default translationReducer;
