
import React from 'react';

import useTranslation from '../../Hooks/UseTranslation';

import { getGroupOptionsFromTaskList } from '../../Helpers/TasksHelper';

import SelectFormControl from '../SelectFormControl/SelectFormControl';

type Props = {
  taskList?: any;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

  const GroupSelector = (props: Props) => {

    const { t } = useTranslation()

    const options = getGroupOptionsFromTaskList(props.taskList, 0)

    return (
      <SelectFormControl
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        <option value="">
          {t('GROUP_SELECTOR:Select')}
        </option>
        {options.map((option: any, i: number) => (
          <option key={i} value={option.value}>{option.label}</option>
        ))}
      </SelectFormControl>
    )
  }

  export default GroupSelector
