import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import config from '../../config'
import navigation from '../../navigation'
import { Can } from '../../Casl/Casl'

import History from '../../Helpers/History';

import { useAuth } from '../../Hooks/UseAuth';
import { usePlan } from '../../Hooks/UsePlan';
import useTranslation from '../../Hooks/UseTranslation';

import ProjectsService from '../../Services/ProjectsService';

import ProjectsActions from '../../Redux/Actions/ProjectsActions'
import TeamsActions from '../../Redux/Actions/TeamsActions'
import UsersActions from '../../Redux/Actions/UsersActions'

import MainLayout from '../../Components/MainLayout/MainLayout'
import TeamCreator from '../../Components/TeamCreator/TeamCreator'
import OptionsDialog, { Option } from '../../Components/OptionsDialog/OptionsDialog';
import TeamMembersSelector from '../../Components/TeamMembersSelector/TeamMembersSelector'
import TeamSelector from '../../Components/TeamSelector/TeamSelector'
import UserAvatar from '../../Components/UserAvatar/UserAvatar'
import TeamEditor from '../../Components/GantV2/TeamEditor/TeamEditor';

import ProfileSelector from './ProfileSelector'

import './ProjectTeamsPage.scss'

const ProjectTeamsPage = (props: any) => {

  const { t } = useTranslation()

  const dispatch: any = useDispatch()
  const history = useHistory();

  const project_id = props.match.params._id
  const team_id = props.match.params.team_id

  const { ability, userData } = useAuth()
  const {
    plan,
    tooManyObservers,
    tooManyCollaborators,
    tooManyTeams,
    showTooManyTeamsModal,
    showTooManyObserversModal,
    showTooManyCollaboratorsModal
  } = usePlan()

  const project = useSelector((state: any) => state.ProjectsReducer.rowData)
  const teams = useSelector((state: any) => state.TeamsReducer.result)
  const users = useSelector((state: any) => state.UsersReducer.result)

  const [ teamCreator, setTeamCreator ] = useState({
    show: false,
    team: undefined
  })

  const [ showTeamEditor, setShowTeamEditor ] = useState(false)

  const [ teamSelector, setTeamSelector ] = useState({
    show: false,
    teamSelected: {},
    userSelected: {},
  })

  const [ membersSelector, setMembersSelector ] = useState({
    show: false,
    searched: false,
    searching: false
  })

  const [ teamSelected, setTeamSelected ] = useState(teams && teams[ 0 ])

  const [ user, setUser ] = useState<any>({})
  const [ showUserOptions, setShowUserOptions ] = useState(false)

  const [ tareasAsignadas, setTareasAsignadas ] = useState(false)

  const [ menu, submenu ] = navigation.getNavigation(
    props.match.params._id,
    'teams',
    ''
  )

  const loadProject = () => {
    dispatch(ProjectsActions.getRowAction(project_id))
  }

  const loadTeams = async () => {
    await dispatch(TeamsActions.getResultAction(project_id))
  }

  const selectTeam = (_team: any) => {

    if (tooManyTeams) {

      showTooManyTeamsModal(t('TEAMS:TooManyTeamsTitle'))

      return
    }

    setTeamSelected(_team)

    History.replace(`/project/${project._id}/teams/${_team._id}`)
  }

  const saveTeam = (team: any) => {
    if (!team._id) {
      dispatch(TeamsActions.createRowAction(team.name, project_id))

      setTeamCreator({ ...teamCreator, show: false })
    }
  }

  const handleSearchUser = (criteria: string) => {
    if (!criteria) {
      return
    }

    dispatch(UsersActions.searchAction(criteria, teamSelected._id))
  }

  const handleCreatePressed = () => {

    if (plan.can('create', 'Team')) {

      setTeamCreator({ ...teamCreator, show: true })
    } else {

      showTooManyTeamsModal(t('TEAMS:CantCreateMoreTeams'))
    }
  }

  const handleAddPressed = () => {

    if (plan.can('add', 'Observer')) {

      setMembersSelector({ ...membersSelector, show: true })
    } else {

      showTooManyObserversModal(t('TEAMS:CantAddMoreObservers'), project.user === userData?.id)
    }
  }

  const addUser = async (user: any) => {
    if (userData?.id === user._id) {
      await dispatch(TeamsActions.addMeAction(teamSelected._id, project_id))
    } else {
      const res = await dispatch(
        TeamsActions.inviteUserAction(user._id, teamSelected._id, project_id)
      )

      if (res) {

        Swal.fire({
          title: t('TEAMS:AddUserSuccessTitle'),
          text: t('TEAMS:AddUserSuccessContent'),
          icon: 'success',
          confirmButtonText: t('TEAMS:AddUserSuccessConfirmButton'),
          width: '500px'
        })
      }
    }
  }

  const sendInviteMail = async (email: string) => {
    await dispatch(
      TeamsActions.mailInviteUserAction(email, teamSelected._id, project_id)
    )

    setMembersSelector({ ...membersSelector, show: false })

    Swal.fire({
      title: t('TEAMS:InviteSentTitle'),
      text: t('TEAMS:InviteSentContent'),
      icon: 'success',
      confirmButtonText: t('TEAMS:InviteSentConfirmButton'),
      width: '500px'
    })

  }

  const removeUserFromTeam = async (user_id: string) => {
    const result = await Swal.fire({
      title: t('TEAMS:RemoveUserAlertTitle'),
      text: t('TEAMS:RemoveUserAlertContent'),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t('TEAMS:RemoveUserAlertCancelButton'),
      confirmButtonText: t('TEAMS:RemoveUserAlertConfirmButton'),
      width: "500px"
    })

    if (result.isConfirmed) {
      dispatch(TeamsActions.removeUserAction(user_id, teamSelected._id, project_id))
    }
  }

  const toggleShowOnlyMyTasks = async (user_id: string, user: any) => {
    if (!user.showOnlyMyTasks) {
      const result = await Swal.fire({
        title: t('TEAMS:ShowOnlyMyTasksAlertTitle'),
        text: t('TEAMS:ShowOnlyMyTasksAlertContent'),
        icon: "question",
        showCancelButton: true,
        cancelButtonText: t('TEAMS:ShowOnlyMyTasksAlertCancelButton'),
        confirmButtonText: t('TEAMS:ShowOnlyMyTasksAlertConfirmButton'),
        width: "500px"
      })

      if (result.isConfirmed) {

        dispatch(TeamsActions.toggleShowOnlyMyTasks(project_id, user_id, true))
      }
    }

    if (user.showOnlyMyTasks) {
      const result = await Swal.fire({
        title: t('TEAMS:DeleteOnlyMyTasksFilterTitle'),
        text: t('TEAMS:DeleteOnlyMyTasksFilterContent'),
        icon: "info",
        showCancelButton: true,
        cancelButtonText: t('TEAMS:DeleteOnlyMyTasksFilterCancelButton'),
        confirmButtonText: t('TEAMS:DeleteOnlyMyTasksFilterConfirmButton'),
        width: "500px"
      })

      if (result.isConfirmed) {

        dispatch(TeamsActions.toggleShowOnlyMyTasks(project_id, user_id, false))
      }
    }
  }

  const removeTeam = async (e: any, team: string) => {
    e.preventDefault()
    e.stopPropagation()

    const answer = await Swal.fire({
      title: t('TEAMS:RemoveTeamAlertTitle'),
      text: t('TEAMS:RemoveTeamAlertContent'),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t('TEAMS:RemoveTeamAlertCancelButton'),
      confirmButtonText: t('TEAMS:RemoveTeamAlertConfirmButton'),
      width: "500px"
    })

    if (answer.isConfirmed) {
      dispatch(TeamsActions.deleteRowAction(team, project_id)).then(() => {
        loadTeams()
        setShowTeamEditor(false)
      })
    }
  }

  const moveToTeam = async (toTeam: any) => {

    const user: any = teamSelector.userSelected
    const from_team: any = teamSelector.teamSelected

    history.replace(`/project/${project._id}/teams/${toTeam._id}`);

    await dispatch(
      TeamsActions.moveUserAction(
        project._id,
        user._id,
        from_team._id,
        toTeam._id
      )
    )

    setTeamSelector({
      ...teamSelector,
      show: false,
      userSelected: {},
      teamSelected: {}
    })
  }

  const handleSetProfile = async (user: any, profileTag: string) => {

    if (profileTag === 'observer' || profileTag === 'collaborator') {

      await ProjectsService.removeAssistant(project._id, user._id)
    } else {

      await ProjectsService.addAssistant(project._id, user._id)
    }

    loadTeams()
  }

  const handleShowUserOptions = (user: any) => {

    setUser(user)
    setShowUserOptions(true)
  }

  const getOptions = () => {

    const options: Option[] = []

    if (ability.can('move', 'TeamMember')) {

      options.push({
        icon: <span className="icon-cambiar_equipo" />,
        label: t('TEAMS:ContextMenuSwapTeamOption'),
        onClick: () => setTeamSelector({
          ...teamSelector,
          show: true,
          teamSelected: teamSelected,
          userSelected: user
        })
      })
    }

    if (user.profile_tag !== 'pl4nner' && user.profile_tag !== 'observer') {

      options.push({
        icon: <span className="icon-tareas_usuario" />,
        label: t('TEAMS:ContextMenuShowOnlyMyTasksOption'),
        color: user.showOnlyMyTasks ? 'var(--color-green)' : 'var(--color-secondary)',
        onClick: () => toggleShowOnlyMyTasks(user._id, user)
      })
    }

    if (ability.can('remove', 'TeamMember')) {

      options.push({
        icon: <span className="icon-eliminar" />,
        color: 'var(--color-danger)',
        label: t('TEAMS:ContextMenuRemoveTeamMemberOption'),
        onClick: () => removeUserFromTeam(user._id)
      })
    }

    return options
  }

  useEffect(() => {
    if (!project._id) {
      loadProject()
    }
  }, [ project ])

  useEffect(() => {
    if (project._id) {

      if (project.ability) ability.update(project.ability)

      loadTeams()
    }
  }, [ project._id ])

  useEffect(() => {
    if (teams && teams.length) {

      if (!team_id) {
        if (!tooManyTeams) {
          selectTeam(teams[0])
          setTeamSelected(teams[0])
        }
      } else {
        teams.map((_team: any) => {

          if (_team._id === team_id) {
            setTeamSelected(_team)
          }
        })
      }
    }
  }, [ teams ])

  useEffect(() => {

    if (tooManyTeams)
      showTooManyTeamsModal(t('TEAMS:TooManyTeamsTitle'))

    if (tooManyObservers)
      showTooManyObserversModal(t('TEAMS:TooManyObserversTitle'), project.user === userData?.id)

    if (tooManyCollaborators)
      showTooManyCollaboratorsModal(t('TEAMS:TooManyAssistantsOrCollaboratorsTitle'), project.user == userData?.id)
  }, [ tooManyTeams, tooManyObservers, tooManyCollaborators ])

  return (
    <MainLayout
      username={`${userData?.name} ${userData?.surname}`}
      project={project}
      menuBar={menu}
      rightTitle={project.shortName}
      avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
    >
      <div className='row'>
        <div className='col-12 pb-3'>
          <div className='page-content'>
            <div className='page-content-body'>
              <div className='ul-widget__head'>
                <div className='ul-widget__head-label flex-grow-1'>
                  <h3 className='ul-widget__head-title'>
                    {t('TEAMS:PageTitle')}
                  </h3>
                </div>
                <div className="ul-widget__head-toolbar">
                  <div className="text-right">
                    <Can I="create" a="Team" ability={ability}>
                      <button
                        className="btn btn-link btn-link-header mt-1"
                        onClick={() => handleCreatePressed()}>
                        <span className="icon-agregar_equipo"></span>{" "}
                        <span className="d-none d-sm-inline">
                          {t('TEAMS:ToolbarCreateTeamButton')}
                        </span>
                      </button>
                    </Can>
                    <Can I="add" a="Observer" ability={ability}>
                      <button
                        className="btn btn-link btn-link-header mt-1"
                        onClick={() => handleAddPressed()}
                        disabled={teamSelected === 0 || !teamSelected}>
                        <span className="icon-agregar_colaborador"></span>{' '}
                        <span className="d-none d-sm-inline">
                          {t('TEAMS:ToolbarAddTeamMemberButton')}
                        </span>
                      </button>
                    </Can>
                  </div>
                </div>
              </div>
              <div className="ul-widget__body">
                <div
                  className="row"
                  style={{
                    height: "calc(100% - 50px)"
                  }}>
                  <div className="col col-sm-3 mb-2">
                    {teams && teams.length > 0 && (
                      <Card style={{ overflow: 'hidden' }}>
                        <div>
                          <div className="list-group">
                            {teams.map((_team: any) => {
                              const clase =
                                teamSelected && teamSelected._id === _team._id ? 'active' : ''

                              return (
                                <div
                                  key={_team._id}
                                  className={`cursor-pointer list-group-item list-group-item-action ${clase}`}
                                  style={{
                                    border: '0 none'
                                  }}
                                  onClick={() => selectTeam(_team)}>
                                  <Can I="delete" this="team" ability={ability}>
                                    <button
                                      className="delete-button"
                                      onClick={(e)=>{
                                        e.preventDefault()
                                        e.stopPropagation()

                                        selectTeam(_team)
                                        setShowTeamEditor(true)
                                      }}
                                    >
                                      <i className="fas fa-fw fa-ellipsis-v"></i>
                                    </button>
                                  </Can>
                                  <span className="icon-grupo_equipo"></span>{" "}
                                  {_team.name}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div className="col col-sm-9">
                    {teamSelected && (
                      <Card className='table-card'>
                        <div>
                          <div
                            className="table-responsive"
                            style={{
                              height: teamSelected.users.length <= 1 ? "150px" : "auto",
                            }}>
                            <table className="table card-table table-container" >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      whiteSpace: 'nowrap',
                                      width: '30%'
                                    }}
                                  >
                                    {t('TEAMS:ListTableNameColumn')}
                                  </th>
                                  <th style={{ width: '10%' }}>
                                    {t('TEAMS:ListTableAvatarColumn')}
                                  </th>
                                  <th style={{ width: '30%' }}>
                                    {t('TEAMS:ListTableMailColumn')}
                                  </th>
                                  <th style={{ width: '15%' }}>
                                    {t('TEAMS:ListTableProfileColumn')}
                                  </th>
                                  <th style={{ width: '15%' }}>
                                    {t('TEAMS:ListTableActionsColumn')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!teamSelected.users.length ? (
                                  <tr className="table-secondary">
                                    <td colSpan={4}>
                                      {t('TEAMS:ListTableNoMembersYet')}
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {teamSelected.users.map((user: any, i: number) => (
                                      <tr key={`${i}-${user._id}`}>
                                        <td>
                                          {`${i + 1}. ${user.name} ${user.surname}`}
                                        </td>
                                        <td>
                                          <UserAvatar user={user} />
                                        </td>
                                        <td>{user.email}</td>
                                        <td>
                                          <Can not
                                            I="set-assistant"
                                            a="team-member"
                                            ability={ability}
                                          >
                                            {user.isAssistant
                                              ? t('TEAMS:ListTableAssistantProfile')
                                              : user.profile}
                                          </Can>
                                          <Can
                                            I="set-assistant"
                                            a="team-member"
                                            ability={ability}
                                          >
                                            {user.profile_tag === 'pl4nner' ? (
                                              <>{user.profile}</>
                                            ) : (
                                              <ProfileSelector
                                                profile={{
                                                  name: user.profile,
                                                  tag: user.profile_tag
                                                }}
                                                isAssistant={user.isAssistant}
                                                onSelect={(profileTag: string) => handleSetProfile(user, profileTag)}
                                              />
                                            )}
                                          </Can>
                                        </td>
                                        <td>
                                          <div className="btn-toolbar justify-content-center dropdownprojectteam">
                                            <Can I="move" this="team-member" ability={ability}>
                                              <button className='btn btn-light mr-1'
                                                onClick={() => handleShowUserOptions(user)}
                                              >
                                                <i className="fas fa-fw fa-ellipsis-v" />
                                              </button>
                                            </Can>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TeamSelector
        show={teamSelector.show}
        teams={teams}
        teamSelected={teamSelector.teamSelected}
        onSelectTeam={(toTeam: any) => moveToTeam(toTeam)}
        onHide={() =>
          setTeamSelector({ ...teamSelector, show: false, teamSelected: {} })
        }
      />

      {teamCreator.show && (
        <TeamCreator
          show={teamCreator.show}
          team={teamCreator.team}
          saveDispatch={(team: any) => saveTeam(team)}
          onHide={() =>
            setTeamCreator({ ...teamCreator, show: false, team: undefined })
          }
        />
      )}

      <TeamMembersSelector
        show={membersSelector.show}
        team={teamSelected}
        users={users}
        myId={userData?.id}
        searchDispatch={(criteria: string) => handleSearchUser(criteria)}
        sendMailDispatch={(email: string) => sendInviteMail(email)}
        onAddUser={(user: any) => addUser(user)}
        onHide={() => setMembersSelector({ ...membersSelector, show: false })}
      />

      <TeamEditor
        show={showTeamEditor}
        onDelete={(e:any) => removeTeam(e, team_id)}
        team={teamSelected}
        onHide={() => {
          setShowTeamEditor(false)
        }}
      />

      <OptionsDialog
        show={showUserOptions}
        title={`${user?.name} ${user?.surname}`}
        closeButton
        onHide={() => {

          setUser({})
          setShowUserOptions(false)
        }}
        options={getOptions()}
      />
    </MainLayout >
  )
}

export default ProjectTeamsPage
