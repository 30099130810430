
import React, { useEffect, useState } from "react";

import useTranslation from "../../Hooks/UseTranslation";

interface Strings {
  fixed: boolean
  name: string
  from: any
  to: any

}

type Props = {
  name: string,
  from: any,
  to: any,
}

const ChangeString = (props: Props) => {

  const { t } = useTranslation()

  const [ strings, setStrings ] = useState<Strings>({
    fixed: false,
    name: '',
    from: '',
    to: '',
  })

  const fixStrings = () => {

    let name = 'unnamed'
    let from = props.from
    let to = props.to

    switch (props.name) {
      case 'name': {
        name = t('CHANGE_STRING:TheName')
        break
      }
      case 'type': {

        name = t('CHANGE_STRING:TheType')
        break
      }
      case 'progress': {

        name = t('CHANGE_STRING:TheProgress')
        from = props.from || props.from == 0 ? `${props.from}%` : null
        to = props.to || props.to == 0 ? `${props.to}%` : null
        break
      }
      case 'colorCode': {

        name = t('CHANGE_STRING:TheColorCode')
        from = props.from ? props.from.name : null
        to = props.to ? props.to.name : null
        break
      }
      case 'executeDays': {

        name = t('CHANGE_STRING:ExecuteDays')
        break
      }
      case 'unit': {

        name = t('CHANGE_STRING:TheUnit')
        break
      }
      case 'metered': {

        name = t('CHANGE_STRING:TheMetered')
        break
      }
      case 'performance': {

        name = t('CHANGE_STRING:ThePerformance')
        break
      }
      case 'crewNumber': {

        name = t('CHANGE_STRING:TheCrewNumber')
        break
      }
      case 'estimatedDays': {

        name = t('CHANGE_STRING:EstimatedDays')
        break
      }
    }

    setStrings({
      ...strings,
      fixed: true,
      name,
      from,
      to,
    })
}

  useEffect(() => {

    fixStrings()
  }, [])

  if (!props.from && !props.to) return null

  if (!strings.fixed) return null

  return (
    <>
      {(strings.from || strings.from === 0 || strings.from === '') ? (
        <>
          {strings.name} {' '}
          {t('CHANGE_STRING:ChangedFrom')} {' '}
          <span className="change removed">{strings.from}</span> {' '}
          {t('CHANGE_STRING:To')} {' '}
          <span className="change added">{strings.to}</span>
        </>
      ) : (
        <>
          {strings.name} {' '}
          {t('CHANGE_STRING:SettedTo')} {' '}
          <span className="change added">{strings.to}</span>
        </>
      )}
    </>
  )
}

export default ChangeString
