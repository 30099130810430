
import React, { useEffect, useRef } from 'react'

import useTranslation from '../../Hooks/UseTranslation';

import ColumnCell from './ColumnCell';

import './DataGrid.scss'

export type Row = any;
export type Column<T> = {
  key: string,
  name: string,
  editable?: boolean,
  resizable?: boolean,
  sortable?: boolean,
  width: number,
}

interface Cell {
  index: number
  row: any
  column: string
  width: number
  value: string | undefined
  hasError: boolean
}

export interface DataGridProps {
  columns: Column<Row>[]
  rows: Row[]
  onRowsChange?: (rows: Row[]) => void
  hasErrors: boolean
}

const DataGrid = (props: DataGridProps) => {

  const { t } = useTranslation()

  const rowRef = useRef<HTMLDivElement>(null)
  const cellsRef = useRef<HTMLDivElement[]>([])
  const resizingItemRef = useRef<any>({
    index: -1,
    left: 0,
  })
  const borderTopRef = useRef<HTMLDivElement>(null)
  const borderRightRef = useRef<HTMLDivElement>(null)
  const borderBottomRef = useRef<HTMLDivElement>(null)
  const borderLeftRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const EDIT_ON_KEYUP = false

  const [ bodyHeight, setBodyHeight ] = React.useState<number>(0)

  const [ grid, setGrid ] = React.useState<any[]>([])

  const [ columnsWidth, setColumnsWidth ] = React.useState<number[]>(props.columns.map((column: any) => column.width))

  const [ selectedCell, setSelectedCell ] = React.useState<Cell>()

  const [ cellValue, setCellValue ] = React.useState<string>('')
  const cellEditingReff = useRef({ row: -1, column: '' })

  const setCurrentCell = (cell: any, i: number) => {
    const x = cellsRef.current[ cell.index ].offsetLeft
    const y = cellsRef.current[ cell.index ].offsetTop
    const w = cellsRef.current[ cell.index ].offsetWidth
    const h = cellsRef.current[ cell.index ].offsetHeight

    borderTopRef.current!.style.top = `${y - 2}px`
    borderTopRef.current!.style.left = `${x - 2}px`
    borderTopRef.current!.style.width = `${w + 3}px`

    borderRightRef.current!.style.top = `${y - 2}px`
    borderRightRef.current!.style.left = `${x + w - 1}px`
    borderRightRef.current!.style.height = `${h + 4}px`

    borderBottomRef.current!.style.top = `${y + h}px`
    borderBottomRef.current!.style.left = `${x - 2}px`
    borderBottomRef.current!.style.width = `${w + 3}px`

    borderLeftRef.current!.style.top = `${y - 2}px`
    borderLeftRef.current!.style.left = `${x - 2}px`
    borderLeftRef.current!.style.height = `${h + 4}px`

    textareaRef.current!.style.display = 'none'

    setSelectedCell(cell)
  }

  const handleEditCell = (cell: any) => {
    const x = cellsRef.current[ cell.index ].offsetLeft
    const y = cellsRef.current[ cell.index ].offsetTop
    const w = cellsRef.current[ cell.index ].offsetWidth

    const { row, column, value } = cell

    setCellValue(value || '')

    textareaRef.current!.style.top = `${y - 2}px`
    textareaRef.current!.style.left = `${x - 2}px`
    textareaRef.current!.style.width = `${w + 3}px`
    textareaRef.current!.style.display = 'block'

    textareaRef.current!.focus()

    cellEditingReff.current = { row, column }
  }

  const getRowsFromGrid = () => {

    const rows: any[] = []

    grid.map((cellsRow: any) => {

      const row: any = {}

      cellsRow.map((cell: any) => {

        if (cell.value) row[ cell.column ] = cell.value
      })

      rows.push(row)
    })

    return rows
  }

  //todo al teclear con cualquier tecla del teclado
  const handleCellKeyDown = (event: React.KeyboardEvent) => {

    if (!EDIT_ON_KEYUP) return

    if (event.key === 'Enter') {
      event.preventDefault()

      handleEditCell(selectedCell)
    } else {
      // must start to write on cell
      event.preventDefault()

      const text = event.key

      handleEditCell(selectedCell)
      handleTextareaChange(text)
    }
  }

  const handleTextareaChange = (value: string) => {

    const row = cellEditingReff.current.row
    const column = cellEditingReff.current.column

    const newGrid = grid.map((cellsRow: any) => {

      return cellsRow.map((cell: any) => {

        if (cell.row === row && cell.column === column) {
          cell.value = value
        }

        return cell
      })
    })

    setGrid(newGrid)
    setCellValue(value)
  }

  const handleTextareaKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {

    // must trigger change on enter
    if (event.key === 'Enter') {
      event.preventDefault()
      textareaRef.current!.style.display = 'none'

      handleTextareaChange(cellValue)

      const newRows = getRowsFromGrid()

      props.onRowsChange && props.onRowsChange(newRows)
    }
  }

  const getCellClass = (cell: Cell) => {

    const classes = [ 'data-grid__body__row__cell' ]

    if (cell.hasError) classes.push('invalid-cell')

    return classes.join(' ')
  }

  const getCellError = (cell: Cell) => {

    if (!cell.hasError) return ''

    const error = cell.row.errors.find((error: any) => error.field === cell.column)

    return error.message
  }

  const handleResizeStart = (columnLeft: number, index: number) => {

    resizingItemRef.current = {
      index,
      left: columnLeft,
    }
  }

  const handleResizeMove = (e: React.MouseEvent) => {

    if (resizingItemRef.current.index === -1) return

    const left = rowRef.current!.getBoundingClientRect().left
    const newWidth: number = e.pageX - left - resizingItemRef.current.left

    setColumnsWidth((prev: number[]) => {

        const newColumnsWidth = [ ...prev ]
        newColumnsWidth[ resizingItemRef.current.index ] = newWidth

        return newColumnsWidth
    })
  }

  const handleResizeEnd = () => {

    resizingItemRef.current.index = -1
    resizingItemRef.current.left = 0
  }

  useEffect(() => {

    const getGrid = () => {

      const grid: any[] = []

      props.rows.forEach((item: any, i: number) => {

        const cellsRow: Cell[] = []

        props.columns.forEach((column: any, j: number) => {

          cellsRow.push({
            index: i * props.columns.length + j,
            row: item,
            column: column.key,
            value: item[ column.key ],
            width: column.width,
            hasError: item.errors.some((error: any) => error.field === column.key)
          })
        })

        grid.push(cellsRow)
      })

      return grid
    }

    setGrid(getGrid())
  }, [ props.rows ])

  useEffect(() => {

    const getBodyHeight = () => {

      const modalHeight = window.innerHeight - 56
      const modalFooterHeight = 64

      const modalTitleHeight = 40

      const gridHeaderHeight = 31

      return modalHeight - modalFooterHeight - modalTitleHeight - gridHeaderHeight - 65
    }

    const handleResize = () => {

      const bodyHeight = getBodyHeight()

      setBodyHeight(bodyHeight)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="data-grid">
      <div className="data-grid__header">
        <div className="data-grid__header__row"
          ref={rowRef}
          onMouseMove={(e: React.MouseEvent) => handleResizeMove(e)}
          onMouseUp={() => handleResizeEnd()}
        >
          <div className="data-grid__header__row__cell">&nbsp;</div>
          {props.columns.map((column: any, i: number) => (
            <ColumnCell key={i}
              value={column.name}
              width={columnsWidth[ i ]}
              onResizeStart={(left: number) => handleResizeStart(left, i)}
              onResizeEnd={() => handleResizeEnd()}
            />
          ))}
        </div>
      </div>
      <div className="data-grid__body" style={{
        overflowY: 'auto',
        height: bodyHeight,
        position: 'relative',
      }}>
        {grid.map((cellsRow: any, i) => (
          <div
            key={i}
            className="data-grid__body__row"
          >
            <div className="data-grid__body__row__cell">
              {i + 1}
            </div>
            {cellsRow.map((cell: Cell, j: number) => (
              <div
                key={`${i}-${j}`}
                ref={ref => cellsRef.current[ i * props.columns.length + j ] = ref!}
                className={getCellClass(cell)}
                style={{ width: columnsWidth[ j ]}}
                onMouseDown={() => setCurrentCell(cell, j)}
                onDoubleClick={() => handleEditCell(cell)}
                tabIndex={0}
                onKeyDown={handleCellKeyDown}
                title={getCellError(cell)}
              >
                {cell.value}
              </div>
            ))}

          </div>
        ))}
        <div className="current-borders">
          <div ref={borderTopRef} className="current-borders__top"></div>
          <div ref={borderRightRef} className="current-borders__right"></div>
          <div ref={borderBottomRef} className="current-borders__bottom"></div>
          <div ref={borderLeftRef} className="current-borders__left"></div>
        </div>
        <textarea
          ref={textareaRef}
          value={cellValue}
          className="data-grid__body__textarea"
          onChange={(e: React.ChangeEvent) => handleTextareaChange((e.target as HTMLTextAreaElement).value)}
          // onBlur={}
          onKeyDown={handleTextareaKeyDown}
        ></textarea>
      </div>
      {props.hasErrors && (
        <div className="invalid-dates-message">
          * {t('DATA_GRID:InvalidDates')}
        </div>
      )}
    </div>
  )
}

export default DataGrid
