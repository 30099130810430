import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';

import { useAuth } from '../../../Hooks/UseAuth';
import { useChat } from '../../../Hooks/UseChat';
import useTranslation from '../../../Hooks/UseTranslation';

import TreeTeamItem from './TreeTeamItem';

interface ChatProjectItemProps {
  project: any;
  onSelectItem: () => void;
}

const TreeProjectItem: React.FC<ChatProjectItemProps> = ({ project, onSelectItem }) => {

  const { t } = useTranslation();

  const { userData } = useAuth()

  const { joinChat } = useChat();

  const [ open, setOpen ] = useState(false);

  const handleClick = (e: any) => {
    e.stopPropagation();

    joinChat([ 'project', project._id ]);
    onSelectItem();
  }


  return (
    <Accordion className={`chat-projects-tree-item ${open && 'open'}`}>
      <Accordion.Toggle as='div' className='toggle' eventKey="0" onClick={() => setOpen(!open)}>
        <span className='toggle-caret'>
          <i className="fas fa-caret-right"></i>
        </span>
        <button className="texts" onClick={(e: any) => handleClick(e)}>
          <span className={`icon-${project.user === userData?.id ? 'mis' : 'otros'}_proyectos`}></span>
          <span className='item-name'>{project.name}</span>
        </button>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <>
          {!project.teams.length && (
            <div className="item-no-subitems">
              {t('CHAT:SidebarProjectHasNoTeamsYet')}
            </div>
          )}
          {project.teams.map((team: any) => (
            <TreeTeamItem
              key={team._id}
              team={team}
              onSelectItem={onSelectItem}
            />
          ))}
        </>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default TreeProjectItem;




