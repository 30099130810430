
import axios, { AxiosHeaders, AxiosRequestHeaders } from 'axios'
import Cookies from 'js-cookie'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'

import { debug } from '../Helpers/DebugHelper'

import Toast from '../Components/Toast/Toast'

const moment = require('moment')

export const getHeaders = (): AxiosHeaders => {

    const headers = AxiosHeaders.from({
        'X-CONSUMER-ID': 'REACT-APP',
        'X-REQUEST-ID': uuidv4(),
        'Content-Type': 'application/json',
        'Accept-Language': localStorage.getItem('language') || 'es',
    }) as AxiosRequestHeaders

    const authToken = Cookies.get('authToken')

    if (authToken) {

        headers.set('Authorization', 'Bearer ' + authToken)
    }

    return headers
}

enum EMethods {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete'
}

const request = async (method: EMethods, type: string, url: string, data?: any) => {

    const apiStartsAt = moment()

    const reqHeaders = getHeaders()

    let response: any

    try {

        switch (method) {
            case EMethods.get:
                response = await axios.get(url, { headers: reqHeaders })
                break
            case EMethods.post:
                response = await axios.post(url, data, { headers: reqHeaders })
                break
            case EMethods.put:
                response = await axios.put(url, data, { headers: reqHeaders })
                break
            case EMethods.delete:
                response = await axios.delete(url, { headers: reqHeaders })
                break
        }

        if (response.data.error) {

            throw new Error(response.data.error)
        }

        onResponse(type, method, apiStartsAt, reqHeaders, response, data, url)

        return response
    } catch (error) {

        onError(type, method, apiStartsAt, reqHeaders, error, data, url)

        throw error
    }
}

const onResponse = (type: string, method: EMethods, apiStartsAt: any, reqHeaders: AxiosHeaders, response: any, data: any, url: string) => {

    const apiEndsAt = moment()

    const diff = apiEndsAt.diff(apiStartsAt)

    debug(`API REQUEST | SUCCESS`, {
        Url: url,
        Type: type,
        Request: {
            Method: method.toUpperCase(),
            Data: data,
            Duration: `${diff} ms`,
            'Request Id': reqHeaders['X-REQUEST-ID'],
            Headers: reqHeaders,
        },
        Response: response.data
    })
}

const onError = async (type: string, method: EMethods, apiStartsAt: number, reqHeaders: AxiosHeaders, error: any, data: any, url: string) => {

    if (error.message === 'Network Error') {

        Toast.show('Servidor no disponible', 'Intentelo nuevamente.')
    } else {

        switch(error.response.data.error) {
            // case 'invalid_dates_range':
            //     Toast.show('Error', 'El proyecto no acepta ese rango de fechas')
            //     break
            case 'project_not_found':
                await Swal.fire({
                    title: 'El proyecto ya no existe',
                    text: 'El propietario eliminó el proyecto',
                    icon: 'info',
                    confirmButtonText: 'Aceptar',
                    width: '500px',
                })

                window.location.href = '/'
                break
            default:
                console.log('Error', error.message)
        }
    }

    const apiEndsAt = moment()

    const diff = apiEndsAt.diff(apiStartsAt)

    debug(`API REQUEST | ERROR`, {
        Url: url,
        Type: type,
        Method: method.toUpperCase(),
        Data: data,
        Duration: `${diff} ms`,
        'Request Id': reqHeaders['X-REQUEST-ID'],
        Headers: reqHeaders,
        Error: error
    })


    if (error.response.status === 401 && window.location.pathname !== '/login') {

        console.log('your session has expired')
        window.location.href = '/login';
    }
}

export const get = async (url: string, type: string) => {

    try {

        const { data } = await request(EMethods.get, type, url)

        return { data }
    } catch (error) {

        return {
            error: error instanceof Error ? error.message : 'unknown error'
        }
    }
}

export const post = async (url: string, body: any, type: string) => {

    try {

        const { data } = await request(EMethods.post, type, url, body)

        return { data }
    } catch (error) {

        return {
            error: error instanceof Error ? error.message : 'unknown error'
        }
    }
}

export const put = async (url: string, body: any, type: string) => {

    try {

        const { data } = await request(EMethods.put, type, url, body)

        return { data }
    } catch (error) {

        return {
            error: error instanceof Error ? error.message : 'unknown error'
        }
    }
}

export const del = async (url: string, type: string) => {

    try {

        const { data } = await request(EMethods.delete, type, url)

        return { data }
    } catch (error) {

        return {
            error: error instanceof Error ? error.message : 'unknown error'
        }
    }
}

export {
    EMethods,
    request,
}
