
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import useTranslation from "../../Hooks/UseTranslation";

import SessionService from "../../Services/SessionService";

import { getApplicationSettingsAction } from "../../Redux/Actions/ApplicationActions";

const TermsAndPoliciesRequester = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { termsVersion, policiesVersion } = useSelector((st: any) => st.ApplicationReducer.settings)
  const { requestAcceptTermsAndPolicies } = useSelector((st: any) => st.ApplicationReducer)

  const [ termsChecked, setTermsChecked ] = useState(false)
  const [ policiesChecked, setPoliciesChecked ] = useState(false)

  const [ accepting, setAccepting ] = useState(false)

  const handleAccept = async () => {

    setAccepting(true)

    await SessionService.acceptTermsAndPolicies(termsVersion, policiesVersion)

    await dispatch(getApplicationSettingsAction())
    setAccepting(false)
  }

  return (
    <Modal
      show={requestAcceptTermsAndPolicies}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('TERMS_AND_POLICIES_MODAL:title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>
              {t('TERMS_AND_POLICIES_MODAL:content')}
            </p>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={termsChecked}
                onChange={() => setTermsChecked(!termsChecked)}
              />
              <label className="form-check-label" htmlFor="terms">
                {t('TERMS_AND_POLICIES_MODAL:content-terms-one')} {' '}
                <a href="https://pl4nner.com/privacidad-y-terminos/terminos-y-condiciones/" target="_blank" rel="noreferrer">
                  {t('TERMS_AND_POLICIES_MODAL:content-terms-two')} <i className="fas fa-external-link-alt"></i>
                </a>
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={policiesChecked}
                onChange={() => setPoliciesChecked(!policiesChecked)}
              />
              <label className="form-check-label" htmlFor="terms">
                {t('TERMS_AND_POLICIES_MODAL:content-policies-one')} {' '}
                <a href="https://pl4nner.com/privacidad-y-terminos/politicas-de-privacidad/" target="_blank" rel="noreferrer">
                  {t('TERMS_AND_POLICIES_MODAL:content-policies-two')} <i className="fas fa-external-link-alt"></i>
                </a>
              </label>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary"
          onClick={() => handleAccept()}
          disabled={!termsChecked || !policiesChecked || accepting}
        >
          {t('TERMS_AND_POLICIES_MODAL:button-accept')}{' '}
          {!accepting && <i className="fas fa-arrow-right"></i>}
          {accepting && <i className="fas fa-spinner fa-spin"></i>}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TermsAndPoliciesRequester
