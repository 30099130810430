import React from "react"
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap"

import useTranslation from "../../Hooks/UseTranslation"

import Icon from "../Icon/Icon"

interface Props {
  isOpen: boolean
  onClose: () => void
  onExport: (format: string) => void
}

const ExportModal = ({ isOpen, onClose, onExport }: Props) => {

  const { t } = useTranslation()

  const powerbiUrl = `https://app.pl4nner.com:8443/export/powerbi`;

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('EXPORT_MODAL:Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" mb-3">
          <span>{t('EXPORT_MODAL:PowerBILabel')}:</span>
          <div>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={powerbiUrl}
                readOnly
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  title={t('EXPORT_MODAL:CopyToClipboard')}
                  onClick={() => navigator.clipboard.writeText(powerbiUrl)}
                >
                  <Icon name="copy" far />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <p>{t('EXPORT_MODAL:DownloadFileLabel')}</p>
        <div className="d-flex justify-content-center">
          <a
            className="btn btn-normal mr-2"
            href={powerbiUrl}
            target="_blank"
            rel="noreferrer"
          >
            {t('EXPORT_MODAL:ExcelFileButton')}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ExportModal
