
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import config from "../../../config";

import { usePlan } from "../../../Hooks/UsePlan";

import ProjectsService from "../../../Services/ProjectsService";

import ProjectsActions from "../../../Redux/Actions/ProjectsActions";
import useTranslation from "../../../Hooks/UseTranslation";

interface Props {
  show: boolean
  title: string;
  projects: any[];
  maxProjectsAllowed: number;
  onHide: () => void;
}

const TooManyProjectsModal = (props: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { plan } = usePlan()

  const [ processing, setProcessing ] = useState<string[]>([])

  const handleArchive = async (project_id: string) => {

    setProcessing([ ...processing, project_id ])
    try {
      await ProjectsService.archiveProject(project_id);

      await dispatch(ProjectsActions.getResultAction())

      setProcessing(processing.filter((id) => id !== project_id))

    } catch (error) {
      console.error('Error archivando proyecto:', error)
    }
  };

  const handleDelete = async (project_id: string) => {

    setProcessing([ ...processing, project_id ])
    try {
      await ProjectsService.del(project_id)

      await dispatch(ProjectsActions.getResultAction())

      setProcessing(processing.filter((id) => id !== project_id))
    } catch (error) {
      console.error('Error eliminando proyecto:', error)
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('TOO_MANY_PROJECTS_MODAL:ContentA')} {props.maxProjectsAllowed} {t('TOO_MANY_PROJECTS_MODAL:ContentB')}
        </p>
        <p>
          <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i> {t('TOO_MANY_PROJECTS_MODAL:ContentC')}
          </a> {t('TOO_MANY_PROJECTS_MODAL:ContentD')}
        </p>
        <p>
          {t('TOO_MANY_PROJECTS_MODAL:ContentE')}
        </p>
        <div className="table-responsive">
          <div className="list-group">
            {props.projects.map((project) => (
              <div
                key={project._id}
                className={`list-group-item`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{project.name}</strong>
                  </div>
                  <div className="btns-group">
                    {processing.includes(project._id) && (
                      <span>
                        <i className="fas fa-spinner fa-spin"></i>
                        <span className="d-none d-sm-inline"> {t('TOO_MANY_PROJECTS_MODAL:Processing')}</span>
                      </span>
                    )}
                    {!processing.includes(project._id) && (
                      <>
                        <button className="btn btn-secondary"
                          onClick={() => handleArchive(project._id)}
                          disabled={!plan.can('archive', 'Project')}
                        >
                          <i className="fas fa-archive"></i>
                          <span className="d-none d-sm-inline"> {t('TOO_MANY_PROJECTS_MODAL:Archive')}</span>
                        </button>
                        <button className="btn btn-danger"
                          onClick={() => handleDelete(project._id)}
                        >
                          <i className="fas fa-trash"></i>
                          <span className="d-none d-sm-inline"> {t('TOO_MANY_PROJECTS_MODAL:Delete')}</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          {t('TOO_MANY_PROJECTS_MODAL:Close')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TooManyProjectsModal
