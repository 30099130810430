import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import config from "../../config";
import navigation from "../../navigation";

import { useAuth } from "../../Hooks/UseAuth";
import { useChat } from "../../Hooks/UseChat";

import MainLayoutHeader from "./MainLayoutHeader";
import MainLayoutSubMenuBar from "./MainLayoutSubMenuBar";
import MainLayoutTopBar from "./MainLayoutTopBar";
import MainLayoutFooter from "./MainLayoutFooter";

import "./PerfectScrollBar.css";
import "./MainLayout.scss"

type Props = {
  username?: string;
  avatar?: string;
  menuBar?: any;
  subMenuBar?: any;
  menu?: string;
  subMenu?: string;
  horizontalBar?: any;
  project?: any;
  hideMainMenu?: boolean;
  title?: string;
  subTitle?: string;
  rightTitle?: string;
  page?: string;
  noBottomBar?: boolean;
  children?: any;
};

const MainLayout = (props: Props) => {
  const noBottonBar = props.noBottomBar ?? false;

  const elemRef = useRef(null);

  const { userData } = useAuth()
  const { setActiveProject } = useChat()

  const project = useSelector((state: any) => state.ProjectsReducer.rowData);

  let menuBar, subMenuBar;

  if (props.menuBar || props.subMenuBar) {
    menuBar = props.menuBar;
    subMenuBar = props.subMenuBar;
  } else if (props.menu && props.subMenu) {
    [ menuBar, subMenuBar ] = navigation.getNavigation(
      project._id,
      props.menu,
      props.subMenu
    );
  }

  const notifications = useSelector(
    (state: any) => state.NotificationsReducer.result
  );

  const [ isFullscreen, setIsFullscreen ] = useState(false);

  const toggleFullscreen = async () => {
    if (!isFullscreen) {
      const elCurr: any = elemRef.current;

      if (elCurr == null) return;

      await elCurr.requestFullscreen();

      setIsFullscreen(true);
    } else {
      document.exitFullscreen();

      setIsFullscreen(false);
    }
  };

  useEffect(() => {

    if (project) setActiveProject(project._id);
  } , [ project ])

  return (
    <div ref={elemRef} className="app-admin-wrap layout-horizontal-bar">
      <MainLayoutHeader
        username={`${userData?.name} ${userData?.surname}`}
        avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ""}
        title={props.title}
        project={project}
        hideMainMenu={props.hideMainMenu}
        menuBar={menuBar}
        notifications={notifications}
        toggleFullscreen={() => toggleFullscreen()}
        isProfilePage={props.page === "profile"}
      />

      <MainLayoutSubMenuBar
        project={project}
        rightTitle={project.shortName}
        subTitle={props.subTitle}
        submenu={subMenuBar}
        notifications={notifications}
        isChatPage={props.page === "chat"}
      />

      {!props.horizontalBar ? null : (
        <MainLayoutTopBar toolbars={props.horizontalBar.toolbars} />
      )}
      <div
        className={`main-content-wrap d-flex flex-column custom-scrollbar ${noBottonBar ? "no-bottom-bar" : ""}`}>
        <div className="main-content">
          <div className="row">
            <div className="col-12 margen-movil">
              <div className="page-content">
                <div className="page-content-body">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {project && !noBottonBar && (
        <MainLayoutFooter project={project} menu={props.menuBar} />
      )}
    </div>
  );
};

export default MainLayout;
