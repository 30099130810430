
import React, { useEffect, useState } from 'react'
import moment from 'moment'

import useTranslation from '../../Hooks/UseTranslation'

import UserAvatar from '../UserAvatar/UserAvatar'

import './CommentItem.scss'

type Props = {
    comment: any,
    hideDate?: boolean,
}

const CommentItem = (props: Props) => {

    const { t } = useTranslation()

    const [ time, setTime ] = useState(moment(props.comment.createdAt).fromNow())

    const user = props.comment.user || {
        name: t('COMMENT_ITEM:UnknownUserName'),
        surname: t('COMMENT_ITEM:UnknownUserSurName')
    }

    const updateTime = () => {

        setTimeout(() => {

            updateTime()
            setTime(moment(props.comment.createdAt).fromNow())
        }, 1000)
    }

    useEffect(() => {

        updateTime()
    }, [ props.comment])

    return (
        <div className="comment-item mb-1">
            <div className="mt-1 mr-2">
                <UserAvatar user={props.comment.user} />
            </div>
          <div className="bubble purple-border-focus">
                <div className="d-flex">
                    <div className="username flex-grow-1">
                        {user.name} {user.surname}
                    </div>
                    {!props.hideDate && <div>{time}</div>}
                </div>
                <div>
                    {props.comment.comment}
                </div>
            </div>
        </div>
    )
}

export default CommentItem
