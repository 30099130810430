
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import config from "../../../config";

import { usePlan } from "../../../Hooks/UsePlan";
import useTranslation from "../../../Hooks/UseTranslation";

import ProjectsService from "../../../Services/ProjectsService";

import ProjectsActions from "../../../Redux/Actions/ProjectsActions";

import Icon from "../../Icon/Icon";

interface Props {
  show: boolean
  title: string;
  projects: any[];
  maxObservedProjectsAllowed: number;
  maxSharedProjectsAllowed: number;
  onHide: () => void;
}

const TooManyOthersProjectsModal = (props: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { plan } = usePlan()

  const [ processing, setProcessing ] = useState<string[]>([])

  const getProfileName = (tag: any): string => {

    const names: any = {
      assistant: 'Asistente',
      collaborator: 'Colaborador',
      observer: 'Observador'
    }

    console.log('tag:', tag, names[tag])

    return names[tag]
  }

  const handleAbandon = async (project_id: string) => {

    setProcessing([ ...processing, project_id ])
    try {
      await ProjectsService.abandon(project_id)

      await dispatch(ProjectsActions.getResultAction())

      setProcessing(processing.filter((id) => id !== project_id))
    } catch (error) {
      console.error('Error eliminando proyecto:', error)
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('TOO_MANY_OTHERS_PROJECTS_MODAL:ContentA')} {' '}
          {props.maxObservedProjectsAllowed + props.maxSharedProjectsAllowed} {' '}
          {t('TOO_MANY_OTHERS_PROJECTS_MODAL:ContentB')} {' '}
          {props.maxSharedProjectsAllowed} {t('TOO_MANY_OTHERS_PROJECTS_MODAL:ContentC')}
        </p>
        <p>
          <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i> {t('TOO_MANY_OTHERS_PROJECTS_MODAL:ContentD')}
          </a> {t('TOO_MANY_OTHERS_PROJECTS_MODAL:ContentE')}
        </p>
        <p>
          {t('TOO_MANY_OTHERS_PROJECTS_MODAL:ContentF')}
        </p>
        <div className="table-responsive">
          <div className="list-group">
            {props.projects.map((project) => (
              <div
                key={project._id}
                className={`list-group-item`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{project.name}</strong>
                    <br />
                    <small>{getProfileName(project.myProfile)}</small>
                  </div>
                  <div className="btns-group">
                    {processing.includes(project._id) && (
                      <span>
                        <Icon name="spinner" fas spin style={{ marginRight: 2 }} />
                        <span className="d-none d-sm-inline"> {t('TOO_MANY_OTHERS_PROJECTS_MODAL:Processing')}</span>
                      </span>
                    )}
                    {!processing.includes(project._id) && (
                      <>
                        <button className="btn btn-danger"
                          onClick={() => handleAbandon(project._id)}
                        >
                          <Icon name="user-minus" fas fw style={{ marginRight: 2 }}  />
                          <span className="d-none d-sm-inline"> {t('TOO_MANY_OTHERS_PROJECTS_MODAL:Abandon')}</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          {t('TOO_MANY_OTHERS_PROJECTS_MODAL:Close')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TooManyOthersProjectsModal
