
import useTranslation from "../../Hooks/UseTranslation"

export const getStatusName = (status: string) => {

    const { t } = useTranslation()

    switch(status) {
        case 'activa':
            return t('TASK_EDITOR:ActiveStatus')
        case 'pendiente':
            return t('TASK_EDITOR:PendingStatus')
        case 'liberada':
            return t('TASK_EDITOR:ReleasedStatus')
        case 'completada':
            return t('TASK_EDITOR:CompletedStatus')
        case 'atrasada':
            return t('TASK_EDITOR:OverdueStatus')
        case 'activa-atrasada':
            return t('TASK_EDITOR:ActiveOverdueStatus')
    }

    return ''
}

export const getMemberFromList = (list: any, _id: string) => {

    const found = list.filter((item: any) => item._id === _id)

    if (!found.length) return undefined

    return found[0]
}

export default {
    getStatusName,
    getMemberFromList,
}
