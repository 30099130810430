
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { Nav, Tab } from 'react-bootstrap'

import config from '../../config'
import navigation from '../../navigation'

import { useAuth } from '../../Hooks/UseAuth'
import { usePlan } from '../../Hooks/UsePlan'
import useTranslation from '../../Hooks/UseTranslation'

import ProjectsService from '../../Services/ProjectsService'

import InterfaceActions from '../../Redux/Actions/InterfaceActions'
import ProjectsActions from '../../Redux/Actions/ProjectsActions'
import ProjectCNCActions from '../../Redux/Actions/ProjectCNCActions'
import TeamsActions from '../../Redux/Actions/TeamsActions'
import { cleanMacro } from '../../Redux/Actions/MacroActions'
import { cleanLookahead } from '../../Redux/Actions/LookaheadActions'
import { cleanWeekly } from '../../Redux/Actions/WeeklyActions'
import { cleanIndicatorsPPCAction } from '../../Redux/Actions/IndicatorsPPCActions'
import { cleanResourcesCNCAction } from '../../Redux/Actions/ResourcesCNCActions'
import { cleanProgrammationAction } from '../../Redux/Actions/ProgrammationActions'
import { cleanTasksAction } from '../../Redux/Actions/TasksActions'
import { cleanDashboardAction } from '../../Redux/Actions/DashboardActions'
import { cleanResourcesRestrictionsAction } from '../../Redux/Actions/ResourcesRestrictionsActions'

import MainLayout from '../../Components/MainLayout/MainLayout'
import ProjectCreator from '../../Components/ProjectCreator/ProjectCreator'
import BigButton from '../../Components/BigButton/BigButton'
import MainLayoutContentBody from '../../Components/MainLayout/MainLayoutContentBody'
import MainLayoutContentHeader from '../../Components/MainLayout/MainLayoutContentHeader'
import Icon from '../../Components/Icon/Icon'
import ExportModal from '../../Components/ExportModal/ExportModal'

import './HomePage.scss'

const HomePage = () => {

    const { t } = useTranslation()

    const { userData } = useAuth()
    const {
        plan,
        tooManyActiveProjects,
        tooManyOthersProjects,
        showTooManyProjectsModal,
        showTooManyArchivedProjectsModal,
        showTooManyOthersProjectsModal,
    } = usePlan()

    const dispatch = useDispatch()

    const projects = useSelector((state: any) => state.ProjectsReducer.data)

    const [ showCreateModal, setShowCreateModal ] = useState(false)
    const [ showExportModal, setShowExportModal ] = useState(false)

    const [ menu, submenu ] = navigation.getNavigation(
        '',
        'homepage',
        'my_projects'
    )

    const [ tab, setTab ] = useState('mine')

    const isDisabled = (project: any) => {

        if (project.deleting) return true

        if (project.isMine)
            return tooManyActiveProjects

        return tooManyOthersProjects
    }

    const handleCreatePressed = () => {
        if (plan.can('create', 'Project')) {

            setShowCreateModal(true);
        } else {

            showTooManyProjectsModal(t('HOME:TooManyProjects-title'))
        }
    }

    const createProject = async (project: any) => {

        dispatch(InterfaceActions.showLoader())

        await dispatch(ProjectsActions.createAction(project))

        dispatch(InterfaceActions.hideLoader())
        setShowCreateModal(false)

        dispatch(ProjectsActions.getResultAction())
    }

    const handleAbandonMyProject = async (project_id: string) => {
        const confirmResult = await Swal.fire({
            title: t('HOME:AbandonMyProject-title'),
            text: t('HOME:AbandonMyProject-content'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: t('HOME:AbandonMyProject-cancel'),
            confirmButtonText: t('HOME:AbandonMyProject-accept'),
            width: '500px',
        });

        if (confirmResult.isConfirmed) {
            const teamMembers: any = await dispatch(TeamsActions.getResultAction(project_id));

            if (teamMembers.data == 0 || teamMembers.data[ 0 ].users.length === 1 && teamMembers.data[ 0 ].users[ 0 ].profile_tag === 'pl4nner') {

                dispatch(ProjectsActions.deleteAction(project_id));
                return; // Salir de la función si no hay teamMembers
            }



            const usersArray = teamMembers.data.flatMap((teamMember: any) => {

                const assistantUsers = teamMember.users.filter((user: any) => user.isAssistant === true);

                if (assistantUsers.length > 0) {
                    return assistantUsers.map((user: any) => ({

                        name: user.name,
                        surname: user.surname,
                        profile: 'Asistente Pl4nner',
                        id: user._id
                    }));
                }
                else {
                    return teamMember.users.filter((user: any) => user.profile_tag !== 'pl4nner').map((user: any) => ({
                        name: user.name,
                        surname: user.surname,
                        profile: user.profile,
                        id: user._id

                    }));
                }
            });

            const asistenteSeleccionadoId = await elegirAsistente(usersArray);

            if (asistenteSeleccionadoId) {
                dispatch(ProjectsActions.delegateAction(project_id, asistenteSeleccionadoId));
                dispatch(ProjectsActions.abandonAction(project_id));
            }
        }
    };

    const elegirAsistente = async (asistentesFromUsersArray: any[]) => {
        const asistentes = asistentesFromUsersArray.map(user => {
            const nombre = `${user.name} ${user.surname} - ${user.profile}`;
            return {
                nombre: nombre,
                id: user.id,
            };
        });


        const { value: asistenteSeleccionadoId } = await Swal.fire({
            onOpen: () => {
                // Remove focus from the currently active element
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }
            },
            title: t('HOME:ChooseAssistant-title'),
            input: 'select',
            icon: 'warning',
            inputOptions: Object.fromEntries(asistentes.map(asistente => [ asistente.id, asistente.nombre ])),
            inputPlaceholder: t('HOME:ChooseAssistant-placeholder'),
            showCancelButton: true,
            preConfirm: (value) => {
                if (!value) {
                    return Promise.reject(t('HOME:ChooseAssistant-must-choose'));
                }
                return Promise.resolve(value);
            },
            customClass: {
                input: 'my-custom-input-class',
            },
        });

        return asistenteSeleccionadoId;
    };

    const handleDelete = async (project_id: string) => {

        const result = await Swal.fire({
            title: t('HOME:DeleteProject-title'),
            text: t('HOME:DeleteProject-content'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: t('HOME:DeleteProject-cancel'),
            confirmButtonText: t('HOME:DeleteProject-accept'),
            width: '500px',
        })

        if (result.isConfirmed) {

            await dispatch(ProjectsActions.deleteAction(project_id))
        }
    }

    const loadProjects = async () => {

        await dispatch(ProjectsActions.getResultAction())
    }

    const handleArchive = async (project_id: string) => {

        if (!plan.can('archive', 'Project')) {

            showTooManyArchivedProjectsModal(t('HOME:TooManyArchivedProjects-title'))
        } else {

            try {
                await ProjectsService.archiveProject(project_id);

                await dispatch(ProjectsActions.getResultAction())

            } catch (error) {
                console.error('Error archivando proyecto:', error)
            }
        }
    };

    const handleAbandonOthersProject = async (project_id: string) => {

        const result = await Swal.fire({
            title: t('HOME:AbandonOthersProject-title'),
            text: t('HOME:AbandonOthersProject-content'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: t('HOME:AbandonOthersProject-cancel'),
            confirmButtonText: t('HOME:AbandonOthersProject-accept'),
            width: '500px',
        })

        if (result.isConfirmed) {

            dispatch(ProjectsActions.abandonAction(project_id))
        }
    }

    useEffect(() => {

        const clearData = () => {

            dispatch(ProjectsActions.clearProjectAction())
            dispatch(cleanDashboardAction())
            dispatch(cleanProgrammationAction())
            dispatch(cleanMacro())
            dispatch(cleanLookahead())
            dispatch(cleanWeekly())
            dispatch(cleanTasksAction())
            dispatch(cleanResourcesCNCAction())
            dispatch(cleanIndicatorsPPCAction())
            dispatch(cleanResourcesRestrictionsAction())
            dispatch(TeamsActions.clearDataAction())
            dispatch(ProjectCNCActions.clearAction())
        }

        loadProjects()

        clearData()
    }, [])

    useEffect(() => {

        if (tooManyActiveProjects)
            showTooManyProjectsModal(t('HOME:TooManyActiveProjects-title'))

        if (tooManyOthersProjects)
            showTooManyOthersProjectsModal(t('HOME:TooManyProjectsYouAreParticipatingIn-title'))
    }, [ tooManyActiveProjects, tooManyOthersProjects, projects.active ])

    return (
        <>
            <MainLayout
                title="PL4NNER"
                menuBar={menu}
                subMenuBar={submenu}
                username={`${userData?.name} ${userData?.surname}`}
                avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
                noBottomBar={true}
            >
                <MainLayoutContentHeader>
                    <div className='ul-widget__head-label flex-grow-1'>
                        <h3 className='ul-widget__head-title'>
                            {t('HOME:Page-title')}
                        </h3>
                    </div>
                    <div className="ul-widget__head-toolbar">
                        <div className="text-right">
                            <button className={`btn btn-link btn-link-header d-inline-block`}
                                onClick={() => handleCreatePressed()}
                                disabled={tab !== 'mine'}
                            >
                                <Icon name="agregar" />
                                <span className="d-none d-sm-inline"> {t('HOME:NewProjectButton')}</span>
                            </button>
                            <button className={`btn btn-link btn-link-header d-inline-block`}
                                onClick={() => setShowExportModal(true)}
                                disabled={tab !== 'mine'}
                            >
                                <Icon name="download" fas style={{ marginRight: 4 }} />
                                <span className="d-none d-sm-inline"> {t('HOME:ExportButton')}</span>
                            </button>
                        </div>
                    </div>
                    <div className="ul-widget__head-nav">
                        <Tab.Container>
                            <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
                                <Nav.Item as="li">
                                    <Nav.Link active={tab === 'mine'} onClick={() => setTab('mine')}>
                                        <span className="icon-mis_proyectos"></span> <span className="hide-on-mobile"> {t('HOME:MyProjectsTab')}</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link active={tab === 'others'} onClick={() => setTab('others')}>
                                        <span className="icon-otros_proyectos"></span> <span className="hide-on-mobile"> {t('HOME:SharedProyectsTab')}</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Tab.Container>
                    </div>
                </MainLayoutContentHeader>
                <MainLayoutContentBody>
                    <Tab.Content className='no-padding'>
                        <Tab.Pane active={tab === 'mine'}>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="row">
                                        {projects.mine?.map((project: any, i: number) => (
                                            <div key={i} className="col-xl-2 col-lg-3 col-md-4 mb-3">
                                                <BigButton
                                                    icon='icon-mis_proyectos'
                                                    text={project.shortName}
                                                    to={`/project/${project._id}`}
                                                    altMenu={[
                                                        {
                                                            icon: <img width="28" height="28" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABb0lEQVR4nO3ZXUoDMRAH8EWhqNRT6Cm8xzxlsLCSeSiUZB98sIKpQo9RT6N4B/UcgrCwskh1KVt2a5JJV2Ygj8nOLx//hzbLpKSkOouITgjNAyn7TmhLQlv1Hc11nHMHWaLmX3ZpehuA0K7YEVTv/B+bbwFU7Aj6vjYhAbwI2vHO9wTwIcij+Q5ApZV5jI6ICSAORGwAxUZwACgmggtAsRCcAIqB8AUAwOHvWuaz57xVMIQvgC5nZ+u1NJolO4I8AVqZO+8mkgLQfGicXQwWQGuEMosrLM6bb2IwAJ+RCQDlBKq9vEIazQ2AG/muD+BGpOycHTCF6TgLVHl+fcr/iJWdhzoBrcytpNC2khTCgacQtKRMcy4MIYU2U2Zzbr7PKdSaMo25ICnUUZJCKCnkV5JCkD6FyoQPuUz+B4fP0GhfQ5zAfTKACvCbUgHFMSn7zL/75mkycUfegB8EWkfKvEV+E2X9jXrngzUvJfXP6wsr118dzShiEAAAAABJRU5ErkJggg=="></img>,
                                                            label: t('HOME:ProjectButtonArchive'),
                                                            onClick: () => handleArchive(project._id)

                                                        },
                                                        {
                                                            icon: <i className="fas fa-fw fa-user-plus"></i>,
                                                            label: t('HOME:ProjectButtonAbandon'),
                                                            onClick: () => handleAbandonMyProject(project._id)

                                                        },
                                                        {
                                                            icon: <i className="fas fa-fw fa-trash-alt"></i>,
                                                            label: t('HOME:ProjectButtonDelete'),
                                                            color: '#f44336',
                                                            onClick: () => handleDelete(project._id)
                                                        },
                                                    ]}
                                                    onDisabledClick={() => {

                                                        if (project.isMine && tooManyActiveProjects)
                                                            showTooManyProjectsModal(t('HOME:TooManyActiveProjects-title'))
                                                    }}
                                                    disabled={isDisabled(project)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane active={tab === 'others'}>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="row">
                                        {projects.other?.map((project: any, i: number) => (
                                            <div key={i} className="col-xl-2 col-lg-3 col-md-4 mb-3">
                                                <BigButton
                                                    icon="icon-otros_proyectos"
                                                    text={project.shortName}
                                                    to={`/project/${project._id}`}
                                                    altButtonClick={() => handleAbandonOthersProject(project._id)}
                                                    onDisabledClick={() => {

                                                        if (tooManyOthersProjects)
                                                            showTooManyOthersProjectsModal(t('HOME:TooManyProjectsYouAreParticipatingIn-title'))
                                                    }}
                                                    disabled={isDisabled(project)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </MainLayoutContentBody>

                {showCreateModal && (
                    <ProjectCreator
                        show={showCreateModal}
                        onHide={() => setShowCreateModal(false)}
                        onSave={(project: any) => createProject(project)}
                    />
                )}
            </MainLayout>

            <ExportModal
                isOpen={showExportModal}
                onClose={() => setShowExportModal(false)}
                onExport={(format: string) => console.log(format)}
            />
        </>
    )
}

export default HomePage
