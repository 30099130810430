
import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Nav, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import useTranslation from '../../Hooks/UseTranslation';

const CncSelector = (props: any) => {

    const { t } = useTranslation()

    const [ tab, setTab ] = useState('obra_construccion');

    const projectCNC = useSelector((state:any) => state.ProjectCNCReducer)

    const [ activeCNCs, setActiveCncs ] = useState({
        obra: [],
        diseno: [],
        administracion: [],
        logistica: [],
        condiciones_externas: [],
    })

    const [ selected, setSelected ] = useState<any>()

    const getActiveCncs = () => {

        const obra: any = []
        const diseno: any = []
        const administracion: any = []
        const logistica: any = []
        const condiciones_externas: any = []

        projectCNC.result.map((projectCnc:any) => {
            if (projectCnc.enabled) {

                const cnc = { ...projectCnc.cnc, selected: false }

                if (projectCnc.cnc.type==="obra_construccion") {
                    obra.push(cnc)
                }else if(projectCnc.cnc.type==="diseno_oficina_tecnica_de_obra"){
                    diseno.push(cnc)
                }else if(projectCnc.cnc.type==="administracion"){
                    administracion.push(cnc)
                }else if(projectCnc.cnc.type==="logistica"){
                    logistica.push(cnc)
                }else if(projectCnc.cnc.type==="condiciones_externas"){
                    condiciones_externas.push(cnc)
                }
            }
        })

        setActiveCncs({
            obra,
            diseno,
            administracion,
            logistica,
            condiciones_externas,
        })
    }

    const isActive = (cnc:any) => {

        if (selected) {

            return cnc._id === selected._id
        }

        return false
    }

    const selectCnc = (cnc:any) => {

        setSelected(cnc)
    }

    const acceptSelect = () => {

        if (selected) {

            props.onSelect(selected)

            props.onHide()
        }
    }

    useEffect(() => {
        getActiveCncs()
    }, [projectCNC.result])

    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide()}
            dialogClassName="modal-cnc"
            backdrop="static"
            size="lg"
            keyboard={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('CNC_SELECTOR:Title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container>
                    <Nav as="ul"
                        className="nav-tabs nav-tabs-line nav-tabs-bold"
                        onSelect={(eventKey: any) => setTab(eventKey)}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="obra_construccion" active={tab === 'obra_construccion'}>
                                {t('CNC_SELECTOR:ProjectTab')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="diseno_oficina_tecnica_de_obra" active={tab === 'diseno_oficina_tecnica_de_obra'}>
                                {t('CNC_SELECTOR:DesignTab')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="administracion" active={tab === 'administracion'}>
                                {t('CNC_SELECTOR:AdministrationTab')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="logistica" active={tab === 'logistica'}>
                                {t('CNC_SELECTOR:LogisticsTab')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="condiciones_externas" active={tab === 'condiciones_externas'}>
                                {t('CNC_SELECTOR:ExternalConditionsTab')}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey='obra_construccion' active={tab === 'obra_construccion'}>
                            <ListGroup as="ul">
                                {activeCNCs.obra.map((cnc:any, i: number) => (
                                    <ListGroup.Item
                                        key={`${i}-${cnc._id}`}
                                        as="li"
                                        className="list-group-item-action cursor-pointer"
                                        onClick={() => selectCnc(cnc)}
                                        active={isActive(cnc)}
                                    >
                                        {cnc.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Tab.Pane>
                        <Tab.Pane eventKey='diseno_oficina_tecnica_de_obra' active={tab === 'diseno_oficina_tecnica_de_obra'}>
                            <ListGroup as="ul">
                                {activeCNCs.diseno.map((cnc:any, i: number) => (
                                    <ListGroup.Item
                                        key={`${i}-${cnc._id}`}
                                        as="li"
                                        className="list-group-item-action cursor-pointer"
                                        onClick={() => selectCnc(cnc)}
                                        active={isActive(cnc)}
                                    >
                                        {cnc.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Tab.Pane>
                        <Tab.Pane eventKey='administracion' active={tab === 'administracion'}>
                            <ListGroup as="ul">
                                {activeCNCs.administracion.map((cnc:any, i: number) => (
                                    <ListGroup.Item
                                        key={`${i}-${cnc._id}`}
                                        as="li"
                                        className="list-group-item-action cursor-pointer"
                                        onClick={() => selectCnc(cnc)}
                                        active={isActive(cnc)}
                                    >
                                        {cnc.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Tab.Pane>
                        <Tab.Pane eventKey='logistica' active={tab === 'logistica'}>
                            <ListGroup as="ul">
                                {activeCNCs.logistica.map((cnc:any, i: number) => (
                                    <ListGroup.Item
                                        key={`${i}-${cnc._id}`}
                                        as="li"
                                        className="list-group-item-action cursor-pointer"
                                        onClick={() => selectCnc(cnc)}
                                        active={isActive(cnc)}
                                    >
                                        {cnc.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Tab.Pane>
                        <Tab.Pane eventKey='condiciones_externas' active={tab === 'condiciones_externas'}>
                            <ListGroup as="ul">
                                {activeCNCs.condiciones_externas.map((cnc:any, i: number) => (
                                    <ListGroup.Item
                                        key={`${i}-${cnc._id}`}
                                        as="li"
                                        className="list-group-item-action cursor-pointer"
                                        onClick={() => selectCnc(cnc)}
                                        active={isActive(cnc)}
                                    >
                                        {cnc.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => acceptSelect()} disabled={!selected}>
                    {t('CNC_SELECTOR:SaveButton')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CncSelector
