
import moment, { Moment } from "moment"
import { getItemWidth } from "../Components/GantV2/GanttHelper"
import { aIsAfterB, aIsBeforeB, aIsSameOrAfterB, aIsSameOrBeforeB, getYMDFromDate } from "./DateFunctions"

type ProgrammationSchema = 'macro' | 'lookahead' | 'weekly'

export const filterByUsers = (items: any[], users: string[]) => {

  const filteredItems: any[] = []

  items.forEach((item: any) => {

    if (item.type === 'task') {

      const found = item.task.responsibles.find((responsible: any) => users.includes(responsible._id))

      if (found) {

        filteredItems.push(item)
      }
    } else {

      item.group.items = filterByUsers(item.group.items, users)

      if (item.group.items.length) {

        filteredItems.push(item)
      }
    }
  })

  return filteredItems
}

export const filterByGroups = (items: any[], groups: string[]) => {

  const filteredItems: any[] = []

  items.map((item: any) => {

      if (item.type === 'group') {

          if (groups.includes(item.group._id)) {

              filteredItems.push(item)
          } else {

              item.group.items = filterByGroups(item.group.items, groups)

              if (item.group.items.length) {

                  filteredItems.push(item)
              }
          }
      }
  })

  return filteredItems
}

export const filterByColorCodes = (items: any[], colorCodes: string[]) => {

  const filteredItems: any[] = []

  items.forEach((item: any) => {

      if (item.type === 'task') {

          const colorCode = item.task.colorCode

          if (colorCode && colorCodes.includes(colorCode._id.toString())) {

              filteredItems.push(item)
          }
      } else {

          item.group.items = filterByColorCodes(item.group.items, colorCodes)

          if (item.group.items.length) {

              filteredItems.push(item)
          }
      }
  })

  return filteredItems
}

export const filterByTypes = (items: any[], types: string[]) => {

  const filteredItems: any[] = []

  items.map((item: any) => {

      if (item.type === 'task') {

          if (types.includes(item.task.type)) {

              filteredItems.push(item)
          }
      } else {

          item.group.items = filterByTypes(item.group.items, types)

          if (item.group.items.length) {

              filteredItems.push(item)
          }
      }
  })

  return filteredItems
}

export const filterByStatus = (items: any[], status_arr: string[]) => {

  const filteredItems: any[] = []

  items.forEach((item: any) => {

      if (item.type === 'task') {

          if (status_arr.includes(item.task.status)) {

              filteredItems.push(item)
          }
      } else {

          item.group.items = filterByStatus(item.group.items, status_arr)

          if (item.group.items.length) {

              filteredItems.push(item)
          }
      }
  })

  return filteredItems
}

const matchDatesConditions = (task: any, start: string, end: string) => {

  const startsAt = new Date(task.startsAt)
  const endsAt = new Date(task.endsAt)

  const task_start = getYMDFromDate(startsAt)
  const task_end = getYMDFromDate(endsAt)

  if (start && !end) {

    if (aIsSameOrAfterB(task_end, start))
      return true

  } else if (!start && end) {

    if (aIsSameOrBeforeB(task_start, end))
      return true

  } else if (start && end) {

    const ends_same_or_before_start = aIsSameOrAfterB(task_end, start)
    const starts_same_or_after_end = aIsSameOrBeforeB(task_start, end)

    if (ends_same_or_before_start && starts_same_or_after_end)
      return true
  }

  return false
}

export const filterByDate = (items: any, start: string, end: string) => {

  const filteredItems: any[] = []

  items.map((item: any) => {

      if (item.type === 'task') {

          if (matchDatesConditions(item.task, start, end)) {

              filteredItems.push(item)
          }
      } else {

          item.group.items = filterByDate(item.group.items, start, end)

          if (item.group.items.length) {

              filteredItems.push(item)
          }
      }
  })

  return filteredItems
}

export const getMacroResult = ({ list, tasks, users, colorCodes, relations, filters, parentGroupId }: any) => {

  list.forEach((item: any, i: number) => {

    const position = `${parentGroupId ? parentGroupId + '.' : ''}${i + 1}`

    if (item.type === 'task') {

      const task = tasks.find((t: any) => t._id === item.task)

      if (!task)
        return

      task.position = position

      task.startsAt = moment.utc(task.startsAt).format('YYYY-MM-DD HH:mm')
      task.endsAt = moment.utc(task.endsAt).format('YYYY-MM-DD HH:mm')

      if (task.colorCode)
        task.colorCode = colorCodes.find((cc: any) => cc._id === task.colorCode)

      task.comments = task.comments.map((comment: any) => {

        comment.user = users.find((u: any) => u._id === comment.user)

        return comment
      })

      task.responsibles = task.responsibles.map((resp: string) => {

        return users.find((user: any) => user._id === resp)
      })

      task.restrictions = task.restrictions.map((rest: any) => {

        return {
          ...rest,
          createdBy: users.find((user: any) => user._id === rest.createdBy)
        }
      })

      task.relations = relations.filter((relation: any) => {

        return relation.from_task === task._id || relation.to_task === task._id
      })

      item.task = task
    } else {

      item.group.position = position

      item.group.items = getMacroResult({
        list: item.group.items,
        tasks,
        users,
        colorCodes,
        relations,
        filters,
        parentGroupId: position
      })
    }
  })

  return list
}

export const getLookaheadResult = (options: any) => {

  const newList: any[] = []

  options.list.forEach((item: any, i: number) => {

    const position = `${options.parentGroupId ? options.parentGroupId + '.' : ''}${i + 1}`

    if (item.type === 'task') {

      const task = options.tasks.find((task: any) => task._id === item.task)

      if (!task)
        return

      const startsBeforeLookeaheadStart = aIsBeforeB(task.startsAt, options.navigator.startDay)
      const startsSameOrAfterLookaheadStart = aIsSameOrAfterB(task.startsAt, options.navigator.startDay)
      const startsSameOrBeforeLookaheadEnd = aIsSameOrBeforeB(task.startsAt, options.navigator.endDay)

      const endsSameOrAfterLookaheadStart = aIsSameOrAfterB(task.endsAt, options.navigator.startDay)
      const endsAfterLookaheadEnd = aIsAfterB(task.endsAt, options.navigator.endDay)
      const endsSameOrBeforeLookaheadEnd = aIsSameOrBeforeB(task.endsAt, options.navigator.endDay)

      const startsBeforeButEndsInRange = startsBeforeLookeaheadStart && endsSameOrAfterLookaheadStart && endsSameOrBeforeLookaheadEnd
      const startsBeforeButEndsAfterRange = startsBeforeLookeaheadStart && endsAfterLookaheadEnd
      const startsInRangeAndEndsInRange = startsSameOrAfterLookaheadStart && endsSameOrBeforeLookaheadEnd
      const startsInRangeButEndsAfter = startsSameOrAfterLookaheadStart && startsSameOrBeforeLookaheadEnd && endsAfterLookaheadEnd

      if (startsBeforeButEndsInRange || startsBeforeButEndsAfterRange || startsInRangeAndEndsInRange || startsInRangeButEndsAfter) {

        task.position = position

        task.startsAt = moment.utc(task.startsAt).format('YYYY-MM-DD HH:mm')
        task.endsAt = moment.utc(task.endsAt).format('YYYY-MM-DD HH:mm')

        if (task.colorCode)
          task.colorCode = options.colorCodes.find((cc: any) => cc._id === task.colorCode)

        task.comments = task.comments.map((comment: any) => {

          comment.user = options.users.find((u: any) => u._id === comment.user)

          return comment
        })

        task.responsibles = task.responsibles.map((resp: string) => {

          return options.users.find((user: any) => user._id === resp)
        })

        task.restrictions = task.restrictions.map((rest: any) => {

          return {
            ...rest,
            createdBy: options.users.find((user: any) => user._id === rest.createdBy)
          }
        })

        task.relations = options.relations.filter((relation: any) => {

          return relation.from_task === task._id || relation.to_task === task._id
        })

        item.task = task

        newList.push(item)
      // } else {

      //   console.log('not in range', task.name, task.startsAt, task.endsAt)

      //   console.log({
      //     startsBeforeButEndsInRange,
      //     startsBeforeButEndsAfterRange,
      //     startsInRangeAndEndsInRange,
      //     startsInRangeButEndsAfter,
      //   })
      }
    } else {

      const subItems = getLookaheadResult({
        ...options,
        list: item.group.items,
        parentGroupId: position
      })

      if (subItems.length) {

        item.group.position = position

        item.group.items = subItems

        newList.push(item)
      }
    }
  })

  return newList
}

export const getWeeklyResult = (options: any) => {

  const newList: any[] = []

  options.list.forEach((item: any, i: number) => {

    const position = `${options.parentGroupId ? options.parentGroupId + '.' : ''}${i + 1}`

    if (item.type === 'task') {

      const task = options.tasks.find((task: any) => task._id === item.task)

      if (!task)
        return

      if (task.status === 'pendiente') return

      const startsBeforeLookeaheadStart = aIsBeforeB(task.startsAt, options.navigator.startDay)
      const startsSameOrAfterLookaheadStart = aIsSameOrAfterB(task.startsAt, options.navigator.startDay)
      const startsSameOrBeforeLookaheadEnd = aIsSameOrBeforeB(task.startsAt, options.navigator.endDay)

      const endsSameOrAfterLookaheadStart = aIsSameOrAfterB(task.endsAt, options.navigator.startDay)
      const endsAfterLookaheadEnd = aIsAfterB(task.endsAt, options.navigator.endDay)
      const endsSameOrBeforeLookaheadEnd = aIsSameOrBeforeB(task.endsAt, options.navigator.endDay)

      const startsBeforeButEndsInRange = startsBeforeLookeaheadStart && endsSameOrAfterLookaheadStart && endsSameOrBeforeLookaheadEnd
      const startsBeforeButEndsAfterRange = startsBeforeLookeaheadStart && endsAfterLookaheadEnd
      const startsInRangeAndEndsInRange = startsSameOrAfterLookaheadStart && endsSameOrBeforeLookaheadEnd
      const startsInRangeButEndsAfter = startsSameOrAfterLookaheadStart && startsSameOrBeforeLookaheadEnd && endsAfterLookaheadEnd

      if (startsBeforeButEndsInRange || startsBeforeButEndsAfterRange || startsInRangeAndEndsInRange || startsInRangeButEndsAfter) {

        task.position = position

        task.startsAt = moment.utc(task.startsAt).format('YYYY-MM-DD HH:mm')
        task.endsAt = moment.utc(task.endsAt).format('YYYY-MM-DD HH:mm')

        if (task.colorCode)
          task.colorCode = options.colorCodes.find((cc: any) => cc._id === task.colorCode)

        task.comments = task.comments.map((comment: any) => {

          comment.user = options.users.find((u: any) => u._id === comment.user)

          return comment
        })

        task.responsibles = task.responsibles.map((resp: string) => {

          return options.users.find((user: any) => user._id === resp)
        })

        task.restrictions = task.restrictions.map((rest: any) => {

          return {
            ...rest,
            createdBy: options.users.find((user: any) => user._id === rest.createdBy)
          }
        })

        task.relations = options.relations.filter((relation: any) => {

          return relation.from_task === task._id || relation.to_task === task._id
        })

        item.task = task

        newList.push(item)
      }
    } else {

      const subItems = getWeeklyResult({
        ...options,
        list: item.group.items,
        parentGroupId: position
      })

      if (subItems.length) {

        item.group.position = position

        item.group.items = subItems

        newList.push(item)
      }
    }
  })

  return newList
}

export const getRestrictionsResult = (options: any) => {

  const newList: any[] = []

  options.list.forEach((item: any, i: number) => {

    const position = `${options.parentGroupId ? options.parentGroupId + '.' : ''}${i + 1}`

    if (item.type === 'task') {

      const task = options.tasks.find((task: any) => task._id === item.task)

      if (!task.restrictions.length) return

      task.position = position
      task.parent_position = options.parentGroupId
      task.parent_name = options.parentName
      task.parent_id = options.parentId

      task.startsAt = moment.utc(task.startsAt).format('YYYY-MM-DD HH:mm')
      task.endsAt = moment.utc(task.endsAt).format('YYYY-MM-DD HH:mm')

      if (task.colorCode)
        task.colorCode = options.colorCodes.find((cc: any) => cc._id === task.colorCode)

      task.comments = task.comments.map((comment: any) => {

        comment.user = options.users.find((u: any) => u._id === comment.user)

        return comment
      })

      task.responsibles = task.responsibles.map((resp: string) => {

        return options.users.find((user: any) => user._id === resp)
      })

      task.restrictions = task.restrictions.map((rest: any) => {

        return {
          ...rest,
          createdBy: options.users.find((user: any) => user._id === rest.createdBy)
        }
      })

      task.relations = options.relations.filter((relation: any) => {

        return relation.from_task === task._id || relation.to_task === task._id
      })

      item.task = task

      newList.push(item)
    } else {

      const subItems = getRestrictionsResult({
        ...options,
        list: item.group.items,
        parentGroupId: position,
        parentName: item.group.name,
        parentId: item.group._id
      })

      if (subItems.length) {

        item.group.position = position

        item.group.items = subItems

        newList.push(item)
      }
    }
  })

  return newList
}

export const getCollapsedGroups = (project_id: string, page: ProgrammationSchema) => {

  return JSON.parse(localStorage.getItem(`${page}-${project_id}-collapsedItems`) || '[]')
}

export const saveCollapsedGroups = (project_id: string, page: ProgrammationSchema, collapsedGroups: string[]) => {

  localStorage.setItem(`${page}-${project_id}-collapsedItems`, JSON.stringify(collapsedGroups))
}

export const getSelectableGroups = (list: any[] = [], exclude_group_id: string, level = 0) => {

  const groups: any[] = []

  list?.map((item: any) => {

    if (item.type === 'group') {

      if (item.group._id === exclude_group_id) return null

      groups.push({
        _id: item.group._id,
        name: `${'-- '.repeat(level)} ${item.group.name}`,
      })

      const subGroups = getSelectableGroups(item.group.items, exclude_group_id, level + 1)

      groups.push(...subGroups)
    }
  })

  return groups
}

export const getNonWorkingDays = (fromString: string, toString: string, project: any) => {

  const workableDays = [
    project.settings.workMonday,
    project.settings.workTuesday,
    project.settings.workWednesday,
    project.settings.workThursday,
    project.settings.workFriday,
    project.settings.workSaturday,
    project.settings.workSunday,
  ]

  const from = fromString.length !== 10 ? moment.utc(fromString) : moment(fromString)
  const to = toString.length !== 10 ? moment.utc(toString) : moment(toString)

  const nonWorkingDays: any = [ ...project.nonWorkingDays ]

  const day = from.clone()

  while (day.isSameOrBefore(to)) {

    const isNonWorkingWeekDay = !workableDays[ day.day() - 1 ]

    if (isNonWorkingWeekDay) {

      nonWorkingDays.push(day.format('YYYY-MM-DD'))
    }

    day.add(1, 'day')
  }

  return nonWorkingDays.sort()
}

export const getGroupStartDay = (items: any, schema: ProgrammationSchema, nonWorkingDays: string[], stretchUpToday: boolean) => {

  let startDay: Moment | undefined

  items.forEach((item: any) => {

    if (item.type === 'task') {

      const taskItem = getTaskitem({ task: item.task, schema, nonWorkingDays, stretchUpToday, i: 0 })

      const taskStart = moment(taskItem.barStart, 'YYYY-MM-DD')

      if (!startDay || taskStart.isBefore(startDay)) {

        startDay = taskStart.clone()
      }
    } else {

      const groupStartDay = getGroupStartDay(item.group.items, schema, nonWorkingDays, stretchUpToday)

      if (groupStartDay) {

        if (!startDay || groupStartDay.isBefore(startDay)) {

          startDay = groupStartDay.clone()
        }
      }
    }
  })

  return startDay
}

export const getGroupEndDay = (items: any, schema: ProgrammationSchema, nonWorkingDays: string[], stretchUpToday: boolean, week?: number) => {

  let endDay: Moment | undefined

  items.forEach((item: any) => {

    if (item.type === 'task') {

      const taskItem = getTaskitem({
        task: item.task,
        schema,
        nonWorkingDays,
        stretchUpToday,
        week,
        i: 0
      })

      let end

      if (taskItem.type === 'hito' || taskItem.type === 'reunion') {

        end = taskItem.startsAt
      } else {

        end = taskItem.barEnd
      }

      const groupEnd = moment(end, 'YYYY-MM-DD')

      if (!endDay || groupEnd.isAfter(endDay)) {

        endDay = groupEnd.clone()
      }
    } else {

      const groupEndDay = getGroupEndDay(item.group.items, schema, nonWorkingDays, stretchUpToday, week)

      if (groupEndDay) {

        if (!endDay || groupEndDay.isAfter(endDay)) {

          endDay = groupEndDay.clone()
        }
      }
    }
  })

  return endDay
}

export const getExpectedEndDate = (startedAt: string, executeDays: number, nonWorkingDays: string[], stretchUpToday: boolean) => {

  const day = moment(startedAt)

  let days = 0

  while (days < (executeDays - 1)) {

    day.add(1, 'day')

    if (!nonWorkingDays.includes(day.format("YYYY-MM-DD"))) {

      days += 1
    }
  }

  if (stretchUpToday) {

    const today = moment()

    if (day.isBefore(today, 'day')) {

      return today.format('YYYY-MM-DD')
    }
  }

  return day.format('YYYY-MM-DD')
}

interface IPeriodValuesOptions {
  periodStart: string
  periodEnd: string
  nonWorkingDays: string[]
  stretchUpToday: boolean,
  task: any,
  barStart: string,
  barEnd: string,
}

const getItemPeriodValues = (options: IPeriodValuesOptions) => {

  const {
    task,
    periodStart,
    periodEnd,
    barStart,
    barEnd,
    nonWorkingDays,
  } = options

  let barName = task.name
  let progressString = `${task.progress}% / 100%`
  let progress = task.progress

  const taskStartsAfterPeriodStart = moment(barStart).isSameOrAfter(moment(periodStart))

  const taskStartsBeforePeriodStart = moment(barStart).isBefore(moment(periodStart))
  const taskEndsAfterPeriodsEnd = moment(barEnd).isAfter(moment(periodEnd))

  const taskEndsBeforePeriodsEnd = moment(barEnd).isSameOrBefore(moment(periodEnd))

  const week = Number(moment(periodStart).format('YYYYWW'))

  if (taskStartsBeforePeriodStart && taskEndsBeforePeriodsEnd) {

    if (task.progress > 0) {

      if (task.lastWeeklyProgress) {

        if (week - task.lastWeeklyProgress.longWeek === 1) {

          const periodProgressExpected = task.periodProgress - task.lastWeeklyProgress.taskProgress
          const periodProgressDone = task.progress - task.lastWeeklyProgress.taskProgress
          const periodProgressPercent = periodProgressDone * 100 / periodProgressExpected

          progressString = `${periodProgressPercent}% / ${periodProgressExpected}%`
          barName = `${task.name} (${progressString})`
          progress = periodProgressPercent
        } else {

          const percentLeft = 100 - task.lastWeeklyProgress.taskProgress

          const startDay = moment(task.lastWeeklyProgress.longWeek + 1, 'YYYYWW').format('YYYY-MM-DD')

          const daysInPeriod = getItemWidth(periodStart, barEnd, nonWorkingDays, true)
          const daysLeft = getItemWidth(startDay, barEnd, nonWorkingDays, true)

          const periodProgressPercent = daysInPeriod * percentLeft / daysLeft

          progressString = `0% / ${periodProgressPercent.toFixed(0)}%`
          barName = `${task.name} (${progressString})`

          progress = 0
        }
      }
    } else if (task.status === 'atrasada') {

      const executeDays = getItemWidth(barStart, barEnd, nonWorkingDays, true)
      const daysInPeriod = getItemWidth(periodStart, barEnd, nonWorkingDays, true)

      const periodProgressPercent = daysInPeriod * 100 / executeDays

      progressString = `0% / ${periodProgressPercent.toFixed(0)}%`
      barName = `${task.name} (${progressString})`

      progress = 0
    }
  } else if (!taskStartsBeforePeriodStart && taskEndsAfterPeriodsEnd && task.status === 'activa-atrasada') {

    const executeDays = getItemWidth(barStart, barEnd, nonWorkingDays, true)

    const taskWorkableDays = getItemWidth(barStart, periodEnd, nonWorkingDays, true)
    const expectedPercentage = (taskWorkableDays * 100) / executeDays

    const percentageDone = ((task.progress * 100) / expectedPercentage)

    barName += ` (${percentageDone < 100 ? percentageDone.toFixed(0) : 100}% / ${expectedPercentage.toFixed(0)}%)`

    progress = percentageDone
  } else if (!taskStartsBeforePeriodStart && taskEndsAfterPeriodsEnd && task.status !== 'activa-atrasada') {

    const taskWorkableDays = getItemWidth(barStart, periodEnd, nonWorkingDays, true)
    const expectedPercentage = (taskWorkableDays * 100) / task.executeDays

    const percentageDone = ((task.progress * 100) / expectedPercentage)

    barName += ` (${percentageDone < 100 ? percentageDone.toFixed(0) : 100}% / ${expectedPercentage.toFixed(0)}%)`

    progress = percentageDone
  } else if (taskStartsAfterPeriodStart && taskEndsBeforePeriodsEnd) {

    barName += ` (${task.progress}% / 100%)`
  } else if (taskStartsBeforePeriodStart && taskEndsAfterPeriodsEnd) {

    const daysLeft = getItemWidth(periodStart, barEnd, nonWorkingDays, true)
    const daysInPeriod = getItemWidth(periodStart, periodEnd, nonWorkingDays, true)

    if (task.progress > 0) {

      if (task.lastWeeklyProgress) {

        if (week - task.lastWeeklyProgress.longWeek === 1) {

          const progressLeft = 100 - task.lastWeeklyProgress.taskProgress
          const periodProgressExpected = daysInPeriod * progressLeft / daysLeft
          const periodProgressDone = task.progress - task.lastWeeklyProgress.taskProgress
          const periodProgressPercent = periodProgressDone * 100 / periodProgressExpected

          barName = `${task.name} (${periodProgressPercent.toFixed(0)}% / ${periodProgressExpected.toFixed(0)}%)`
          progress = periodProgressPercent
        }
      }
    } else {

      const executeDays = getItemWidth(barStart, barEnd, nonWorkingDays, true)

      const periodProgressPercent = daysInPeriod * 100 / executeDays

      barName = `${task.name} (0% / ${periodProgressPercent.toFixed(0)}%)`

      progress = 0
    }
  }

  return {
    barName,
    progress,
    progressString,
  }
}

interface IGetTaskItemOptions {
  task: any
  schema: ProgrammationSchema
  nonWorkingDays: string[]
  stretchUpToday: boolean,
  week?: number,
  ganttStart?: string,
  ganttEnd?: string,
  level?: number
  i: number
  j?: number
  k?: number
}

const getTaskitem = (options: IGetTaskItemOptions) => {

  const {
    task,
    schema,
    nonWorkingDays,
    stretchUpToday,
    ganttStart,
    ganttEnd,
    level,
  } = options

  const { colorCode, comments } = task

  const startsAt = moment(task.startsAt).format("YYYY-MM-DD")
  const startedAt = task.startedAt ? moment(task.startedAt).format("YYYY-MM-DD") : undefined

  const barStart = startedAt || startsAt

  const taskItem: any = {
    _id: task._id,
    schema: 'task',
    level,
    type: task.type,
    position: task.position,
    name: task.name,
    barName: `${colorCode ? `${colorCode.name} - ` : ''}${task.name} (${task.progress}%/100%)`, // macro
    startsAt,
    executeDays: task.executeDays,
    barStart,
    relations: task.relations,
  }

  if (taskItem.type === 'hito' || taskItem.type === 'reunion') {

    return taskItem
  }

  const endsAt = moment(task.endsAt).format("YYYY-MM-DD")
  const endedAt = task.endedAt ? moment(task.endedAt).format("YYYY-MM-DD") : undefined

  const barEnd = startedAt && !endedAt ?
                  getExpectedEndDate(startedAt, task.executeDays, nonWorkingDays, stretchUpToday) :
                  endedAt || endsAt

  taskItem.colorCodeName = colorCode?.name
  taskItem.colorCodeColor = colorCode?.color
  taskItem.status = task.status
  taskItem.endsAt = endsAt
  taskItem.responsibles = task.responsibles
  taskItem.startedAt = startedAt
  taskItem.endedAt = endedAt
  taskItem.progress = task.progress
  taskItem.confirmed = task.confirmed
  taskItem.barEnd = barEnd
  taskItem.restrictions = task.restrictions?.reduce((acc: any, restriction: any) => {

    if (restriction.checked) {

      acc.done += 1
    }
    acc.toDo += 1

    return acc
  }, { done: 0, toDo: 0, all: task.restrictions })

  if (schema === 'lookahead') {

    taskItem.periodDays = 0
    taskItem.executePercent = 0
    taskItem.unit = task.unit
    taskItem.metered = task.metered
    taskItem.performance = task.performance
    taskItem.crewNumber = task.crewNumber
    taskItem.estimatedDays = task.estimatedDays
  }

  if (schema === 'lookahead' || schema === 'weekly') {

    taskItem.releasesAt = task.releasesAt
    taskItem.comments = comments[ comments.length - 1 ]

    const { barName, progress, progressString } = getItemPeriodValues({
      periodStart: ganttStart || '',
      periodEnd: ganttEnd || '',
      task,
      nonWorkingDays,
      stretchUpToday,
      barStart,
      barEnd,
    })

    taskItem.barName = `${colorCode ? `${colorCode.name} - ` : ''}${barName}`
    taskItem.progressString = progressString
    taskItem.progress = progress
  }

  return taskItem
}

const hasThisUserTasks = (items: any[], user_id: string) => {

  let hasTasks = false

  items.forEach((item: any) => {

    if (item.type === 'task') {

      if (item.task.responsibles.find((responsible: any) => responsible._id === user_id)) {

        hasTasks = true
      }
    } else {

      if (hasThisUserTasks(item.group.items, user_id)) {

        hasTasks = true
      }
    }
  })

  return hasTasks
}

interface ICollapsedGroupBarsOptions {
  groupItems: any[],
  schema: ProgrammationSchema,
  nonWorkingDays: string[]
  stretchUpToday: boolean
  showOnlyThisUserTasks?: string,
  week?: number,
  ganttStart?: string,
  ganttEnd?: string,
  level?: number,
  i: number
  j?: number
  k?: number
}

const getCollapsedGroupBars = (options: ICollapsedGroupBarsOptions) => {

  const {
    groupItems,
    schema,
    nonWorkingDays,
    stretchUpToday,
    week,
    ganttStart,
    ganttEnd,
    level,
    j = -1,
    k = -1,
  } = options

  const bars: any[] = []

  groupItems.map((item: any, i: number) => {

    if (item.type === 'task') {

      const { task } = item

      const taskItem = getTaskitem({ task, schema, nonWorkingDays, stretchUpToday, week, ganttStart, ganttEnd, i, j })

      bars.push({
        _id: taskItem._id,
        name: taskItem.name,
        position: taskItem.position,
        progress: taskItem.progress,
        progressString: taskItem.progressString,
        restrictions: taskItem.restrictions,
        responsibles: taskItem.responsibles,
        barStart: taskItem.barStart,
        barEnd: taskItem.barEnd,
        status: taskItem.status,
        colorCodeName: taskItem.colorCodeName,
        colorCodeColor: taskItem.colorCodeColor,
      })
    } else {

      const subBars = getCollapsedGroupBars({
        groupItems: item.group.items,
        schema,
        nonWorkingDays,
        stretchUpToday,
        week,
        ganttStart,
        ganttEnd,
        level,
        i,
        j,
        k,
      })

      bars.push(...subBars)
    }
  })

  return bars
}

interface IGetGroupItemOptions {
  group: any
  collapsedGroups: any[]
  schema: ProgrammationSchema
  nonWorkingDays: string[]
  stretchUpToday: boolean
  showOnlyThisUserTasks?: string,
  week?: number,
  ganttStart?: string,
  ganttEnd?: string,
  level?: number,
  i: number
  j?: number
  k?: number
}

const getGroupItem = (options: IGetGroupItemOptions) => {

  const {
    group,
    collapsedGroups,
    schema,
    nonWorkingDays,
    stretchUpToday,
    showOnlyThisUserTasks,
    week,
    ganttStart,
    ganttEnd,
    level,
    j = -1,
    k = -1,
  } = options

  const isCollapsed = collapsedGroups.includes(group._id)

  const bars: any = []

  group.items.forEach((item: any, i: number) => {

    if (item.type === 'task') {

      const { task } = item

      const taskItem = getTaskitem({ task, schema, nonWorkingDays, stretchUpToday, week, ganttStart, ganttEnd, i })

      bars.push({
        _id: taskItem._id,
        schema: 'task',
        name: taskItem.name,
        position: taskItem.position,
        progressString: taskItem.progressString,
        restrictions: taskItem.restrictions,
        barStart: taskItem.barStart,
        barEnd: taskItem.barEnd,
        status: taskItem.status,
        colorCodeName: taskItem.colorCodeName,
        colorCodeColor: taskItem.colorCodeColor,
      })
    } else {

      const subBars = getCollapsedGroupBars({
        groupItems: item.group.items,
        schema,
        nonWorkingDays,
        stretchUpToday,
        week,
        ganttStart,
        ganttEnd,
        level,
        i,
        j,
        k,
      })

      bars.push(...subBars)
    }
  })

  const barStart = getGroupStartDay(group.items, schema, nonWorkingDays, stretchUpToday)?.format("YYYY-MM-DD")
  const barEnd = getGroupEndDay(group.items, schema, nonWorkingDays, stretchUpToday, week)?.format("YYYY-MM-DD")

  const groupItem: any = {
    schema: 'group',
    _id: group._id,
    level,
    position: group.position,
    name: group.name,
    startsAt: barStart,
    endsAt: barEnd,
    barStart,
    barEnd,

    isCollapsed,
    bars,
    hasChildGroup: group.items.some((item: any) => item.type === 'group'),
  }

  if (showOnlyThisUserTasks) {

    groupItem.hasThisUserTasks = hasThisUserTasks(group.items, showOnlyThisUserTasks)
  }

  return groupItem
}

export interface IGetItemsListOptions {
  itemsList: any[],
  collapsedGroups: any[],
  schema: ProgrammationSchema,
  nonWorkingDays: string[],
  stretchUpToday: boolean,
  showOnlyThisUserTasks?: string,
  week?: number,
  ganttStart?: string,
  ganttEnd?: string,
  parentId?: string,
  parentPosition?: string,
  level?: number,
}

const getItemPosition = (item: any, schema: ProgrammationSchema, parentGroupId: string, index: number) => {

  // if (schema === 'macro') {

  //   return `${parentGroupId ? parentGroupId + '.' : ''}${index}`
  // }

  if (item.type === 'task') {

    return item.task.position
  }

  return item.group.position
}

export const getGanttItems = (options: IGetItemsListOptions) => {

  const {
    itemsList,
    collapsedGroups,
    schema,
    nonWorkingDays,
    stretchUpToday,
    showOnlyThisUserTasks,
    week,
    ganttStart,
    ganttEnd,
    parentId,
    parentPosition,
  } = options

  const level = options.level || 0

  const ganttItems: any = []

  itemsList.map((item: any, i: number) => {

    const position = getItemPosition(item, schema, parentPosition || '', i + 1)

    if (item.type === 'task') {

      const { task } = item

      const taskItem = getTaskitem({ task, schema, nonWorkingDays, stretchUpToday, week, ganttStart, ganttEnd, level, i })

      taskItem.position = position
      taskItem.parentId = parentId
      taskItem.parentPosition = parentPosition

      ganttItems.push(taskItem)
    } else {

      const { group } = item

      const groupItem = getGroupItem({ group, collapsedGroups, schema, nonWorkingDays, stretchUpToday, showOnlyThisUserTasks, week, ganttStart, ganttEnd, level, i })

      groupItem.position = position
      groupItem.parentId = parentId
      groupItem.parentPosition = parentPosition

      ganttItems.push(groupItem)

      if (collapsedGroups.includes(item.group._id)) return

      const subItems = getGanttItems({
        ...options,
        itemsList: item.group.items,
        parentId: group._id,
        parentPosition: position,
        level: level + 1,
      })

      ganttItems.push(...subItems)
    }
  })

  if (showOnlyThisUserTasks) {

    return ganttItems.filter((item: any) => {
      if (item.schema === 'task' && item._id !== showOnlyThisUserTasks) {
        const hasMatchingResponsible = item.responsibles && item.responsibles.some((responsible: any) => responsible._id === showOnlyThisUserTasks);

        return hasMatchingResponsible;
      }

      if (item.schema === 'group' && !item.hasThisUserTasks) return false;
      return true;
    });
  }

  return ganttItems
}

export const getRestrictionsTableItems = (options: any) => {

  const items: any[] = []

  options.itemsList.forEach((item: any) => {

    if (item.type === 'task') {

      const taskName = item.task.name;
      const restrictions = item.task.restrictions;
      const itemId = item.task._id;
      const taskId = item.task.position
      const taskReleasesAt = item.task.releasesAt || item.task.startsAt

      restrictions.forEach((restriction: any) => {

          const from = moment(restriction.createdAt).format('YYYY-MM-DD')
          const to = (restriction.checked ? moment(restriction.updatedAt) : moment()).format('YYYY-MM-DD')

          const creator = restriction.createdBy ? restriction.createdBy.name + ' ' + restriction.createdBy.surname : ''
          const days = moment(to).diff(from, 'd') + 1

          const responsibles = item.task.responsibles

          const createdAt = restriction.createdAt || item.task.createdAt

          items.push({
            ...restriction,
            itemId,
            taskId,
            taskName,
            taskReleasesAt,
            parentPosition: item.task.parent_position,
            parentId: item.task.parent_id,
            parentName: item.task.parent_name,
            creator,
            days,
            responsibles,
            createdAt
          });
      });
    } else {

      const subItems = getRestrictionsTableItems({
        ...options,
        itemsList: item.group.items
      })

      if (subItems.length) {

        items.push(...subItems)
      }
    }
  })

  return items
}

export const getItemParentId = (list: any, item_id: string, parent_id: string = '') => {

  let p_id = ''

  list.forEach((item: any) => {

    if (item.type === 'task') {

      if (item.task._id === item_id)
        p_id = parent_id
    } else {

      if (item.group._id === item_id) {

        p_id = parent_id
      } else {

        const found = getItemParentId(item.group.items, item_id, p_id)

        if (found)
          p_id = found
      }
    }
  })

  return p_id
}

export const getItemFromTaskList = (taskList: any[], itemId: string, group?: any) => {

  let item: any
  let parent: any

  taskList.map((itemLevel: any) => {

    if (itemLevel.type === 'task') {

      if (itemLevel.task._id === itemId) {

        item = itemLevel.task
        parent = group
      }
    } else {

      if (itemLevel.group._id === itemId) {

        item = itemLevel.group
        parent = group
      } else {

        const recu = getItemFromTaskList(itemLevel.group.items, itemId, itemLevel.group)

        if (recu.item) {
          item = recu.item
          parent = recu.parent
        }
      }
    }
  })

  return { item, parent }
}

export const getTasksResult = ({ list, tasks, users, colorCodes, relations, filters, parentGroupId }: any) => {

  list.forEach((item: any, i: number) => {

    const position = `${parentGroupId ? parentGroupId + '.' : ''}${i + 1}`

    if (item.type === 'task') {

      const task = tasks.find((t: any) => t._id === item.task)

      if (!task) {

        console.log('Task not found', position, item.task)
        return
      }

      task.position = position

      if (task.colorCode)
        task.colorCode = colorCodes.find((cc: any) => cc._id === task.colorCode)

      task.comments = task.comments.map((comment: any) => {

        comment.user = users.find((u: any) => u._id === comment.user)

        return comment
      })

      task.responsibles = task.responsibles.map((resp: string) => {

        return users.find((user: any) => user._id === resp)
      })

      task.restrictions = task.restrictions.map((rest: any) => {

        return {
          ...rest,
          createdBy: users.find((user: any) => user._id === rest.createdBy)
        }
      })

      task.relations = relations.filter((relation: any) => {

        return relation.from_task === task._id || relation.to_task === task._id
      })

      item.task = task
    } else {

      item.group.position = position

      item.group.items = getTasksResult({
        list: item.group.items,
        tasks,
        users,
        colorCodes,
        relations,
        filters,
        parentGroupId: position
      })
    }
  })

  return list
}

export const getTasksItems = (items: any[], nonWorkingDays: string[], stretchUpToday: boolean, user_id?: string) => {

  const tasks: any[] = []

  items?.forEach((item: any, i: number) => {

    if (item.type === 'task') {

      const { task } = item

      const taskItem = getTaskitem({ task, schema: 'macro', nonWorkingDays, stretchUpToday, i })

      if (taskItem.responsibles) {
        const isUserResponsibility = taskItem.responsibles.find((responsible: any) => responsible._id === user_id)

        if (!user_id || (user_id && isUserResponsibility)) {
          tasks.push(taskItem)
        }
      }
    } else {

      const subTasks = getTasksItems(item.group.items, nonWorkingDays, stretchUpToday, user_id)

      tasks.push(...subTasks)
    }
  })

  return tasks
}

export const getGroupFromList = (list: any[], group_id: string, level: number) => {

  let group: any
  let lvl = level

  list.forEach((item: any) => {

    if (item.type === 'group') {

      if (item.group._id === group_id) {

        group = item.group
      } else {

        const res = getGroupFromList(item.group.items, group_id, level + 1)

        if (res.group) {

          group = res.group
          lvl = res.level
        }
      }
    }
  })

  return { group, level: lvl }
}

interface IGetCollapsedGroupItemsOptions {
  group_id: string
  taskList: any[]
  schema: ProgrammationSchema
  nonWorkingDays: string[]
  stretchUpToday: boolean
  parentPosition?: string
  level?: number
}

export const getCollapsedGroupItems = (options: IGetCollapsedGroupItemsOptions) => {

  const {
    group_id,
    taskList,
    schema,
    nonWorkingDays,
    stretchUpToday,
    parentPosition,
  } = options

  const lvl = options.level || 0

  const ganttItems: any = []

  const { group, level } = getGroupFromList(taskList, group_id, lvl)

  group?.items.forEach((item: any, i: number) => {

    const position = getItemPosition(item, schema, parentPosition || '', i + 1)

    if (item.type === 'task') {

      const { task } = item

      const taskItem = getTaskitem({ task, schema, nonWorkingDays, stretchUpToday, i })

      taskItem.position = position
      taskItem.parentId = group_id
      taskItem.parentPosition = parentPosition

      ganttItems.push(taskItem)
    } else {

        const { group } = item

        const groupItem = getGroupItem({ group, collapsedGroups: [], schema, nonWorkingDays, stretchUpToday, level, i })

        groupItem.position = position
        groupItem.parentId = group_id
        groupItem.parentPosition = parentPosition

        ganttItems.push(groupItem)

        if (group.items.length > 0) {


          const subItems = getCollapsedGroupItems({
            group_id: groupItem._id,
            taskList,
            schema,
            nonWorkingDays,
            stretchUpToday,
            parentPosition: position,
            level: level + 1,
          })

          ganttItems.push(...subItems)
        }
    }
  })

  return ganttItems
}

export const getGroupTasks = (taskList: any[]) => {

  const tasks: any[] = []

  taskList.forEach((item: any) => {

    if (item.type === 'task') {

      const { task } = item

      const taskItem = getTaskitem({ task, schema: 'macro', nonWorkingDays: [], stretchUpToday: false, i: 0 })

      tasks.push(taskItem)
    } else {

      const subTasks = getGroupTasks(item.group.items)

      tasks.push(...subTasks)
    }
  })

  return tasks
}
