import React, { createContext, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadTranslations } from "../Redux/Actions/TranslationsActions";

interface TranslationsContextProps {
  language: string;
  t: (key: string) => string;
  changeLanguage: (newLanguage: string) => void;
}

const TranslationsContext = createContext<TranslationsContextProps | undefined>(undefined);

export const TranslationsProvider = ({ children }: { children: React.ReactNode }) => {

  const dispatch = useDispatch()

  const language = useSelector((state: any) => state.TranslationsReducer.language);
  const translations = useSelector((state: any) => state.TranslationsReducer.translations);

  const t = (key: string) => translations[language]?.[key] || key;

  const detectLanguage = (): 'en' | 'es' => {
    const browserLang = navigator.language.split('-')[0]; // Get the primary language code

    // If the system language is Spanish or English, return it directly
    if (browserLang === 'es' || browserLang === 'en') {
      return browserLang;
    }

    // List of Hispanic American time zones
    const hispanicAmericaTimeZones = [
      'America/Argentina/Buenos_Aires',
      'America/Bogota',
      'America/Caracas',
      'America/Lima',
      'America/Mexico_City',
      'America/Montevideo',
      'America/Panama',
      'America/Santiago',
      'America/Guatemala',
      'America/El_Salvador',
      'America/Managua',
      'America/Tegucigalpa',
      'America/Santo_Domingo',
      'America/Havana',
      'America/Asuncion',
      // Add more Hispanic American time zones if needed
    ];

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // If the system language is not Spanish or English, check the time zone
    if (hispanicAmericaTimeZones.includes(timeZone)) {
      return 'es';
    }

    // Default to English for all other cases
    return 'en';
  };

  const changeLanguage = (newLanguage: string) => {

    const loadLang = async (lang: string) => {

      const langData = await import(`../Translations/${lang}.json`) as any;

      dispatch(loadTranslations(lang, langData.default));

      localStorage.setItem('language', lang);
    };

    loadLang(newLanguage || detectLanguage());
  };

  useEffect(() => {
    const loadLang = async (lang: string) => {

      const langData = await import(`../Translations/${lang}.json`) as any;

      dispatch(loadTranslations(lang, langData.default));

      localStorage.setItem('language', lang);
    };

    loadLang(language);
  }, [language, dispatch]);

  return (
    <TranslationsContext.Provider value={{ language, t, changeLanguage }}>
      {children}
    </TranslationsContext.Provider>
  );
};

const useTranslation = () => {

  const context = useContext(TranslationsContext);

  if (context === undefined) {
    throw new Error("useTranslation must be used within a TranslationsProvider");
  }

  return context;

  // const dispatch = useDispatch();

  // const language = useSelector((state: any) => state.TranslationsReducer.language);
  // const translations = useSelector((state: any) => state.TranslationsReducer.translations);

  // // Function to get a translation by key
  // const t = (key: string) => translations[language]?.[key] || key;

  // // Function to switch language
  // const changeLanguage = (newLanguage: string) => {
  //   dispatch(setLanguage(newLanguage));
  // };

  // // Load translations dynamically when language changes
  // useEffect(() => {
  //   const loadLang = async (lang: string) => {

  //     console.log('loading lang', lang)

  //     const langData = await import(`../Translations/${lang}.json`) as any;
  //     console.log('langData', langData)
  //     dispatch(loadTranslations({ [lang]: langData.default }));
  //   };
  //   loadLang(language);
  // }, [language, dispatch]);

  // return { t, changeLanguage, language };
};

export default useTranslation;
