
import React, { useEffect, useMemo, useState } from 'react'

import { usePlan } from '../../Hooks/UsePlan'
import useTranslation from '../../Hooks/UseTranslation'

import OptionsDialog, { Option } from '../../Components/OptionsDialog/OptionsDialog'

type Profile = {
  name: string
  tag: string
  selected?: boolean
  disabled?: boolean
}

type Props = {
    profile: Profile,
    isAssistant?: boolean,

    onSelect?: any,
}

const ProfileSelector = (props: Props) => {

  const { t } = useTranslation()

  const { plan } = usePlan()

    const [ show, setShow ] = useState(false)

    const [ profileName, setProfileName ] = useState<string>('')

    const handleSelect = (profileTag: string) => {

        props.onSelect(profileTag)

        setShow(false)
    }

    const options: Option[] = useMemo(() => {

      const profileTag: string = props.profile.tag
      const isAssistant: boolean = props.isAssistant || false

      const isObserver = profileTag === 'observer'
      const isCollaborator = profileTag === 'collaborator'

      const opts: Option[] = [
        {
          icon: <i className="fas fa-fw fa-eye"></i>,
          label: t('PROFILE_SELECTOR:ObserverProfile'),
          onClick: () => handleSelect('observer'),
          active: isObserver && !isAssistant,
          disabled: isCollaborator || (isObserver && !isAssistant) || plan.cannot('add', 'Observer')
        },
        {
          icon: <i className="fas fa-fw fa-tools"></i>,
          label: t('PROFILE_SELECTOR:CollaboratorProfile'),
          onClick: () => handleSelect('collaborator'),
          active: isCollaborator && !isAssistant,
          disabled: isObserver || (isCollaborator && !isAssistant) || (!isAssistant && plan.cannot('add', 'Collaborator'))
        },
        {
          icon: <i className="fas fa-fw fa-hard-hat"></i>,
          label: t('PROFILE_SELECTOR:AssistantProfile'),
          onClick: () => handleSelect('assistant'),
          active: isAssistant,
          disabled: isObserver && plan.cannot('add', 'Assistant') || isAssistant
        }
      ]

      return opts
    }, [ props.isAssistant, props.profile.tag, plan.can('add', 'Observer'), plan.can('add', 'Collaborator'), plan.can('add', 'Assistant') ])

    useEffect(() => {

        const selected = options.find((option: Option) => option.active)?.label
        setProfileName(selected || t('PROFILE_SELECTOR:Title'))
    }, [ props.isAssistant ])

    return (
      <>
        <button className='btn btn-secondary'
          onClick={() => setShow(true)}
        >
          <span className="icon-editar"></span> {profileName}
        </button>

        <OptionsDialog
          show={show}
          title={t('PROFILE_SELECTOR:Title')}
          options={options}
          onHide={() => setShow(false)}
        />
      </>
    )
}

export default ProfileSelector
