
import moment from "moment";
import React from "react";

import useTranslation from "../../Hooks/UseTranslation";

const ChangeDate = ({ name, from, to }: any) => {

  const { t } = useTranslation()

  const dateName = () => {

    switch (name) {
      case 'startsAt': return 'inicio'
      case 'endsAt': return 'fin'
      case 'startedAt': return 'inicio real'
      case 'endedAt': return 'fin real'
      case 'releasesAt': return 'liberación'
    }
  }

  if (!from && !to) return null

  return (
    <>
      {!from && (
        <>
          {t('CHANGE_DATE:TheDateFrom')} {' '}
          {dateName()} {' '}
          {t('CHANGE_DATE:HasSettedTo')} {' '}
          <span className="change added">{moment(to).format("DD/MM/YYYY")}</span>
        </>
      )}
      {from && (
        <>
          {t('CHANGE_DATE:TheDateFrom')} {' '}
          {dateName()} {' '}
          {t('CHANGE_DATE:HasChangedFrom')} {' '}
          <span className="change removed">{moment(from).format('DD/MM/YYYY')}</span> {' '}
          {t('CHANGE_DATE:To')} {' '}
          <span className="change added">{moment(to).format("DD/MM/YYYY")}</span>
        </>
      )}
    </>
  )
}

export default ChangeDate
