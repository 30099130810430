
import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { RegisterData } from '../../Types/Interfaces'

import history from '../../Helpers/History'

import SessionActions from '../../Redux/Actions/SessionActions'

import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'

import ToastMsg from '../../Components/ToastMsg/ToastMsg'

import 'react-toastify/dist/ReactToastify.css'
import useTranslation from '../../Hooks/UseTranslation'

const mapStateToProps = (state: any) => {

    return {
        registerStatus: state.SessionReducer.registerStatus,
    }
}

const mapDispatchToProps = (dispatch: any) => {

    return {
        doRegister(registerData: RegisterData) {

            dispatch(SessionActions.registerAction(registerData))
        },
        doReset() {
            dispatch(SessionActions.registerResetAction())
        }
    }
}

const RegisterPage = (props: any) => {

    const { t } = useTranslation()

    const [ name, setName ] = useState('')
    const [ surname, setSurname ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ password2, setPassword2 ] = useState('')

    const [ nameError, setNameError ] = useState('')
    const [ surnameError, setSurnameError ] = useState('')
    const [ emailError, setEmailError ] = useState('')
    const [ usernameError, setUsernameError ] = useState('')
    const [ passwordError, setPasswordError ] = useState('')
    const [ password2Error, setPassword2Error ] = useState('')
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [privacyAccepted, setPrivacyAccepted] = useState(false);

    let showSwal: boolean = false

    const handleChange = (name: string, value: string) => {

        if (name === 'name') {

            setName(value)

            if (value === '') {

                setNameError(t('REGISTER:Required'))
            } else {

                setNameError('')
            }
        } else if (name === 'surname') {

            setSurname(value)

            if (value === '') {

                setSurnameError(t('REGISTER:Required'))
            } else {

                setSurnameError('')
            }
        } else if (name === 'email') {

            setEmail(value)

            if (value === '') {

                setEmailError(t('REGISTER:Required'))
            } else {

                const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

                if (!regex.test(String(value).toLowerCase())) {

                    setEmailError(t('REGISTER:EmailIncorrect'))
                } else {

                    setEmailError('')
                }
            }
        } else if (name === 'username') {

            setUsername(value)

            if (value === '') {

                setUsernameError(t('REGISTER:EnterUsername'))
            } else {

                setUsernameError('')
            }
        } else if (name ==='password') {

            setPassword(value)

            if (value === '') {

                setPasswordError(t('REGISTER:EnterPassword'))
            } else {

                setPasswordError('')
            }
        } else if (name === 'password2') {
            setPassword2(value);

            if (value === '') {
                setPassword2Error(t('REGISTER:EnterPassword'));
            } else if (value !== password) { // Aquí es donde se compara password2 con password
                setPassword2Error(t('REGISTER:PasswordsDoNotMatch'));
            } else {
                setPassword2Error('');
            }
        }
    }

    const handleSubmit = () => {

        let isError: boolean = false

        if (username === '') {

            setUsernameError(t('REGISTER:EnterUsername'))
            isError = true
        }

        if (password === '') {

            setPasswordError(t('REGISTER:EnterPassword'))
            isError = true
        }

        if (isError) {

            return
        }

        const registerData: RegisterData = {
            name,
            surname,
            email,
            username,
            password
        }

        props.doRegister(registerData)
    }

    const evalRegisterStatus = () => {

        let toastTitle: string = ''
        let toastContent: string = ''
        let showToast: boolean = false

        if (props.registerStatus === 'register_ended') {

            if (!showSwal) {
                showSwal = true

                Swal.fire({
                    title: t('REGISTER:UserCreatedSuccessfullyTitle'),
                    text: t('REGISTER:UserCreatedSuccessfullyContent'),
                    icon: 'success',
                    confirmButtonText: t('REGISTER:UserCreatedSuccessfullyCloseButton'),
                    width: '500px',
                    onClose: () => {
                        props.doReset()
                        history.push('/login')
                    },
                })
                    return
            }
        } else if (props.registerStatus === 'user_already_exists') {

            toastTitle = t('REGISTER:UserAlreadyExistsTitle')
            toastContent = t('REGISTER:UserAlreadyExistsContent')
            showToast = true
        } else if (props.registerStatus === 'email_already_exists') {

            toastTitle = t('REGISTER:EmailAlreadyExistsTitle')
            toastContent = t('REGISTER:EmailAlreadyExistsContent')
            showToast = true
        } else if (props.registerStatus === 'Network Error') {

            toastTitle = t('REGISTER:NetworkErrorTitle')
            toastContent = t('REGISTER:NetworkErrorContent')
            showToast = true
        }

        if (!showToast) {

            return
        }

        toast.dark(<ToastMsg title={toastTitle} content={toastContent} />, {
            toastId: 'toast_msg',
            progress: undefined,
            onOpen: () => props.doReset()
        })
    }
    evalRegisterStatus()

    return (
        <AuthLayout>
            <div className="card 0-hidden">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="p-4">
                            <div className="logo text-center mb-4">
                                <img src="/images/logo.svg" alt=""/>
                            </div>
                            <div>
                                <h1 className="h1-title mb-1 text-14">
                                    {t("REGISTER:Register-title")}
                                </h1>

                                <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                                    e.preventDefault()

                                    handleSubmit()
                                }}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="txtNames"
                                            placeholder={t("REGISTER:Name")}
                                            value={name}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('name', e.target.value)}
                                            autoFocus
                                        />
                                        <span className="error-message">{nameError}</span>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="txtSurnames"
                                            placeholder={t("REGISTER:LastName")}
                                            value={surname}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('surname', e.target.value)}
                                        />
                                        <span className="error-message">{surnameError}</span>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="txtEmail"
                                            placeholder={t("REGISTER:Email")}
                                            value={email}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('email', e.target.value)}
                                        />
                                        <span className="error-message">
                                            {emailError}
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="txtUsername"
                                            placeholder={t("REGISTER:Username")}
                                            value={username}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('username', e.target.value)}
                                        />
                                        <span className="error-message">{usernameError}</span>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="txtPassword"
                                            placeholder={t("REGISTER:Password")}
                                            value={password}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('password', e.target.value)}
                                        />
                                        <span className="error-message">{passwordError}</span>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="txtPassword2"
                                            placeholder={t("REGISTER:ConfirmPassword")}
                                            value={password2}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('password2', e.target.value)}
                                        />
                                        <span className="error-message">{password2Error}</span>
                                    </div>
                                    <div className="form-group form-checkbox">
                                        <label className="form-check-label">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="chkAcceptTerms"
                                                checked={termsAccepted}
                                                onChange={() => setTermsAccepted(!termsAccepted)}
                                            />
                                            {t("REGISTER:TermsAndConditions-checkLabel-one")} <a href="https://pl4nner.com/privacidad-y-terminos/terminos-y-condiciones/">
                                                {t("REGISTER:TermsAndConditions-checkLabel-two")}
                                            </a> {t("REGISTER:TermsAndConditions-checkLabel-three")}
                                        </label>
                                    </div>

                                    <div className="form-group form-checkbox">
                                        <label className="form-check-label">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="chkAcceptPrivacy"
                                                checked={privacyAccepted}
                                                onChange={() => setPrivacyAccepted(!privacyAccepted)}
                                            />
                                            {t("REGISTER:PrivacyPolicyAndUse-checkLabel-one")} <a href="https://pl4nner.com/privacidad-y-terminos/politicas-de-privacidad/">
                                                {t("REGISTER:PrivacyPolicyAndUse-checkLabel-two")}
                                            </a> {t("REGISTER:PrivacyPolicyAndUse-checkLabel-three")}
                                        </label>
                                    </div>

                                    <button
                                        type="submit"
                                        className="btn btn-block mt-2 btn-handle-register"
                                        disabled={props.registerStatus === 'register_started' || !(termsAccepted && privacyAccepted)}
                                    >
                                        {props.loginStatus !== 'register_started' ?
                                            t('REGISTER:RegisterButtonIdle') :
                                            t('REGISTER:RegisterButtonRegistering')}
                                    </button>
                                </form>
                                <div className="mt-3 text-center">
                                    <Link to="/login" className="btn btn-light btn-block">
                                        <u>{t('REGISTER:LoginButton')}</u>
                                    </Link>
                                </div>
                            </div>
                        </div>


                        <ToastContainer
                            position="bottom-center"
                            autoClose={3000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
