import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import navigation from "../../navigation";
import { TaskWithId } from "../../Types/Tasks";
import { Can } from "../../Casl/Casl";

import { useAuth } from "../../Hooks/UseAuth";
import useTranslation from "../../Hooks/UseTranslation";

import {
  filterByColorCodes,
  filterByDate,
  filterByGroups,
  filterByStatus,
  filterByTypes,
  filterByUsers,
  getItemFromTaskList,
  getNonWorkingDays,
  getTasksResult,
} from "../../Helpers/ProgrammationHelper";
import TeamsHelper from "../../Helpers/TeamsHelper";

import ColorCodeActions from "../../Redux/Actions/ColorCodeActions";
import ProjectsActions from "../../Redux/Actions/ProjectsActions";
import TasksActions, { setTasksAction } from "../../Redux/Actions/TasksActions";
import TeamsActions from "../../Redux/Actions/TeamsActions";
import { loadProgrammationAction } from "../../Redux/Actions/ProgrammationActions";
import InterfaceActions from "../../Redux/Actions/InterfaceActions";

import MainLayout from "../../Components/MainLayout/MainLayout";
import MainLayoutContentBody from "../../Components/MainLayout/MainLayoutContentBody";
import MainLayoutContentHeader from "../../Components/MainLayout/MainLayoutContentHeader";
import TaskFilters from "../../Components/TaskFilters/TaskFilters";
import TaskCreator from "../../Components/TaskManager/TaskCreator";
import TaskEditor from "../../Components/TaskManager/TaskEditor";
import Icon from "../../Components/Icon/Icon";

import TasksGroupByMonth from "./TasksGroupByMonth";
import TaskGroupByState from "./TasksGroupByState";

const ProjectTasksPage = (props: any) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const project_id = props.match.params._id;

  const { ability, userData } = useAuth()

  const projectLoadStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)
  const project = useSelector((state: any) => state.ProjectsReducer.rowData);
  const programmation = useSelector((state: any) => state.ProgrammationReducer)
  const tasksReducer = useSelector((state: any) => state.TasksReducer)
  const teams = useSelector((state: any) => state.TeamsReducer.result);
  const interfaceHeights = useSelector(
    (state: any) => state.InterfaceReducer.heights
  );

  const savedFilters = localStorage.getItem(`${project_id}-taskpage-filters`);

  const [ filters, setFilters ] = useState(
    savedFilters
      ? JSON.parse(savedFilters)
      : {
        user: "",
        state: "",
        team: "",
        group: "",
        code: "",
        type: "",
        start: "",
        end: "",
      }
  );

  const [ tab, setTab ] = useState(
    localStorage.getItem("taskPageTab") ?? "by_status"
  );
  const [ height, setHeight ] = useState(0);

  const [ showTaskCreator, setShowTaskCreator ] = useState(false);
  const [ showTaskEditor, setShowTaskEditor ] = useState(false);
  const [ showGroupEditor, setShowGroupEditor ] = useState(false);
  const [ showTaskFilters, setShowTaskFilters ] = useState(false);
  const [ onlyShowMine, setOnlyShowMine ] = useState(
    localStorage.getItem("onlyShowMyTasks") === "true"
  );

  const [ task, setTask ] = useState<TaskWithId | undefined>();
  const [ group, setGroup ] = useState({}); //TODO: use undefined instead of empty object

  const [ menu, submenu ] = navigation.getNavigation(
    props.match.params._id,
    "tasks",
    "board"
  );

  const nonWorkingDays = useMemo(() => {
    if (!project._id) return [];

    return getNonWorkingDays(project.startsAt, project.endsAt, project);
  }, [ project._id ]);

  const loadProject = () => {
    dispatch(ProjectsActions.getRowAction(project_id));
  };

  const loadTeams = async () => {
    await dispatch(TeamsActions.getResultAction(project_id));
  };

  const loadColorCodes = async () => {
    await dispatch(ColorCodeActions.getResult(project._id));
  };

  const loadProgrammation = async () => {

    await dispatch(loadProgrammationAction(project_id))
  }

  const loadTasks = async (withFilters?: ITaskListFilters) => {

    if (programmation.loadStatus !== 'loaded') return

    const options = {
      ...programmation,
      list: JSON.parse(JSON.stringify(programmation.list)),
      tasks: JSON.parse(JSON.stringify(programmation.tasks)),
      filters
    }

    let tasksResult = getTasksResult(options)

    const newFilters = withFilters || filters

    if (newFilters.team) {

      const teams_ids = newFilters.team.split(',')

      const teamsFound = teams.filter((team: any) => teams_ids.includes(team._id))

      const users_ids: string[] = []

      teamsFound.forEach((team: any) => {

        team.users.forEach((user: any) => {

          if (!users_ids.includes(user._id))
            users_ids.push(user._id)
        })
      })

      tasksResult = filterByUsers(tasksResult, users_ids)
    } else if (newFilters.user || onlyShowMine)

      if (onlyShowMine && userData) {

        tasksResult = filterByUsers(tasksResult, [userData.id])
      } else {

        tasksResult = filterByUsers(tasksResult, newFilters.user.split(','))
      }

    if (newFilters.group) {

      tasksResult = filterByGroups(tasksResult, newFilters.group.split(','))
    }else if (newFilters.code)
      tasksResult = filterByColorCodes(tasksResult, newFilters.code.split(','))

    if (newFilters.type)
      tasksResult = filterByTypes(tasksResult, newFilters.type.split(','))

    if (newFilters.state)
      tasksResult = filterByStatus(tasksResult, newFilters.state.split(','))

    if (newFilters.start || newFilters.end)
      tasksResult = filterByDate(tasksResult, newFilters.start, newFilters.end)

    dispatch(setTasksAction(tasksResult))
  };

  const handleTabChange = (tab: string) => {
    localStorage.setItem("taskPageTab", tab);

    setTab(tab);
  };

  const handleNewTaskButton = async () => {

    if (!tasksReducer.result.length) {
      const prompt = await Swal.fire({
        title: t('TASKS:CreateNewTaskAlertTitle'),
        text: t('TASKS:CreateNewTaskAlertContent'),
        icon: "question",
        confirmButtonText: t('TASKS:CreateNewTaskAlertConfirmButton'),
        showCancelButton: true,
        cancelButtonText: t('TASKS:CreateNewTaskAlertCancelButton'),
        width: "400px",
      });

      if (!prompt.isConfirmed) return;
    }

    setShowTaskCreator(true);
  };

  const hasActiveFilters = () => {
    const values = Object.values(filters);
    const found = values.findIndex((f) => f !== "");
    return !!(found >= 0);
  };

  const handleChangeFilters = (filters: any) => {
    localStorage.setItem(
      `${project_id}-taskpage-filters`,
      JSON.stringify(filters)
    );

    loadTasks(filters)
    setFilters(filters);
  };

  const handleToggleOnlyMyTasks = () => {
    localStorage.setItem("onlyShowMyTasks", onlyShowMine ? "false" : "true");

    setOnlyShowMine(!onlyShowMine);
  };

  const handleSelectTask = (item_id: string) => {

    const { item, parent } = getItemFromTaskList(tasksReducer.result, item_id)

    setTask(item);
    setGroup(parent || {});
    setShowTaskEditor(true);
  };

  const handleCloseTaskEditor = () => {
    setTask(undefined);
    setGroup({});
    setShowTaskEditor(false);
  };

  const handleTaskCreated = async () => {

    setShowTaskCreator(false);
  };

  const handleTaskUpdated = async () => {

    setShowTaskEditor(false);
  };

  const handleConfirmedOrRejected = async (
    task_id: string,
    action: "confirm" | "reject"
  ) => {
    await dispatch(TasksActions.confirmOrReject(task_id, action));

    setShowTaskEditor(false);
  };

  const handleTaskDeleted = async (task: any) => {
    //TODO: pass the dispatch action to task editor
    await dispatch(TasksActions.remove(task, project._id));

    setShowTaskEditor(false);
  };

  useEffect(() => {
    if (!project._id) loadProject();

    if (project._id) {

      if (project.ability) ability.update(project.ability)

      loadTeams();
      loadColorCodes();


      if (project.mySettings.showOnlyMyTasks) {
        setOnlyShowMine(true)
      }
    }
  }, [ project._id ]);

  useEffect(() => {

    if (projectLoadStatus === 'loaded') {

      if (project.ability) ability.update(project.ability)

      if (programmation.loadStatus === 'none') {

        loadProgrammation();
      }
    }
  }, [ projectLoadStatus, programmation.loadStatus ]);

  useEffect(() => {

    if (programmation.loadStatus === 'loaded') {

      if (tasksReducer.loadResultStatus === 'none') {

        loadTasks()
      }
    }
  }, [ programmation.loadStatus, tasksReducer.loadResultStatus])

  useEffect(() => {

    loadTasks()
  }, [onlyShowMine])

  useEffect(() => {

    const projectLoaded = projectLoadStatus === 'loaded'
    const programmationLoaded = programmation.loadStatus === 'loaded' || programmation.loadStatus === 'error'

    if (!projectLoaded || !programmationLoaded) {

      dispatch(InterfaceActions.showLoader())
    } else {

      dispatch(InterfaceActions.hideLoader())
    }
  }, [ projectLoadStatus, programmation.loadStatus ])

  useLayoutEffect(() => {
    const updateHeight = () => {
      const contentBodyMargin = 10;

      const height =
        window.innerHeight -
        interfaceHeights.headerHeight -
        interfaceHeights.submenuHeight -
        interfaceHeights.contentHeaderHeight -
        contentBodyMargin -
        interfaceHeights.contentWrapPaddingTop -
        interfaceHeights.contentWrapPaddingBottom -
        interfaceHeights.footerHeight;

      setHeight(height);
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [ interfaceHeights ]);

  return (
    <MainLayout
      project={project}
      menuBar={menu}
      subMenuBar={submenu}
    >
      <MainLayoutContentHeader>
        <div className="ul-widget__head-label flex-grow-1">
          <h3 className="ul-widget__head-title">
            {t('TASKS:PageTitle')}
          </h3>
        </div>
        <div className="ul-widget__head-toolbar">
          <div className="text-right">
            <button className={`btn btn-link btn-link-header ${tasksReducer.loadResultStatus === 'loading' ? 'active' : ''} d-inline-block`}
              onClick={() => loadProgrammation()}
              disabled={tasksReducer.loadResultStatus === 'loading'}
            >
              <Icon name="sync" fas fw spin={tasksReducer.loadResultStatus === 'loading'} />
              <span className="d-none d-sm-inline">
                {t('TASKS:ToolbarRefreshbutton')}
              </span>
            </button>
            <Can I="create" a="task" ability={ability}>
              <button
                className="btn btn-link btn-link-header"
                onClick={() => handleNewTaskButton()}>
                <span className="icon-agregar_tarea"></span>
                <span className="d-none d-md-inline">
                  {t('TASKS:ToolbarCreatebutton')}
                </span>
              </button>
            </Can>
            <button
              className={`btn btn-link btn-link-header ${hasActiveFilters() ? "active" : ""
                } mr-1`}
              onClick={() => setShowTaskFilters(true)}>
              <span className="icon-filtro"></span>
              <span className="d-none d-md-inline">
                {t('TASKS:ToolbarFilterbutton')}
              </span>
            </button>
            <span title={project.showOnlyMyTasks ? t('TASKS:ToolbarShowOnlyMyTasksDisabled') : undefined}>
              <button
                className={`btn btn-link btn-link-header ${onlyShowMine ? "active" : ""
                  } mr-1`}
                onClick={() => handleToggleOnlyMyTasks()}
                disabled={project.mySettings.showOnlyMyTasks}
              >
                <span className="icon-tareas_usuario"></span>
                <span className="d-none d-md-inline">
                  {t('TASKS:ToolbarShowOnlyMyTasksButton')}
                </span>
              </button>
            </span>
          </div>
        </div>
        <div className="ul-widget__head-nav">
          <Tab.Container>
            <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
              <Nav.Item as="li">
                <Nav.Link
                  active={tab === "by_status"}
                  onClick={() => handleTabChange("by_status")}>
                  <span className="icon-orden_estado"></span> {' '}
                  <span className="hide-on-mobile">
                    {t('TASKS:ToolbarSortByStatus')}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link
                  active={tab === "by_month"}
                  onClick={() => handleTabChange("by_month")}>
                  <span className="icon-orden_mes"></span> {' '}
                  <span className="hide-on-mobile">
                    {t('TASKS:ToolbarSortByMonths')}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </MainLayoutContentHeader>
      <MainLayoutContentBody>
        <Tab.Content className="no-padding">
          <Tab.Pane active={tab === "by_status"}>
            <TaskGroupByState
              height={height}
              tasks={tasksReducer.result}
              teams={teams}
              onSelectTask={handleSelectTask}
            />
          </Tab.Pane>
          <Tab.Pane active={tab === "by_month"}>
            <TasksGroupByMonth
              height={height}
              tasks={tasksReducer.result}
              teams={teams}
              onSelectTask={handleSelectTask}
            />
          </Tab.Pane>
        </Tab.Content>
      </MainLayoutContentBody>

      <TaskFilters
        show={showTaskFilters}
        type="tasks"
        filters={filters}
        onChange={handleChangeFilters}
        onHide={() => setShowTaskFilters(false)}
      />

      <TaskCreator
        show={showTaskCreator}
        taskList={tasksReducer.result}
        nonWorkingDays={nonWorkingDays}
        project={project}
        projectMembers={TeamsHelper.getAllMembersFromProject(teams)}
        projectStartDate={project.startsAt}
        projectEndDate={project.endsAt}
        onHide={() => setShowTaskCreator(false)}
        onCreated={handleTaskCreated}
      />

      <TaskEditor
        show={showTaskEditor}
        task={task}
        group={group}
        taskList={tasksReducer.result}
        nonWorkingDays={nonWorkingDays}
        project={project}
        projectMembers={TeamsHelper.getAllMembersFromProject(teams)}
        projectStartDate={project.startsAt}
        projectEndDate={project.endsAt}
        onHide={() => handleCloseTaskEditor()}
        onUpdated={handleTaskUpdated}
        onConfirmOrReject={handleConfirmedOrRejected}
        onDelete={handleTaskDeleted}
        canConfirmOrReject={ability.can("confirm", "task")}
        canDelete={ability.can("delete", "task")}
        canUpdate={() => {
          const isPl4nner = ability.can("manage", "all");
          const isAssistant = project.assistants?.includes(userData?.id);
          const isCollaborator = task?.responsibles
            .map((r: any) => r._id)
            .includes(userData?.id);

          return isPl4nner || isAssistant || isCollaborator;
        }}
      />
    </MainLayout>
  );
};

export default ProjectTasksPage;
