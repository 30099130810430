export const SET_LANGUAGE = "SET_LANGUAGE";
export const LOAD_TRANSLATIONS = "LOAD_TRANSLATIONS";

export const setLanguage = (language: string) => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const loadTranslations = (lang: string, texts: any) => ({
  type: LOAD_TRANSLATIONS,
  payload: {
    language: lang,
    texts,
  },
});
