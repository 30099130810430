import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { subject } from '@casl/ability';

import config from '../../../config';

import { useAuth } from '../../../Hooks/UseAuth';
import { usePlan } from '../../../Hooks/UsePlan';
import useTranslation from '../../../Hooks/UseTranslation';

import { TChat, TChatMessage } from '../../../Types/Chat';

import UserAvatar from '../../UserAvatar/UserAvatar';
import ChatAutomaticMessage from '../ChatAutomaticMessage/ChatAutomaticMessage';
import ChatBot from '../../ChatBot/ChatBot';
import ChatMessage from '../ChatMessage/ChatMessage';

import "./ChatContent.scss"

interface Props {
  project?: any;
  chat: TChat;
  messages: TChatMessage[];
  onSendMessage: (message: string) => void;
  disabled: boolean;
}

const ChatContent: React.FC<Props> = ({ chat, messages, onSendMessage, disabled, project }) => {

  const { t } = useTranslation();

  const { userData } = useAuth()
  const { plan, showCantWriteToChatModal } = usePlan()

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [ inputValue, setInputValue ] = useState('');
  const [ canWrite, setCanWrite ] = useState(false);

  const getChatIcon = () => {

    if (chat.type === "project") return <span className={project === userData?.id ? 'icon-mis_proyectos' : 'icon-otros_proyectos'}></span>

    if (chat.type === "team") return <span className="icon-grupo_equipo"></span>

    if (chat.type === "private") return <UserAvatar user={chat.user} />
  }

  const getChatTitle = () => {

    if (chat.type === 'project') {

      return chat.project.name
    } else if (chat.type === 'team') {

      return (
        <p className="mb-0 text-title text-16 flex-grow-1">
          {chat.team.name}
        </p>
      )
    } else if (chat.type === 'private') {

      return (
        <p className="mb-0 text-title text-16 flex-grow-1">
          {chat.user?.name + ' ' + chat.user?.surname}
        </p>
      )
    }

    return null
  }

  const getChatSubtitle = () => {

    if (chat.type === 'team') {

      return (
        <div className="chat-content-subtitle">
          <span className="icon-proyecto"></span> {chat.project?.name}
        </div>
      )
    }

    return null
  }

  const interfaceHeights = useSelector((state: any) => state.InterfaceReducer.heights)

  const frameheight = useMemo(() => {

    const contentBodyMargin = 10

    const height = window.innerHeight -
      interfaceHeights.headerHeight -
      interfaceHeights.submenuHeight -
      interfaceHeights.contentHeaderHeight -
      contentBodyMargin -
      interfaceHeights.contentWrapPaddingTop -
      interfaceHeights.contentWrapPaddingBottom

    return height
  }, [ interfaceHeights ])

  const handleClickDisabled = () => {

    if (!canWrite)
      showCantWriteToChatModal(t('CHAT_CANT_CHAT:Title'))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!canWrite) {

      handleClickDisabled()
      return
    }

    if (inputValue.trim() !== '') {
      onSendMessage(inputValue);
      setInputValue('');
    }
  };

  useEffect(() => {

    if (chat)
      setCanWrite(plan.can('write', subject('Chat', { type: chat.type })))
  }, [ chat ])

  useEffect(() => {

    if (inputRef.current)
      inputRef.current.focus()
  }, [ chat ])

  return (
    <>
      {!chat && !config.chat.pl4nnerBot.enabled && (
        <div className="chat-content-empty" style={{ height: `${frameheight}px` }}>
          {t('CHAT:SelectAChat')}
        </div>
      )}
      {!chat && config.chat.pl4nnerBot.enabled && (
        <div className="chat-content" style={{ height: `${frameheight}px` }}>
          <div className="chat-content-header">
            <div className="chat-content-icon-ia">
              <i className="fas fa-fw fa-robot fa-sm" ></i>
            </div>
            <div className='chat-content-texts'>
              <div className='chat-content-title-ai'>
                {t('CHAT:PlannerIA')}
              </div>
              {/* {getChatSubtitle()} */}
            </div>
          </div>
          <div><ChatBot /></div>
        </div>
      )}
      {chat && (
        <div className="chat-content" style={{ height: `${frameheight}px` }}>
          <div className="chat-content-header">
            <div className="chat-content-icon">
              {getChatIcon()}
            </div>
            <div className='chat-content-texts'>
              <div className='chat-content-title'>
                {getChatTitle()}
              </div>
              {getChatSubtitle()}
            </div>
          </div>
          <div className='custom-scrollbar'>
            <div className="messages-container">
              {messages.map((message, i) => (
                <Fragment key={i}>
                  {message.isAutomatic && <ChatAutomaticMessage message={message} />}
                  {!message.isAutomatic && (
                    <ChatMessage
                      message={message}
                      chat={chat}
                      isMine={message.sender._id === userData?.id}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className='input-container'>
              <div className='input-barra'>
                <input
                  ref={inputRef}
                  type="text"
                  autoFocus
                  value={inputValue}
                  placeholder={canWrite ? t('CHAT:MessagePlaceholder') : t('CHAT:CantWriteInThisChat')}
                  onChange={(event) => setInputValue(event.target.value)}
                  onClick={handleClickDisabled}
                  readOnly={disabled || !canWrite}
                />
              </div>
              <button type="submit" disabled={disabled}>
                <i className="fas fa-fw fa-paper-plane"></i>
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ChatContent;