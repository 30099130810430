import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";

import useTranslation from "../../Hooks/UseTranslation";

import ChangeItem from "./ChangeItem";

const LastChanges = (props: any) => {

    const { t } = useTranslation()

    const fieldsToCompare = [
        'type', 'name', 'startsAt', 'endsAt', 'executeDays',
        'releasesAt', 'responsibles', 'restrictions', 'relations',
        'colorCode', 'unit', 'metered', 'performance', 'crewNumber',
        'estimatedDays', 'progress', 'toConfirm', 'comments',
        'startedAt', 'endedAt',
    ]

    const [ changes, setChanges ] = useState([]);

    const compare = () => {

        try {

            let lastVersion = props.versions[props.versions.length - 1]

            const newChanges: any = []

            for (let index = 1; index < props.versions.length; index++) {

                const previousVersion = props.versions[props.versions.length - 1 - index]
                console.log('previousVersion', previousVersion)

                fieldsToCompare.map((field: string) =>{

                    switch (field) {
                        case 'colorCode': {

                            const isDifferentName = lastVersion[field]?.name !== previousVersion[field]?.name
                            const isDifferentColor = lastVersion[field]?.color !== previousVersion[field]?.color

                            if (isDifferentName || isDifferentColor) {

                                const newChange:any = {
                                    fieldName: field,
                                    fieldFrom: previousVersion[field],
                                    fieldTo: lastVersion[field],
                                    updatedAt: lastVersion.updatedAt,
                                }

                                newChanges.push(newChange)
                            }
                            break
                        }
                        case 'type':
                        case 'name':
                        case 'progress':
                        case 'executeDays': {

                            if (lastVersion[field] !== previousVersion[field]) {

                                const newChange:any = {
                                    fieldName: field,
                                    fieldFrom: previousVersion[field],
                                    fieldTo: lastVersion[field],
                                    updatedAt: lastVersion.updatedAt,
                                }

                                newChanges.push(newChange)
                            }
                            break
                        }
                        case 'startsAt':
                        case 'endsAt':
                        case 'startedAt':
                        case 'endedAt':
                        case 'releasesAt':
                        case 'unit':
                        case 'metered':
                        case 'performance':
                        case 'crewNumber':
                        case 'estimatedDays': {

                            if (lastVersion[field] !== previousVersion[field]) {

                                const newChange:any = {
                                    fieldName: field,
                                    fieldFrom: previousVersion[field],
                                    fieldTo: lastVersion[field],
                                    updatedAt: lastVersion.updatedAt,
                                }

                                newChanges.push(newChange)
                            }
                            break
                        }
                        case 'restrictions':
                        case 'responsibles':
                        case 'comments': {

                            const isDifferentList = !_.isEqual(lastVersion[field], previousVersion[field])

                            if (isDifferentList) {

                                const newChange:any = {
                                    fieldName: field,
                                    fieldFrom: previousVersion[field],
                                    fieldTo: lastVersion[field],
                                    updatedAt: lastVersion.updatedAt,
                                }

                                newChanges.push(newChange)
                            }
                            break
                        }
                        default: {

                            console.log('default', field, lastVersion[field], previousVersion[field])
                        }
                    }
                })

                setChanges(newChanges)

                lastVersion = previousVersion;
            }
        } catch (error) {

            if (error instanceof Error) console.error(error);
        }
    }

    useEffect(() => {

        compare()
    }, [props.versions])

    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide()}
            backdrop="static"
            keyboard={true}
            size="lg"
            changes={props.comparesToString}
        >
            <Modal.Header closeButton>
                <div className="d-flex flex-row w-100">
                    <div className="flex-grow-1">
                        <Modal.Title>
                            {t('LAST_CHANGES:Title')}
                        </Modal.Title>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="ul-widget-s6__items">
                    {changes.map((change: any, i: number) => (
                        <ChangeItem key={i}
                            fieldName={change.fieldName}
                            fieldFrom={change.fieldFrom}
                            fieldTo={change.fieldTo}
                            updatedAt={change.updatedAt}
                        />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LastChanges

