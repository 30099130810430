
import moment from "moment-timezone"
import React, { useEffect, useMemo, useState } from "react"
import { Col, FormGroup, FormLabel, Modal, Nav, Row, Tab } from "react-bootstrap"
import { useDispatch } from "react-redux"
import Swal from "sweetalert2"

import { ColorCode, Task, TaskTypes } from "../../Types/Tasks"

import { usePlan } from "../../Hooks/UsePlan"
import useTranslation from "../../Hooks/UseTranslation"

import { getDiffDays } from "../../Helpers/DateFunctions"
import { checkRelations } from "../../Helpers/TasksHelper"
import { getStatusName } from "./TaskEditorHelper"
import { getExpectedEndDate } from "../../Helpers/ProgrammationHelper"

import TasksActions from "../../Redux/Actions/TasksActions"
import CheckList from "../CheckList/CheckList"
import ColorCodeSelector from "../ColorCodeSelector/ColorCodeSelector"
import CommentsList from "../CommentsList/CommentsList"
import GroupSelector from "../GroupSelector/GroupSelector"
import InputDateTime from "../InputDateTime/InputDateTime"
import InputFormControl from "../InputFormControl/InputFormControl"
import RelationsList from "../RelationsList/RelationsList"
import SelectFormControl from "../SelectFormControl/SelectFormControl"
import TaskResponsibleList from "../TaskResponsibleList/TaskResponsibleList"

import './TaskEditor.scss'

type Props = {
  show: boolean
  task: any
  group: any
  taskList: any
  nonWorkingDays: any[]
  project: any
  projectMembers: any[]
  projectStartDate: string
  projectEndDate: string
  onHide: () => void
  onUpdated: () => void
  onConfirmOrReject: (task_id: string, action: 'confirm' | 'reject') => void
  onDelete: (task: Task) => void
  canConfirmOrReject: boolean
  canDelete: boolean
  canUpdate: () => boolean
}

const TaskEditor = (props: Props) => {

  const { t } = useTranslation()

  const { plan } = usePlan()

  const dispatch = useDispatch()

  const [ name, setName ] = useState(props.task?.name || '')
  const [ groupId, setGroupId ] = useState('')
  const [ type, setType ] = useState('partida')
  const [ startDate, setStartDate ] = useState('')
  const [ startTime, setStartTime ] = useState(props.project.settings?.workTimeStart || '08:00')
  const [ endDate, setEndDate ] = useState('')
  const [ endTime, setEndTime ] = useState(props.project.settings?.workTimeEnd || '16:00')

  const hasTheProjectStarted = useMemo(() => {

    if (!props.project._id) return false

    return moment().isSameOrAfter(props.project.startsAt)
  }, [ props.project ])

  const executeDays = useMemo(() => {

    if (!startDate || !endDate) return 0

    return getDiffDays(startDate, endDate, props.nonWorkingDays)
  }, [ startDate, endDate ])

  const expectedEndDate = useMemo(() => {

    if (!startDate) return ''

    const endDate = getExpectedEndDate(props.task.startedAt, executeDays, props.nonWorkingDays, true)

    return moment(endDate).format('DD/MM/YYYY')
  }, [ startDate, executeDays ])

  const [ releaseDate, setReleaseDate ] = useState('')
  const [ colorCode, setColorCode ] = useState<ColorCode | undefined>()
  const [ showColorCodeSelector, setShowColorCodeSelector ] = useState(false)

  const [ responsibles, setResponsibles ] = useState<any[]>([])
  const [ restrictions, setRestrictions ] = useState<any[]>([])
  const [ relations, setRelations ] = useState<any[]>([])
  const [ comments, setComments ] = useState<any[]>([])

  const [ taskUnit, setTaskUnit ] = useState('')
  const [ taskMetered, setTaskMetered ] = useState(0)
  const [ taskPerformance, setTaskPerformance ] = useState(0)
  const [ taskCrewNumber, setTaskCrewNumber ] = useState(0)
  const [ taskEstimatedDays, setTaskEstimatedDays ] = useState(0)
  const [ progress, setProgress ] = useState(0)
  const [ confirmed, setConfirmed ] = useState(false)

  const [ tab, setTab ] = useState('generals')
  const [ editable, setEditable ] = useState(false)
  const [ updating, setUpdating ] = useState(false)

  const [ nameFocused, setNameFocused ] = useState(false)

  const getTaskType = (type: string) => {

    switch (type) {
        case 'partida': {
            return t('TASK_TYPES:Partida')
        }
        case 'tarea': {
            return t('TASK_TYPES:Task')
        }
        case 'diseño': {
            return t('TASK_TYPES:Design')
        }
        case 'reunion': {
            return t('TASK_TYPES:Meeting')
        }
        case 'hito': {
            return t('TASK_TYPES:Milestone')
        }
    }
}

  const getHeaderColor = () => {

    if (props.task?.type === 'hito') {

      return 'hito'
    }

    if (props.task?.type === 'reunion') {

      return 'reunion'
    }

    return `${props.task?.status}`
  }

  const unsetTask = () => {

    setName('')
    setGroupId('')
    setStartDate('')
    setEndDate('')
    setReleaseDate('')
    setColorCode(undefined)
    setRestrictions([])
    setRelations([])
    setResponsibles([])
    setComments([])
    setTaskUnit('')
    setTaskMetered(0)
    setTaskPerformance(0)
    setTaskCrewNumber(0)
    setTaskEstimatedDays(0)
    setType('partida')
  }

  const resetTask = () => {

    setName(props.task.name)
    setGroupId(props.group?._id || '')
    setType(props.task.type)
    setStartDate(props.task.startsAt)
    setEndDate(props.task.end_date)
    setReleaseDate(props.task.release_date)
    setColorCode(props.task.color_code)
    setRestrictions([ ...props.task.restrictions ])
    setRelations([ ...props.task.relations ])
    setResponsibles([ ...props.task.responsibles ])
    setComments([ ...props.task.comments ])
    setTaskUnit(props.task.unit)
    setTaskMetered(props.task.metered)
    setTaskPerformance(props.task.performance)
    setTaskCrewNumber(props.task.crew_number)
    setTaskEstimatedDays(props.task.estimated_days)
  }

  const handleChangeStart = (datetime: string) => {

    const datetimeObj = moment(datetime, 'YYYY-MM-DD HH:mm:ss');

    setStartDate(datetimeObj.format('YYYY-MM-DD'));
    setStartTime(datetimeObj.format('HH:mm:ss'));
    setReleaseDate(datetimeObj.format('YYYY-MM-DD'));

    handleChangeEnd(`${datetimeObj.format('YYYY-MM-DD')} ${endTime}`);
  }

  const handleChangeEnd = (datetime: string) => {

    const datetimeObj = moment(datetime, 'YYYY-MM-DD HH:mm:ss');

    setEndDate(datetimeObj.format('YYYY-MM-DD'))
    setEndTime(datetimeObj.format('HH:mm:ss'))
  }

  const handleDelete = async () => {

    const result = await Swal.fire({
      title: t('TASK_EDITOR:DeleteAlertTitle'),
      text: t('TASK_EDITOR:DeleteAlertContent'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: t('TASK_EDITOR:DeleteAlertCancelButton'),
      confirmButtonText: t('TASK_EDITOR:DeleteAlertConfirmButton'),
      width: '500px',
    })

    if (result.isConfirmed) {

      props.onDelete(props.task)
    }
  }

  const thereAreChanges = () => {

    if (name !== props.task.name) return true
    if (groupId !== props.task.group_id) return true
    if (type !== props.task.type) return true
    if (startDate !== props.task.start_date) return true
    if (endDate !== props.task.end_date) return true
    if (releaseDate !== props.task.release_date) return true
    if (colorCode?._id !== props.task.color_code?._id) return true

    return false
  }

  const handleEditionCancel = async () => {

    if (thereAreChanges()) {

      setNameFocused(false)

      const prompt = await Swal.fire({
        title: t('TASK_EDITOR:CancelEditAlertTitle'),
        text: t('TASK_EDITOR:CancelEditAlertContent'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: t('TASK_EDITOR:CancelEditAlertCancelButton'),
        confirmButtonText: t('TASK_EDITOR:CancelEditAlertConfirmButton'),
        width: '500px',
      })

      if (prompt.isConfirmed) {

        resetTask()
        unsetTask()
        props.onHide()
      }
    }

    setEditable(false)

  }

  const handleSetRestrictions = (restrictions: any[], deleted: any[], added: any[]) => {

    setRestrictions(restrictions)
  }

  const handleClose = async () => {

    setNameFocused(false)
    unsetTask()
    props.onHide()
    setEditable(false)
  }

  const handleCloseEdition = async () => {

    unsetTask()
    props.onHide()
    setEditable(false)

  }

  const isValidTask = (): boolean => {

    if (name === '') {

      alert(t('TASK_EDITOR:EnterTaskNameAlert'))
      return false
    }

    const taskStartsBeforeProjectStarts = moment(startDate).isBefore(props.projectStartDate,'day')
    const taskEndsAfterProjectEnds = moment(endDate).isAfter(props.projectEndDate, 'day')

    if (taskStartsBeforeProjectStarts || taskEndsAfterProjectEnds) {
        alert(t('TASK_EDITOR:InvalidDatesAlert'))
        return false
    }

    if (!checkRelations(relations, props.task?._id)) {

      alert(t('TASK_EDITOR:InvalidRelationsAlert'))
      return false
    }

    if (type === 'hito' || type === 'reunion') {

      if (startDate === '') {

        alert(t('TASK_EDITOR:EnterMilestoneDateAlert'))
        return false
      }
    } else {

      if (startDate === '' || endDate === '') {

        alert(t('TASK_EDITOR:EnterValidDatesAlert'))
        return false
      }
    }

    return true
  }

  const handleUpdateTask = async () => {

    if (!isValidTask()) return

    const startsAt = `${startDate} ${startTime}`
    const endsAt = `${endDate} ${endTime}`

    const task: Task = {
      ...props.task,
      groupId,
      name,
      startsAt,
      endsAt,
      executeDays,
      releasesAt: releaseDate,
      colorCodeId: colorCode?._id,
      restrictions,
      relations,
      responsibles: responsibles.map((res: any) => res._id),
      comments,
      unit: taskUnit,
      metered: taskMetered,
      performance: taskPerformance,
      crewNumber: taskCrewNumber,
      estimatedDays: taskEstimatedDays,
      progress,
      type,
    }

    task.project_id = props.project?._id

    setUpdating(true)

    try {

      await dispatch(TasksActions.update(task))

      unsetTask()

      props.onUpdated()
    } catch (error) {

      //TODO: show error message to user
    }

    setEditable(false)
    setUpdating(false)
  }

  const handleConfirmOrReject = async (action: 'confirm' | 'reject') => {

    props.onConfirmOrReject(props.task._id, action)
  }

  const canUpdateProgress = () => {

    if (!hasTheProjectStarted) return false

    const unchecked = restrictions?.filter((r: any) => !r.checked)

    return editable && !unchecked.length
  }

  const getName = () => {

    if (props.task?.type === 'hito') {

      return t('TASK_EDITOR:MilestoneType')
    }

    if (props.task?.type === 'reunion') {

      return t('TASK_EDITOR:MeetingType')
    }

    if (props.task?.status) {
      return getStatusName(props.task?.status)
    }
  }

  useEffect(() => {

    if (props.task) {

      setName(props.task.name)
      setStartDate(moment(props.task.startsAt).format('YYYY-MM-DD'))
      setStartTime(moment(props.task.startsAt).format('HH:mm'))
      setEndDate(moment(props.task.endsAt).format('YYYY-MM-DD'))
      setEndTime(moment(props.task.endsAt).format('HH:mm'))
      setReleaseDate(props.task.releasesAt && moment.utc(props.task.releasesAt).format('YYYY-MM-DD'))
      setColorCode(props.task.colorCode)
      setRestrictions([ ...props.task.restrictions ])
      setRelations([ ...props.task.relations ])
      setResponsibles([ ...props.task.responsibles ])
      setComments([ ...props.task.comments ])
      setTaskUnit(props.task.unit)
      setTaskMetered(props.task.metered)
      setTaskPerformance(props.task.performance)
      setTaskCrewNumber(props.task.crewNumber)
      setTaskEstimatedDays(props.task.estimatedDays)
      setType(props.task.type)
      setProgress(props.task.progress)
      setConfirmed(props.task.confirmed)
    }

    setGroupId(props.group._id || '')

    if (props.task?.progress === 100) {

      setTab('comments')
    }
  }, [ props.task, props.group ])

  useEffect(() => {

    if (taskMetered && taskPerformance && taskCrewNumber) {

      let estimatedDays = taskMetered / (taskPerformance * taskCrewNumber)

      if (parseInt(`${estimatedDays}`, 10) !== estimatedDays) {

        estimatedDays = (parseInt(`${estimatedDays}`, 10) + 1) || 0
      }

      setTaskEstimatedDays(estimatedDays)
    } else {

      setTaskEstimatedDays(0)
    }
  }, [ taskMetered, taskPerformance, taskCrewNumber ])

  return (
    <>
      <Modal
        show={props.show && !showColorCodeSelector}
        autoFocus
        onHide={handleClose}
        dialogClassName="modal-task-editor"
        backdrop="static"
        keyboard={true}
      >
        <Modal.Body>
          <Tab.Container defaultActiveKey={tab}>
            <div className="ul-widget__head">
              <div className="ul-widget__head-label flex-grow-1">
                <h4 className={`ul-widget__head-title color-${getHeaderColor()}`}>
                  {getName()}
                </h4>
              </div>
              <div className="ul-widget__head-nav">
                <Nav variant="tabs" className={`nav-${getHeaderColor()}`}>
                  <Nav.Item>
                    <Nav.Link eventKey='generals' >
                      <span className="fas fa-home"></span>
                      <span className="ocultar-en-pantallas-pequeñas"> {t('TASK_EDITOR:GeneralTab')}</span>

                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='restrictions' disabled={type === 'hito' || type === 'reunion'}
                      style={{
                        display: type === 'hito' || type === 'reunion' ? 'none' : 'block',
                      }}
                    >
                      <span className="icon-restricciones"></span>
                      <span className="ocultar-en-pantallas-pequeñas"> {t('TASK_EDITOR:RestrictionsTab')}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='relations'>
                      <span className="icon-relaciones"></span> {' '}
                      <span className="ocultar-en-pantallas-pequeñas"> {t('TASK_EDITOR:RelationsTab')}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='responsibles' disabled={type === 'hito' || type === 'reunion'}
                      style={{
                        display: type === 'hito' || type === 'reunion' ? 'none' : 'block',
                      }}
                    >
                      <span className="icon-responsables"></span> {' '}
                      <span className="ocultar-en-pantallas-pequeñas"> {t('TASK_EDITOR:ResponsiblesTab')}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='comments'>
                      <span className="icon-comentarios"></span> {' '}
                      <span className="ocultar-en-pantallas-pequeñas"> {t('TASK_EDITOR:CommentsTab')}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='metered' disabled={type === 'hito' || type === 'reunion'}

                      style={{
                        display: type === 'hito' || type === 'reunion' ? 'none' : 'block',
                      }}
                    >
                      <span className="icon-metrados"></span> {' '}
                      <span className="ocultar-en-pantallas-pequeñas"> {t('TASK_EDITOR:MeteredTab')}</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div className={`ul-widget__status-bar ${getHeaderColor()}`}></div>
            <div className="ul-widget__body">
              {progress === 100 && !confirmed && (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-info" role="alert">
                      {t('TASK_EDITOR:TaskIsPendingConfirmation')}
                    </div>
                  </div>
                </div>
              )}
              <Tab.Content>
                <Tab.Pane eventKey='generals' onChange={() => setNameFocused(true)}>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {t('TASK_EDITOR:InputNameLabel')}
                    </FormLabel>
                    <Col sm={7}>
                      <InputFormControl
                        placeholder={t('TASK_EDITOR:InputNamePlaceholder')}
                        value={name}
                        onChange={setName}
                        readOnly={!editable || props.task?.confirmed || updating}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {t('TASK_EDITOR:InputGroupLabel')}
                    </FormLabel>
                    <Col sm={3}>
                      <GroupSelector
                        value={groupId}
                        onChange={setGroupId}
                        taskList={props.taskList}
                        disabled={!editable || props.task?.confirmed || updating}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {t('TASK_EDITOR:InputTypeLabel')}
                    </FormLabel>
                    <Col sm={3}>
                      <SelectFormControl
                        value={type}
                        onChange={setType}
                        disabled={!editable || props.task?.confirmed || updating}
                      >
                        <option value="">
                          {t('TASK_EDITOR:InputTypeSelect')}
                        </option>
                        {Object.keys(TaskTypes).map((type: string, i: number) => (
                          <option key={i} value={type}>
                            {getTaskType(type)}
                          </option>
                        ))}
                      </SelectFormControl>
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {type === 'hito' && t('TASK_EDITOR:InputMilestoneDateLabel')}
                      {type === 'reunion' && t('TASK_EDITOR:InputMeetingDateLabel')}
                      {type !== 'hito' && type !== 'reunion' && t('TASK_EDITOR:InputStartDateLabel')}
                    </FormLabel>
                    <Col sm={3}>
                      <InputDateTime
                        date={startDate}
                        time={startTime}
                        onChange={handleChangeStart}
                        readOnly={!editable || props.task?.confirmed || updating}
                      />
                    </Col>
                  </FormGroup>
                  {(type !== 'hito' && type !== 'reunion') && (
                    <>
                      <FormGroup as={Row}>
                        <FormLabel className='col-sm-3'>
                          {t('TASK_EDITOR:InputEndDateLabel')}
                        </FormLabel>
                        <Col sm={3}>
                          <InputDateTime
                            date={endDate}
                            time={endTime}
                            onChange={handleChangeEnd}
                            readOnly={!editable || props.task?.confirmed || updating}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row}>
                        <FormLabel className='col-sm-3'>
                          {t('TASK_EDITOR:InputExecuteDaysLabel')}
                        </FormLabel>
                        <Col sm={2}>
                          <InputFormControl
                            type="number"
                            value={executeDays}
                            readOnly
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row}>
                        <FormLabel className='col-sm-3'>
                          {t('TASK_EDITOR:InputReleaseDateLabel')}
                        </FormLabel>
                        <Col sm={3}>
                          <InputFormControl
                            type="date"
                            value={releaseDate}
                            onChange={setReleaseDate}
                            readOnly={!editable || props.task?.confirmed || updating}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row}>
                        <FormLabel className='col-sm-3'>
                          {t('TASK_EDITOR:InputColorCodeLabel')}
                        </FormLabel>
                        <Col sm={3}>
                          <button
                            className="color-code-toggle"
                            onClick={() => setShowColorCodeSelector(true)}
                            disabled={!editable || props.task?.confirmed || updating}
                          >
                            {colorCode && (
                              <>
                                <span className="code">{colorCode?.name}</span>
                                <span className="color" style={{ backgroundColor: colorCode?.color }}></span>
                              </>
                            )}
                            {!colorCode && t('TASK_EDITOR:InputColorCodeSelect')}
                          </button>
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row}>
                        <FormLabel className='col-sm-3'>
                          {t('TASK_EDITOR:InputProgressLabel')}
                        </FormLabel>
                        <Col sm={4}>
                          <div className="d-flex">
                            <InputFormControl
                              type="range"
                              min={0}
                              max={100}
                              value={progress}
                              onChange={setProgress}
                              style={{
                                padding: 0,
                                flex: 9,
                              }}
                              disabled={!canUpdateProgress() || confirmed}
                              readOnly={!canUpdateProgress() || confirmed}
                            />
                            <div className="ml-2 py-2" style={{
                              flex: 3,
                            }}>
                              {progress}%
                            </div>
                          </div>
                        </Col>
                      </FormGroup>
                      {props.task?.startedAt && (
                        <>
                          <FormGroup as={Row}>
                            <FormLabel className='col-sm-3'>
                              {t('TASK_EDITOR:InputActualStartDateLabel')}
                            </FormLabel>
                            <Col sm={3}>
                              <InputFormControl
                                className="form-control-plaintext"
                                value={moment(props.task.startedAt).format('DD/MM/YYYY HH:mm')}
                                readOnly
                              />
                            </Col>
                          </FormGroup>
                          {!props.task?.endedAt && (
                            <FormGroup as={Row}>
                              <FormLabel className='col-sm-3'>
                                {t('TASK_EDITOR:InputEstimatedEndDateLabel')}
                              </FormLabel>
                              <Col sm={3}>
                                <InputFormControl
                                  className="form-control-plaintext"
                                  value={expectedEndDate}
                                  readOnly
                                />
                              </Col>
                            </FormGroup>
                          )}
                          {props.task?.endedAt && (
                            <FormGroup as={Row}>
                              <FormLabel className='col-sm-3'>
                                {t('TASK_EDITOR:InputActualEndDateLabel')}
                              </FormLabel>
                              <Col sm={3}>
                                <InputFormControl
                                  className="form-control-plaintext"
                                  value={moment(props.task.endedAt).format('DD/MM/YYYY HH:mm')}
                                  readOnly
                                />
                              </Col>
                            </FormGroup>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="restrictions">
                  <Row>
                    <Col sm={7}>
                      <CheckList
                        addText={t('TASK_EDITOR:AddRestrictionButton')}
                        items={restrictions}
                        onChange={(restrictions: any[], deleted, added) => handleSetRestrictions(restrictions, deleted, added)}
                        readOnly={!editable || confirmed || updating}
                        canCreate={plan.can('add', 'Restriction')}
                      />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey='relations'>
                  <RelationsList
                    taskList={props.taskList || []}
                    items={relations}
                    task={{
                      _id: props.task?._id,
                      groupId,
                      name,
                    }}
                    onChange={setRelations}
                    readOnly={!editable || props.task?.confirmed || updating}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='responsibles'>
                  <Row>
                    <Col sm={7}>
                      <TaskResponsibleList
                        responsibles={responsibles}
                        projectMembers={props.projectMembers}
                        onChange={setResponsibles}
                        disabled={!editable || props.task?.confirmed || updating}
                      />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="comments">
                  <CommentsList
                    // me={props.me}
                    list={comments}
                    task_id={props.task?._id}
                    onChange={setComments}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='metered'>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {t('TASK_EDITOR:InputUnitLabel')}
                    </FormLabel>
                    <Col sm={3}>
                      <InputFormControl
                        value={taskUnit}
                        onChange={setTaskUnit}
                        readOnly={!editable || props.task?.confirmed || updating}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {t('TASK_EDITOR:InputMeteredLabel')}
                    </FormLabel>
                    <Col sm={3}>
                      <InputFormControl
                        value={taskMetered > 0 ? taskMetered : ''}
                        onChange={setTaskMetered}
                        readOnly={!editable || props.task?.confirmed || updating}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {t('TASK_EDITOR:InputPerformanceLabel')}
                    </FormLabel>
                    <Col sm={3}>
                      <InputFormControl
                        value={taskPerformance > 0 ? taskPerformance : ''}
                        onChange={setTaskPerformance}
                        readOnly={!editable || props.task?.confirmed || updating}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {t('TASK_EDITOR:InputCrewNumberLabel')}
                    </FormLabel>
                    <Col sm={3}>
                      <InputFormControl
                        value={taskCrewNumber > 0 ? taskCrewNumber : ''}
                        onChange={setTaskCrewNumber}
                        readOnly={!editable || props.task?.confirmed || updating}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <FormLabel className='col-sm-3'>
                      {t('TASK_EDITOR:InputEstimatedTimeLabel')}
                    </FormLabel>
                    <Col sm={3}>
                      <InputFormControl
                        value={taskEstimatedDays > 0 ? taskEstimatedDays : '-'}
                        readOnly
                      />
                    </Col>
                  </FormGroup>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: 'space-between',
          }}
        >
          <div className="footer-left">
            {props.canDelete && (
              <button
                className="btn btn-danger"
                onClick={() => handleDelete()}
              >
                <span className="icon-eliminar"></span> {' '}
                {t('TASK_EDITOR:DeleteButton')}
              </button>
            )}
            {props.canConfirmOrReject && props.task?.progress === 100 && !props.task?.confirmed && (
              <>
                <button className="btn btn-secondary ml-1"
                  onClick={() => handleConfirmOrReject('confirm')}
                >
                  <i className="fas fa-fw fa-check"></i> {' '}
                  {t('TASK_EDITOR:ConfirmButton')}
                </button>
                <button className="btn btn-secondary ml-1"
                  onClick={() => handleConfirmOrReject('reject')}
                >
                  <i className="fas fa-fw fa-times"></i> {' '}
                  {t('TASK_EDITOR:RejectButton')}
                </button>
              </>
            )}
          </div>
          <div className="footer-right">
            <>
              {!editable && (
                <>
                  <button className="btn btn-secondary mr-1" onClick={() => handleClose()}>
                    <span className="icon-cancelar"></span> {' '}
                    {t('TASK_EDITOR:CloseButton')}
                  </button>
                  {props.canUpdate() && (
                    <button className="btn btn-primary" onClick={() => setEditable(true)}>
                      <span className="icon-editar"></span> {' '}
                      {t('TASK_EDITOR:EditButton')}
                    </button>
                  )}
                </>
              )}
              {editable && (
                <>
                  <button className="btn btn-secondary mr-1" onClick={() => handleEditionCancel()}>
                    <span className="icon-cancelar"></span> {' '}
                    {t('TASK_EDITOR:CancelButton')}
                  </button>
                  <button className="btn btn-primary" onClick={() => handleUpdateTask()} disabled={updating}>
                    <span className="icon-guardar"></span> {updating ? t('TASK_EDITOR:SavingButton') : t('TASK_EDITOR:SaveButton')}
                  </button>
                </>
              )}
            </>
          </div>
        </Modal.Footer>
      </Modal>

      <ColorCodeSelector
        show={showColorCodeSelector}
        onHide={() => setShowColorCodeSelector(false)}
        projectId={props.project?._id}
        code={colorCode}
        onSelect={(code: ColorCode) => {
          setColorCode(code)
          setShowColorCodeSelector(false)
        }}
      />
    </>
  )
}

export default TaskEditor
