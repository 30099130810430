import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";

import InterfaceActions from "../../Redux/Actions/InterfaceActions";

import "./MainLayoutFooter.scss";

const MainLayoutFooter = (props: any) => {
  const ref = useRef(null);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const saveHeight = () => {
      if (ref && ref.current) {
        const current: any = ref.current;

        dispatch(
          InterfaceActions.setDimension("footerHeight", current.offsetHeight)
        );
      }
    };

    saveHeight();

    window.addEventListener("resize", saveHeight);

    return () => window.removeEventListener("resize", saveHeight);
  }, []);

  return (
    <div ref={ref} className="footer-menu shadow-custom d-flex d-lg-none footermenu">
      <ul className="project-menu footer-menu__list footermenu">
        {props.menu?.map((menuItem: any, i: number) => (
          <li key={i}>
            {menuItem.disabled && (
              <span className="svg-icon-menu cursor-pointer">
                <div className="svg-icon">
                  <div className="svg-icon-container">
                    <span
                      className={`main-menu-icon ${menuItem.icon}${menuItem.active ? "_full" : ""
                        }`}></span>
                  </div>
                </div>
                <span className={`svg-icon-text width-${menuItem.textWidth}`}>
                  {menuItem.text}
                </span>
              </span>
            )}
            {!menuItem.disabled && (

              <Link
                className={`svg-icon-menu ${menuItem.active && "active"}`}
                to={menuItem.path}>
                <div className="svg-icon">
                  <div className="svg-icon-container">
                    <span
                      className={`main-menu-icon ${menuItem.icon}${menuItem.active ? "_full" : ""
                        }`}></span>
                  </div>
                </div>
              <p className="textMenu">{menuItem.text}</p>
              </Link>
            )}
          </li>
        ))}

      </ul>
    </div>
  );
};

export default MainLayoutFooter;
