
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import config from '../../config'
import navigation from '../../navigation'

import { useAuth } from '../../Hooks/UseAuth'
import { usePlan } from '../../Hooks/UsePlan'
import useTranslation from '../../Hooks/UseTranslation'

import ProjectsActions from '../../Redux/Actions/ProjectsActions'
import ProjectsService from '../../Services/ProjectsService'

import MainLayout from '../../Components/MainLayout/MainLayout'
import BigButton from '../../Components/BigButton/BigButton'
import Swal from 'sweetalert2'

type Props = {
    projectsLoadResultStatus: any,
    projects: any,
    session: any,
    notifications: any,
}

const OtherProjectsPage = (props: Props) => {

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { userData } = useAuth()
    const { plan, tooManyArchivedProjects, showTooManyProjectsModal, showTooManyArchivedProjectsModal } = usePlan()

    const projectsLoadResultStatus = useSelector((state: any) => state.ProjectsReducer.loadResultStatus)
    const projects = useSelector((state: any) => state.ProjectsReducer.data)

    const [ menu, submenu ] = navigation.getNavigation(
        '',
        'homepage',
        'other_projects'
    )

    const loadProjects = async () => {

        await dispatch(ProjectsActions.getResultAction())
    }

    useEffect(() => {

        if (projectsLoadResultStatus === 'none') {

            loadProjects()
        }
    }, [])

    const handleRestore = async (project_id: string) => {

        if (!plan.can('create', 'Project')) {

            showTooManyProjectsModal(t('HOME:TooManyActiveProjects-title'))
        } else {

            try {
                await ProjectsService.restoreProject(project_id);

                await dispatch(ProjectsActions.getResultAction())

            } catch (error) {
                console.error('Error archivando proyecto:', error)
            }
        }
    }

    const handleDelete = async (project_id: string) => {

        const result = await Swal.fire({
            title: t('HOME:DeleteProject-title'),
            text: t('HOME:DeleteProject-content'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: t('HOME:DeleteProject-cancel'),
            confirmButtonText: t('HOME:DeleteProject-accept'),
            width: '500px',
        })

        if (result.isConfirmed) {

            await dispatch(ProjectsActions.deleteAction(project_id))
        }
    }

    useEffect(() => {

        if (tooManyArchivedProjects)
            showTooManyArchivedProjectsModal(t('HOME:TooManyArchivedProjects-title'))
    }, [ tooManyArchivedProjects, projects.archived ])

    return (
        <MainLayout
            title="PL4NNER"
            subMenuBar={submenu}
            avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
            noBottomBar
        >
            <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="row">
                        {projects.archived?.map((project: any, i: number) => (
                            <div key={i} className="col-xl-2 col-lg-3 col-md-4">
                                <BigButton

                                    icon="icon-otros_proyectos"
                                    text={project.shortName}
                                    to={`/project/${project._id}`}
                                    altMenu={[
                                        {
                                            icon: <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADqklEQVR4nO1YS2gTURQd8YP4QVQsIioqIooLRRDEhYK6ceVCBu3cG0ubebc/m3vT+sOFURDBhbhWwaIr9342iiCIFHRRRRDBulAqVNSF1mKtP17aJC/TZDKZjGkKOfAgJPe9OefdefedG8uqo4466qijjjoKo8PuWNDutC+2pgsIZBuBXFDA/QQ8TCh/9VDIY/o7hXJZQWK7VWtwj8hmQr6TIVxqKJQHrZjcYtUCFHALIY8WIfpNoXwhlD+TfgP5oTBxdKrJn8knrF8VuaEc3q/f/0wcYs98gsReAuklkJ8eMeemhHwrcKNnZ5/FY7yp1Dw31r2BQPryRSSbq8M6QwK6VhLId4PEPSKaF3R+U1NqLqHczs4HHm5t4jVWtUDI14yHv2xpOb6w7DWI5hHIc2MTrlrVQBv2NOh3Pftgh3dVWHZ/Tqx13qpe1clWmUeVruc6vJVibKd2p2ZZ1YBCuZVLOyes6QLbtme2HO5coYCf5jLQtdOqZbjpasMXCfmVAv7tvYzaHVkXxXMU8HVj/V+E/IGQHyoQDlMgNGYolBMKecTPEsTt5JJKyZN9cpGv9QD56KJAeYuiXJ1sC9KVZ1AhD+gRZdVQ+Rko7J+AzwYjDyyeHejT1sC2U3OsKp01d/yidAnkjcmlZCaam7uWEfBXg3xvlOXNdeQAgYzpoT9bJZD2UKbLBfnoeyYI5JSRsn4imh0V+fSllXemeER/F0iEmQmQrqLBCvlx7oZNOkHJxTG5nkDe6qE/FyH/efK7zZ+DiHAdVoZ1uV9cAPBQJrCj6djyoAII5LSxQ6eDkQ8uov1Q9ypjzqCPAPmRCSzn0OoKUahaeMmbZTm/RLOvCM3FiB0tSoRQ3udO/NG1lQqYKLdZkgr4ihF3xSNuoCivWGJ1oAykfX2u5ePoBIzvsDeOjAz5CkChQGcg77Agf+iMdS6tRED6cDvJnszBLhQX98R4oVtTM5O+fbTulBTyOyMLT/TdEFZA2Lg88sB3jTlDJb2RvnXHDVVWxCdCSbmQ3KHNmx7e9jFKAbZtz5yoOpQuzUbF0n24FQQUkzZTRAFfNOKi7IlCQBv2NBDyC19DNx5/JhD57MNiso+AXxcXIZeiEEBO0ilBfCjwznuhfZDCxEECvqkbmYwTTTc1jd0b/1MGfulSqauNtg1h+4Gy8L8OcdUw7QTEQ9b3mhGgQt6wNShAyvI4NSOAQrrMmhEQ1uf79Q1TgnI7rVKd25QgbK9bU3DL/LchSvwDzfeDZ1meZRIAAAAASUVORK5CYII="></img>,
                                            label: t('ARCHIVED_PROJECTS:ProjectButtonRestore'),
                                            onClick: () => handleRestore(project._id)

                                        },
                                        {
                                            icon: <i className="fas fa-fw fa-trash-alt"></i>,
                                            label: t('ARCHIVED_PROJECTS:ProjectButtonDelete'),
                                            color: '#f44336',
                                            onClick: () => handleDelete(project._id)
                                        },
                                    ]}
                                    disabled
                                    onDisabledClick={() => {

                                        if (tooManyArchivedProjects)
                                            showTooManyArchivedProjectsModal(t('HOME:TooManyArchivedProjects-title'))
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default OtherProjectsPage

