
import React from "react";
import { Modal } from "react-bootstrap";

import config from "../../../config";
import useTranslation from "../../../Hooks/UseTranslation";

interface Props {
  show: boolean
  title: string;
  onHide: () => void;
}

const CantWriteToChatModal = (props: Props) => {

  const { t } = useTranslation();

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('CANT_CHAT_MODAL:ContentA"')}
        </p>
        <p>
          <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i> {t('CANT_CHAT_MODAL:ContentB')}
          </a> {t('CANT_CHAT_MODAL:ContentC')}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          {t('CANT_CHAT_MODAL:Close')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CantWriteToChatModal
