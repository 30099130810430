
import React from 'react'

import './ExcelSheetPicker.scss'
import { Modal } from 'react-bootstrap'

import useTranslation from '../../Hooks/UseTranslation'

import { ReadSheetFile } from './SheetFunctions'

import gifImage from '../../assets/gif-terminado-1.gif'

type Props = {
  show: boolean
  title: string
  onHide: () => void
  onFileRead: (content: any) => void
}

const FilePicker = ({ show, title, onHide, onFileRead }: Props) => {

  const { t } = useTranslation()

  const [ file, setFile ] = React.useState<File | null>(null)

  React.useEffect(() => {
    if (!file) return
    const reader = new FileReader()
    reader.onload = async (e) => {
      if (!e.target) return

      const content = e.target.result

      const data = await ReadSheetFile(content)

      onFileRead(data)
    }
    reader.readAsArrayBuffer(file)
  }, [ file ])

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={gifImage} alt="GIF animado" />
        <input type="file" onChange={(e) => setFile(e.target.files?.[ 0 ] || null)} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>
          {t('EXCEL_SHEET_PICKER:CancelButton')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default FilePicker
