
import React, { useEffect, useMemo, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'

import useTranslation from '../../Hooks/UseTranslation'

import { getSelectableGroups } from '../../Helpers/ProgrammationHelper'

import InputFormControl from '../InputFormControl/InputFormControl'
import SelectFormControl from '../SelectFormControl/SelectFormControl'

type Props = {
    show?: boolean;
    taskList?: any;
    group?: any;
    parentId: string;
    onSubmit: (id: string, name: string, parent_id: string) => void;
    onHide: () => void;
    onDelete: (group_id: string) => void;

    // deprecated
    groups?: any;
    selected?: any;
}

const GroupEditor = (props: Props) => {

    const { t } = useTranslation()

    const [ editable, setEditable ] = useState(true)

    const [ name, setName ] = useState('')
    const [ parentId, setParentId ] = useState('')

    const resetForm = () => {

        setName('')

        props.onHide()
    }

    const saveGroup = () => {

        props.onSubmit(props.group._id, name, parentId)

        props.onHide()

        resetForm()
    }

    const handleDelete = () => {

        if (window.confirm(t('GROUP_EDITOR:DeleteConfirm'))) {

            props.onDelete(props.group._id)
            resetForm()
        }
    }

    const handleClose = () => {

        props.onHide()
    }

    const handleCancel = () => {

        setParentId('')

        if (props.group._id) {

            setEditable(false)
        } else {

            props.onHide()
        }
    }

    const rootGroups = useMemo(() => {

        return getSelectableGroups(props.taskList, props.group._id)
    }, [ props.taskList, props.group._id ])

    useEffect(() => {

        if (props.group?._id) {
            setEditable(false)
        } else {
            setEditable(true)
        }

        setName(props.group?.name ?? '')
    }, [ props.group ])

    useEffect(() => {

        setParentId(props.parentId)
    }, [ props.parentId ])

    return (
        <Modal show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={true}
        >
            <Modal.Header>
                <Modal.Title>
                    {props.group._id ? t('GROUP_EDITOR:EditTitle') : t('GROUP_EDITOR:CreateTitle')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="form-group">
                        <label htmlFor="">
                            {t('GROUP_EDITOR:MainGroupLabel')}
                        </label>
                        <SelectFormControl
                            value={parentId}
                            onChange={setParentId}
                            disabled={!editable}
                        >
                            <option value="">
                                {t('GROUP_EDITOR:MainGroupSelect')}
                            </option>
                            {rootGroups.map((group: any) => (
                                <option key={group._id} value={group._id}>{group.name}</option>
                            ))}
                        </SelectFormControl>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">
                            {t('GROUP_EDITOR:NameLabel')}
                        </label>
                            <InputFormControl
                                value={name}
                                onChange={setName}
                                onEnter={() => saveGroup()}
                                onEscape={() => resetForm()}
                                readOnly={!editable}
                            />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer
                style={{
                    justifyContent: 'space-between'
                }}
            >
                <div className="footer-left">
                    {!props.group._id ? null : (
                        <button className="btn btn-danger"
                            onClick={() => handleDelete()}
                        >
                            <span className="icon-eliminar"></span> {' '}
                            {t('GROUP_EDITOR:DeleteButton')}
                        </button>
                    )}
                </div>
                <div className="footer-right">
                    {editable && (
                        <>
                            <button className="btn btn-secondary mr-1"
                                onClick={() => handleCancel()}
                            >
                                <span className="icon-cancelar"></span> {' '}
                                {t('GROUP_EDITOR:CancelButton')}
                            </button>
                            <button
                                className="btn btn-raised btn-link active"
                                onClick={() => saveGroup()}
                            >
                                <span className="icon-guardar"></span> {' '}
                                {t('GROUP_EDITOR:SaveButton')}
                            </button>
                        </>
                    )}
                    {!editable && (
                        <>
                            <button className="btn btn-secondary mr-1"
                                onClick={() => handleClose()}
                            >
                                <span className="icon-cancelar"></span> {' '}
                                {t('GROUP_EDITOR:CloseButton')}
                            </button>
                            <button className="btn btn-raised btn-link active"
                                onClick={() => setEditable(true)}
                            >
                                <span className="icon-editar"></span> {' '}
                                {t('GROUP_EDITOR:EditButton')}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default GroupEditor
