
import { combineReducers } from 'redux'

import ApplicationReducer from './ApplicationReducer'
import ChatReducer from './ChatReducer'
import ColorCodes from './ColorCodeReducer'
import DashboardReducer from './DashboardReducer'
import IndicatorsPPCReducer from './IndicatorsPPCReducer'
import IndicatorsReducer from './IndicatorsReducer'
import InterfaceReducer from './InterfaceReducer'
import LookaheadReducer from './LookaheadReducer'
import MacroReducer from './MacroReducer'
import NotificationsReducer from './NotificationsReducer'
import ProgrammationReducer from './ProgrammationReducer'
import ProjectCNCReducer from './ProjectCNCReducer'
import ProjectsReducer from './ProjectsReducer'
import ResourcesCNCReducer from './ResourcesCNCReducer'
import ResourcesRestrictionsReducer from './ResourcesRestrictionsReducer'
import SessionReducer from './SessionReducer'
import TasksReducer from './TasksReducer'
import TeamsReducer from './TeamsReducer'
import TranslationsReducer from './TranslationReducer'
import UsersReducer from './UsersReducer'
import WeeklyReducer from './WeeklyReducer'

const Reducers = combineReducers({
    ApplicationReducer,
    ChatReducer,
    ColorCodes,
    DashboardReducer,
    IndicatorsPPCReducer,
    IndicatorsReducer,
    InterfaceReducer,
    LookaheadReducer,
    MacroReducer,
    NotificationsReducer,
    ProgrammationReducer,
    ProjectCNCReducer,
    ProjectsReducer,
    ResourcesRestrictionsReducer,
    ResourcesCNCReducer,
    SessionReducer,
    TasksReducer,
    TeamsReducer,
    TranslationsReducer,
    UsersReducer,
    WeeklyReducer,
})

export default Reducers
