
import React, { Fragment, useMemo } from "react";

import useTranslation from "../../Hooks/UseTranslation";

interface Props {
  from: any;
  to: any;
}

const ChangeResponsibles = ({ from, to }: Props) => {

  const { t } = useTranslation()

  const responsibles = useMemo(() => {

    const newResponsibles: any = []

    from.forEach((responsible: any) => {

      let existsInNewList = false

      to.forEach((responsibleFrom: any) => {

        if (responsibleFrom._id === responsible._id) {

          existsInNewList = true
        }
      })

      if (!existsInNewList) {

        newResponsibles.push({
          _id: responsible._id,
          name: responsible.name,
          surname: responsible.surname,
          deleted: true
        })
      }
    })

    to.forEach((responsible: any) => {

      let existsInOldList = false

      from.forEach((responsibleTo: any) => {

        if (responsibleTo._id === responsible._id) {

          existsInOldList = true
        }
      })

      if (!existsInOldList) {

        newResponsibles.push({
          _id: responsible._id,
          name: responsible.name,
          surname: responsible.surname,
          added: true
        })
      }
    })

    return newResponsibles
  }, [ from, to ])

  return (
    <ul className="change-ul">
      {responsibles.map((responsible: any, i: number) => (
        <Fragment key={i}>
          {responsible.deleted && (
            <li>
              {t('CHANGE_RESPONSIBLE:ItWasRemovedTo')}{' '}
              <span className="change removed">
                {responsible.name} {responsible.surname}
              </span>{' '}
              {t('CHANGE_RESPONSIBLE:IsNotResponsibleAnymore')}
            </li>
          )}
          {responsible.added && (
            <li>
              {t('CHANGE_RESPONSIBLE:ITWasAddedAsResponsibleTo')} {' '}
              <span className="change added">{responsible.name} {responsible.surname}</span>
            </li>
          )}
        </Fragment>
      ))}
    </ul>
  )
}

export default ChangeResponsibles;

