import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import "./ChatBot.scss"; // Archivo CSS para los estilos

const ChatBot = () => {
    const [ inputValue, setInputValue ] = useState("");
    const [ chatLog, setChatLog ] = useState<{ type: string; message: string }[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setChatLog((prevChatLog) => [
            ...prevChatLog,
            { type: "user", message: inputValue }
        ]);

        sendMessage(inputValue);

        setInputValue("");
    };

    const sendMessage = (message: any) => {
        const url = "https://api.openai.com/v1/chat/completions";
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API}`
        };
        const data = {
            model: "gpt-3.5-turbo-1106",
            messages: [ { role: "user", content: message } ]
        };

        setIsLoading(true);

        axios.post(url, data, { headers: headers })
            .then((response) => {
                setChatLog((prevChatLog) => [
                    ...prevChatLog,
                    { type: 'bot', message: response.data.choices[ 0 ].message.content }
                ]);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    return (
        <div className="chat-container"> {/* Agregar contenedor para los mensajes */}



            {/* <div className="chat-content" style={{ height: `${frameheight}px` }}> */}


            <div className="messages-container-ia">


                <div className="message-box">
                    {chatLog.map((message, index) => (
                        <div
                            key={index}
                            className={message.type === 'user' ? 'user-message' : 'bot-message'}
                        >
                            {message.message}
                        </div>
                    ))}
                    {isLoading && <div className="loading-indicator">Cargando...</div>}
                </div>
                {/* {messages.map((message, i) => (
                            <Fragment key={i}>
                                {message.isAutomatic && <ChatAutomaticMessage message={message} />}
                                {!message.isAutomatic && (
                                    <ChatMessage
                                        message={message}
                                        chat={chat}
                                        isMine={message.sender._id === session.id}
                                    />
                                )}
                            </Fragment>
                        ))} */}
            </div>


            <form onSubmit={handleSubmit}>
                <div className='input-container'>
                    <div className='input-barra'>
                        <input
                            // ref={inputRef}
                            type="text"
                            autoFocus
                            value={inputValue}
                            onChange={(event) => setInputValue(event.target.value)}
                        // disabled={disabled}
                        />
                    </div>
                    {/* <button type="submit" disabled={disabled}> */}
                    <button type="submit" >
                        <i className="fas fa-fw fa-paper-plane"></i>
                    </button>
                </div>
            </form>

        </div>
    );
};

export default ChatBot;
