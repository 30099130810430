
import React from "react";
import GanttChartBodyGridColumn from "./GanttChartBodyGridColumn";
import GanttChartBodyGridRow from "./GanttChartBodyGridRow";

type Props = {
  days: any[];
  items: any[];
  dayWidth: number;
  height: number;
  rowHeight: number;
  selectedItems: any[];
  showVerticalBorders: boolean;
  onItemHover: (itemIndex: number) => void
}

const GanttChartBodyGrid = (props: Props) => {

  const getItemHeight = (item: any) => {

    const isExpendedGroup = item.schema === 'group' && !item.isCollapsed
    const isMeeting = item.schema === 'task' && item.type === 'reunion'
    const isMilestone = item.schema === 'task' && item.type === 'hito'

    if (isExpendedGroup || isMeeting || isMilestone)
      return 32

    return props.rowHeight
  }

  return (
    <g id="chart-grid">
      <g id="grid-rows">
        {props.items.map((item: any, i: number) => (
          <GanttChartBodyGridRow
            key={i}
            top={item.top}
            width={(props.days.length * props.dayWidth) + 17}
            height={getItemHeight(item)}
            isActive={props.selectedItems.some((selectedItem: any) => selectedItem._id === item?._id)}
            onItemHover={() => props.onItemHover(i)}
            needsConfirmation={item.progress === 100 && !item.confirmed}
          />
        ))}
      </g>
      <g id="grid-non-working-days">
        {props.days.map((day: any, i: number) => (
            <GanttChartBodyGridColumn
              key={i}
              day={day}
              left={i * props.dayWidth}
              height={props.height}
              width={props.dayWidth}
              showVerticalBorders={props.showVerticalBorders}
            />
        ))}
      </g>
    </g>
  )
}

export default GanttChartBodyGrid
