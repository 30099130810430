
import React, { useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';

import useTranslation from '../../Hooks/UseTranslation';

import { ColorCode } from '../../Types/Tasks';

import ColorCodeActions from '../../Redux/Actions/ColorCodeActions';
import InputFormControl from '../InputFormControl/InputFormControl';
import ColorCodeOption from './ColorCodeOption';

import './ColorCodeSelector.scss'

type Props = {
  show: boolean;
  onHide: () => void;
  projectId: string;
  code: ColorCode | undefined;
  onSelect: (code: ColorCode) => void;
}

const ColorCodeSelector = (props: Props) => {

  const  { t } = useTranslation()

  const dispatch = useDispatch()

  const codes = useSelector((st: any) => st.ColorCodes.data)
  const [ creating, setCreating ] = useState(false)
  const [ editing, setEditing ] = useState(false)

  const [ code, setCode ] = useState({
    name: '',
    color: ''
  })

  const resetForm = () => {
    setCreating(false)
    setEditing(false)
    setCode({
      name: '',
      color: ''
    })
  }

  const editCode = async () => {

    await dispatch(ColorCodeActions.update(code, props.projectId))
    await dispatch(ColorCodeActions.getResult(props.projectId))
    resetForm()
  }

  const createCode = async () => {

    await dispatch(ColorCodeActions.create(code, props.projectId))

    resetForm()
  }

  const handleEditCode = (code: ColorCode) => {

    setEditing(true)
    setCode(code)
  }

  const handleHide = () => {

    resetForm()
    props.onHide()
  }

  return (
    <Modal
      show={props.show}
      onHide={handleHide}
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>
          {t('COLOR_CODE_SELECTOR:Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!creating && !editing && (
          <ul className="list-group mb-2">
            {codes?.map((code: ColorCode, i: number) => (
              <Dropdown.Item
                key={i} as={ColorCodeOption}
                name={code.name}
                color={code.color}
                onClick={(e: any) => props.onSelect(code)}
                selected={props.code && props.code.name === code.name}
                buttons={[
                  {
                    icon: 'fas fa-fw fa-ellipsis-v',
                    onClick: () => handleEditCode(code)
                  }
                ]}
              />
            ))}
          </ul>
        )}
        {creating && (
          <>
            <div className="mb-2">
                <InputFormControl
                    placeholder={t('COLOR_CODE_SELECTOR:InputTitlePlaceholder')}
                    value={code.name}
                    onChange={(name: string) => setCode({ ...code, name })}
                />
            </div>
            <div className="text-center mb-2"
                style={{
                    padding: '0 1px'
                }}
            >
                <SketchPicker
                    color={code.color}
                    onChangeComplete={(color: any) => setCode({ ...code, color: color.hex })}
                />
            </div>
          </>
        )}
        {editing && (
          <>
            <div className="mb-2">
              <InputFormControl
                placeholder={t('COLOR_CODE_SELECTOR:InputTitlePlaceholder')}
                value={code.name}
                onChange={(name: string) => setCode({ ...code, name })}
              />
            </div>
            <div className="text-center mb-2"
              style={{
                padding: '0 1px'
              }}
            >
              <SketchPicker
                color={code.color}
                onChangeComplete={(color: any) => setCode({ ...code, color: color.hex })}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        { editing && (
          <>
            <Button variant="secondary" onClick={resetForm}>
              {t('COLOR_CODE_SELECTOR:CancelButton')}
            </Button>
            <Button variant="primary" onClick={() => editCode()}>
              {t('COLOR_CODE_SELECTOR:SaveButton')}
            </Button>
          </>
        )}
        {!editing && !creating && (
          <>
            <Button variant="secondary" onClick={handleHide}>
              {t('COLOR_CODE_SELECTOR:CloseButton')}
            </Button>
            <Button variant="primary" onClick={() => setCreating(true)}>
              {t('COLOR_CODE_SELECTOR:AddButton')}
            </Button>
          </>
        )}
        {creating && (
          <>
            <Button variant="secondary" onClick={resetForm}>
              {t('COLOR_CODE_SELECTOR:CancelButton')}
            </Button>
            <Button variant="primary" onClick={createCode}
              disabled={code.name === '' || code.color === ''}
            >
              {t('COLOR_CODE_SELECTOR:AcceptButton')}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ColorCodeSelector
