import React from 'react';

import useTranslation from '../../Hooks/UseTranslation';

import UserAvatar from '../UserAvatar/UserAvatar';

import './TaskCard.scss';

const moment = require('moment');

type Props = {
  task: any;
  responsibles: any;

  onClick: any;
};

const TaskCard = (props: Props) => {

  const { t } = useTranslation();

  const restrictions = props.task.restrictions || [];
  const responsibles = props.responsibles || [];

  const { all, toDo, done } = restrictions.reduce(
    (acc: any, restriction: any) => {
      if (restriction.checked) {
        acc.done += 1;
      }
      acc.toDo += 1;

      return acc;
    },
    { done: 0, toDo: 0, all: restrictions }
  );

  const getPreviewTaskDates = () => {
    const startsAt = moment(props.task.startsAt, 'YYYY-MM-DD').format(
      'DD-MM-YY'
    );
    const endsAt = moment(props.task.endsAt, 'YYYY-MM-DD').format('DD-MM-YY');

    return startsAt + ' al ' + endsAt;
  };

  const getPreviewRestrictionsList = () => {
    return (
      <div className="task-restrictions">
        <ul>
          {all.map((restriction: any, i: number) => {
            if (i === 2) {
              return '...';
            } else if (i >= 3) {
              return null;
            } else {
              return (
                <li
                  key={i}
                  className={`${!restriction.checked ? '' : 'cleared'}`}
                >
                  {i === 2 ? '...' : restriction.description}
                </li>
              );
            }
          })}
        </ul>
        {toDo === 0 ? null : <div>{`(${done}/${toDo})`}</div>}
      </div>
    );
  };

  const getTaskStatus = (status: string) => {

    switch (status) {
      case 'activa':
        return t('TASK_CARD:ActiveStatus');
      case 'activa-atrasada':
        return t('TASK_CARD:ActiveOverdueStatus');
      case 'liberada':
        return t('TASK_CARD:ReleasedStatus');
      case 'pendiente':
        return t('TASK_CARD:PendingStatus');
      case 'atrasada':
        return t('TASK_CARD:OverdueStatus');
      case 'completada':
        return t('TASK_CARD:CompletedStatus');
      default:
        return status
    }
  }

  return (
    <div
      className={`kanban-task status-${props.task.status || 'activa'} mb-2`}
      onClick={() => props.onClick()}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div className="task-name">{props.task.name}</div>
        <div className="task-position">{props.task.position}</div>
      </div>
      <div className="task-dates">
        {getPreviewTaskDates()}
        <span className="task-date-days text-center">
          {props.task.executeDays} {t('TASK_CARD:Days')}
        </span>
      </div>
      {getPreviewRestrictionsList()}
      <div>
        <div
          className={`task-status-name task-status-${
            props.task.status || 'activa'
          } float-left`}
        >
          {getTaskStatus(props.task.status)}
        </div>
        <div className="task-responsibles-avatars text-right">
          {responsibles.map((responsible: any, i: number) => (
            <UserAvatar key={i} user={responsible} size="sm" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
