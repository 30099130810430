
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import useTranslation from '../../Hooks/UseTranslation'

import InputFormControl from '../InputFormControl/InputFormControl'
import SelectFormControl from '../SelectFormControl/SelectFormControl'

type Props = {
    show: boolean,
    row: any,
    result: any,
    saving: boolean,
    onHide: any,
    onSave: any,
}

const CncEditor = (props: Props) => {

    const { t } = useTranslation()

    const [ row, setRow ] = useState({
        name: '',
        code: '',
        tmpCode: '',
        type: '',
    })

    const getNewCode = (type: string) => {

        if (!type) return ''

        const cncs = props.result.filter((row: any) => row.cnc.type === type).sort()

        const lastRow = cncs[cncs.length - 1]

        const lastCode = lastRow?.cnc.code

        const parts = lastCode?.split('.')

        return `${parts[0]}.${parseInt(parts[1], 10) + 1}`
    }

    const handleChangeType = (type: string) => {

        const code = getNewCode(type)

        setRow({ ...row, type, code })
    }

    const setNameAndCodePlaceholder = (name: string) => {

        const tmpCode = `${name}`.split(' ').map(word => word && word[0].toUpperCase()).join('')

        setRow({
            ...row,
            name: `${name}`,
            tmpCode
        })
    }

    const canSave = () => {

        return row.name && row.code && row.type
    }

    return (
        <Modal show={props.show}
            onHide={props.onHide}
            // size="lg"
            keyboard={true}
            backdrop="static"
            onEntered={() => document.getElementById("subjectInput")?.focus()}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('CNC_EDITOR:Title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row mb-3">
                    <label className='col-sm-2 col-form-label' htmlFor="firstName1">
                        {t('CNC_EDITOR:InputDescriptionLabel')}
                    </label>
                    <div className="col-sm-10">
                        <InputFormControl
                            value={row.name}
                            onChange={(value: string) => setNameAndCodePlaceholder(value)}
                            autoFocus={true}
                            id="subjectInput"
                        />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <label className='col-sm-2 col-form-label' htmlFor="firstName1">
                        {t('CNC_EDITOR:InputTypeLabel')}
                    </label>
                    <div className="col-sm-6">
                        <SelectFormControl
                            value={row.type}
                            onChange={(type: string) => handleChangeType(type)}
                        >
                            <option value="">
                                {t('CNC_EDITOR:InputTypeSelect')}
                            </option>
                            <option value="obra_construccion">
                                {t('RESOURCES_CNC:ProjectConstructionCNCType')}
                            </option>
                            <option value="diseno_oficina_tecnica_de_obra">
                                {t('RESOURCES_CNC:DesignOTOCNCType')}
                            </option>
                            <option value="administracion">
                                {t('RESOURCES_CNC:AdministrationCNCType')}
                            </option>
                            <option value="logistica">
                                {t('RESOURCES_CNC:LogisticsCNCType')}
                            </option>
                            <option value="condiciones_externas">
                                {t('RESOURCES_CNC:ExternalConditionsCNCType')}
                            </option>
                        </SelectFormControl>
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <label className='col-sm-2 col-form-label' htmlFor="firstName1">
                        {t('CNC_EDITOR:InputCodeLabel')}
                    </label>
                    <div className="col-sm-4">
                        <InputFormControl
                            placeholder={row.tmpCode}
                            value={row.code}
                            onChange={(value: string) => setRow({ ...row, code: value })}
                            readOnly
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => props.onHide()}
                    disabled={props.saving}
                >
                    <span className="icon-cancelar"></span> {' '}
                    {t('CNC_EDITOR:CancelButton')}
                </button>
                <button className="btn btn-primary" onClick={() => props.onSave(row)}
                    disabled={props.saving || !canSave()}
                >
                    <span className="icon-guardar"></span>
                    {props.saving ? t('CNC_EDITOR:SavingButton') : t('CNC_EDITOR:SaveButton')}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default CncEditor
