import React, { useEffect, useMemo, useState } from "react";
import Tab from 'react-bootstrap/esm/Tab';
import Nav from 'react-bootstrap/esm/Nav';
import Card from 'react-bootstrap/esm/Card';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import navigation from "../../navigation";

import { usePlan } from "../../Hooks/UsePlan";
import { useAuth } from "../../Hooks/UseAuth";
import useTranslation from "../../Hooks/UseTranslation";

import { TaskWithId } from "../../Types/Tasks";

import {
    filterByColorCodes,
    filterByDate,
    filterByGroups,
    filterByStatus,
    filterByTypes,
    filterByUsers,
    getItemFromTaskList,
    getNonWorkingDays,
    getRestrictionsResult,
    getRestrictionsTableItems
} from "../../Helpers/ProgrammationHelper";
import TeamsHelper from "../../Helpers/TeamsHelper";

import ProjectsActions from "../../Redux/Actions/ProjectsActions";
import TeamsActions from '../../Redux/Actions/TeamsActions';
import {
    setResourcesRestrictionsAction,
    setResourcesRestrictionsItemsAction
} from "../../Redux/Actions/ResourcesRestrictionsActions";
import InterfaceActions from "../../Redux/Actions/InterfaceActions";
import TasksActions from "../../Redux/Actions/TasksActions";
import { loadProgrammationAction } from "../../Redux/Actions/ProgrammationActions";

import MainLayout from "../../Components/MainLayout/MainLayout";
import MainLayoutContentBody from '../../Components/MainLayout/MainLayoutContentBody';
import MainLayoutContentHeader from '../../Components/MainLayout/MainLayoutContentHeader';
import TaskFilters from '../../Components/TaskFilters/TaskFilters';
import Icon from "../../Components/Icon/Icon";
import TaskEditor from "../../Components/TaskManager/TaskEditor";
import RestrictionsGroup from "../../Components/RestrictionsGroup/RestrictionsGroup";

import './ProjectResourcesRestrictionsPage.scss'

interface TableRowId {
    id: string;
    description: string;
    checked: boolean;
    itemId: string
    taskId: string
    taskName: string
    taskReleasesAt: string
    createdAt: string
    updatedAt: string
    creator: string
    responsible?: any
    responsibles?: any[]
    days: number
}

const ProjectResourcesRestrictionsPage = (props: any) => {

    const { t } = useTranslation()

    const project_id = props.match.params._id;

    const { ability, userData } = useAuth()
    const {
        tooManyRestrictions,
        showTooManyRestrictionsModal,
    } = usePlan()

    const dispatch = useDispatch();

    const projectLoadStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)
    const project = useSelector((state: any) => state.ProjectsReducer.rowData);
    const teams = useSelector((state: any) => state.TeamsReducer.result)
    const programmation = useSelector((state: any) => state.ProgrammationReducer)
    const restrictions = useSelector((state: any) => state.ResourcesRestrictionsReducer)

    const [ tab, setTab ] = useState('ABIERTAS');
    const [ editable, setEditable ] = useState(false);
    const [ editablePass, setEditablePass ] = useState(false);
    const [ showTaskFilters, setShowTaskFilters ] = useState(false)
    const [ showTaskEditor, setShowTaskEditor ] = useState(false)
    const [ showRestrictionsGroup, setShowRestrictionsGroup ] = useState(false)
    const [ mode, setMode ] = useState<'tasks' | 'groups'>('tasks')

    const [ shownTooManyRestrictionsModal, setShownTooManyRestrictionsModal ] = useState(!tooManyRestrictions)

    const [ task, setTask ] = useState<TaskWithId | undefined>()
    const [ restrictionsGroup, setRestrictionsGroup ] = useState([])
    const [ group, setGroup ] = useState({}) //TODO: use undefined instead of empty object
    const [ parentId, setParentId ] = useState('')

    const [ sortedColumn, setSortedColumn ] = useState<keyof TableRowId>('id');
    const [ sortOrder, setSortOrder ] = useState<'asc' | 'desc'>('asc');

    const nonWorkingDays = useMemo(() => {

        if (!project._id) return []

        return getNonWorkingDays(project.startsAt, project.endsAt, project)
    }, [ project._id, project.startsAt, project.endsAt ])

    const [ menu, submenu ] = navigation.getNavigation(
        props.match.params._id,
        'resources',
        'restrictions'
    );

    const savedFilters = localStorage.getItem(`${project_id}-restriction-filters`)

    const [ filters, setFilters ] = useState(savedFilters ? JSON.parse(savedFilters) : {
        user: '',
        state: '',
        team: '',
        group: '',
        code: '',
        type: '',
        start: '',
        end: '',
    })

    const [ open, close ] = useMemo(() => {

        const getAsGroups = (restrictions: any[]) => {

            const groups: any[] = []

            restrictions.forEach((restriction: any) => {

                const key = `${restriction.parentId}${restriction.description}`

                const found = groups.find((group: any) => group.key === key)

                if (found) {

                    found.items.push(restriction)
                } else {

                    groups.push({
                        ...restriction,
                        key,
                        taskId: restriction.parentPosition,
                        taskName: restriction.parentName || t('RESOURCES_RESTRICTIONS:WithoutGroup'),
                        creator: '',
                        taskReleasesAt: '',
                        days: '',
                        createdAt: '',
                        items: [ restriction ]
                    })
                }
            })

            return groups
        }

        const rows = [ ...restrictions.items ].sort((a, b) => {
            const valueA = a[ sortedColumn ];
            const valueB = b[ sortedColumn ];
            if (valueA < valueB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        })
        const openRows = rows.filter((row) => row.checked === false)
        const closeRows = rows.filter((row) => row.checked === true)

        let open: any[] = openRows
        let closed: any[] = closeRows

        if (mode === 'groups') {

            open = getAsGroups(openRows)
            closed = getAsGroups(closeRows)
        }

        return [ open, closed ]
    }, [ restrictions.items, sortedColumn, sortOrder, mode ])

    const hasActiveFilters = () => {

        const values = Object.values(filters)
        const found = values.findIndex(f => f !== '')

        return !!(found >= 0)
    }

    const handleChangeFilters = (filters: any) => {

        localStorage.setItem(`${project_id}-restriction-filters`, JSON.stringify(filters))

        loadRestrictions(filters)
        setFilters(filters)
    }

    const loadTeams = async () => {

        await dispatch(TeamsActions.getResultAction(project_id))
    }

    const loadProject = async () => {

        await dispatch(ProjectsActions.getRowAction(project_id))
    }

    const loadProgrammation = async () => {

        await dispatch(loadProgrammationAction(project_id))
    }

    const loadRestrictions = (withFilters?: any) => {

        if (programmation.loadStatus !== 'loaded') return

        const options = {
            ...programmation,
            list: JSON.parse(JSON.stringify(programmation.list)),
            tasks: JSON.parse(JSON.stringify(programmation.tasks)),
        }

        let restrictionsResult = getRestrictionsResult(options)

        const newFilters = withFilters || filters

        if (newFilters.team) {

            const teams_ids = newFilters.team.split(',')

            const teamsFound = teams.filter((team: any) => teams_ids.includes(team._id))

            const users_ids: string[] = []

            teamsFound.forEach((team: any) => {

              team.users.forEach((user: any) => {

                if (!users_ids.includes(user._id))
                  users_ids.push(user._id)
              })
            })

            restrictionsResult = filterByUsers(restrictionsResult, users_ids)
        } else if (newFilters.user)
            restrictionsResult = filterByUsers(restrictionsResult, newFilters.user.split(','))

        if (newFilters.group) {

            restrictionsResult = filterByGroups(restrictionsResult, newFilters.group.split(','))
        } else if (newFilters.code)
            restrictionsResult = filterByColorCodes(restrictionsResult, newFilters.code.split(','))

        if (newFilters.type)
            restrictionsResult = filterByTypes(restrictionsResult, newFilters.type.split(','))

        if (newFilters.state)
            restrictionsResult = filterByStatus(restrictionsResult, newFilters.state.split(','))

        if (newFilters.start || newFilters.end)
            restrictionsResult = filterByDate(restrictionsResult, newFilters.start, newFilters.end)

        dispatch(setResourcesRestrictionsAction(restrictionsResult))
    }

    const changeTab = (tab: string) => {
        if (!editable) {
            setTab(tab);
        }
    };

    const handleEditItem = async (item_data: any) => {

        if (mode === 'tasks') {

            const { item, parent } = getItemFromTaskList(restrictions.result, item_data._id)

            setTask(item)
            setGroup(parent || {})
            setParentId(parent?._id || '')
            setShowTaskEditor(true)
        } else {

            setRestrictionsGroup(item_data.items)
            setShowRestrictionsGroup(true)
        }
    }

    const handleCloseTaskEditor = () => {

        setTask(undefined)
        setGroup({})
        setShowTaskEditor(false)
    }

    const onTaskUpdated = async () => {

        setShowTaskEditor(false)
    }

    const onConfirmOrRejectTask = async (task_id: string, action: 'confirm' | 'reject') => {

        await dispatch(TasksActions.confirmOrReject(task_id, action))

        setShowTaskEditor(false)
    }

    const onDeleteTask = async (task: any) => {

        await dispatch(TasksActions.remove(task, project._id))

        setShowTaskEditor(false)
    }

    const handleSortByColumn = (column: keyof TableRowId) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedColumn(column);
            setSortOrder('asc');
        }
    };

    useEffect(() => {

        if (projectLoadStatus === 'none') loadProject()

        if (projectLoadStatus === 'loaded') {

            loadTeams()
        }
    }, [ projectLoadStatus ]);

    useEffect(() => {
        if (projectLoadStatus !== 'loaded') return

        if (project.ability) ability.update(project.ability)

        if (programmation.loadStatus === 'none') {

            loadProgrammation();
        }
    }, [ projectLoadStatus, restrictions.loadStatus ]);

    useEffect(() => {

        if (programmation.loadStatus !== 'loaded') return

        if (restrictions.loadStatus === 'none') {

            loadRestrictions()
        }
    }, [ programmation.loadStatus, restrictions.loadStatus ])

    useEffect(() => {

        if (restrictions.loadStatus !== 'loaded') return
        if (restrictions.loadItemsStatus !== 'none') return

        const options = {
            itemsList: restrictions.result
        }

        const tableItems = getRestrictionsTableItems(options)

        dispatch(setResourcesRestrictionsItemsAction(tableItems))

    }, [ restrictions.loadItemsStatus, restrictions.result ]);

    useEffect(() => {

        const projectLoaded = projectLoadStatus === 'loaded'
        const taskListLoaded = restrictions.loadStatus === 'loaded'

        if (!projectLoaded || !taskListLoaded) {

            dispatch(InterfaceActions.showLoader())
        } else {

            dispatch(InterfaceActions.hideLoader())
        }
    }, [ projectLoadStatus, restrictions.loadStatus ])

    useEffect(() => {

        if (tooManyRestrictions) {

          setShownTooManyRestrictionsModal(false)

          showTooManyRestrictionsModal(t('RESOURCES_RESTRICTIONS:TooManyRestrictionsAlert'), project, () => setShownTooManyRestrictionsModal(true))
        }
      }, [ tooManyRestrictions ])

    return (
        <>
            <MainLayout project={project} menuBar={menu} subMenuBar={submenu}>
                <MainLayoutContentHeader>
                    <div className='ul-widget__head-label flex-grow-1'>
                        <h3 className='ul-widget__head-title'>
                            {t('RESOURCES_RESTRICTIONS:Title')}
                        </h3>
                    </div>
                    <div className="ul-widget__head-nav">
                        <Tab.Container>
                            <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
                                <button className={`btn btn-link btn-link-header ${restrictions.loadStatus === 'loading' ? 'active' : ''} d-inline-block`}
                                    onClick={() => loadProgrammation()}
                                    disabled={restrictions.loadStatus === 'loading'}
                                >
                                    <Icon name="sync" fas fw spin={restrictions.loadStatus === 'loading'} /> {' '}
                                    <span className="d-none d-sm-inline"> {t('RESOURCES_RESTRICTIONS:ToolbarRefreshButton')}</span>
                                </button>
                                <button className={`btn btn-link btn-link-header ${mode === 'tasks' ? 'active' : ''}`}
                                    onClick={() => setMode('tasks')}
                                >
                                    <Icon name="tasks" fas fw /> {' '}
                                    <span className="d-none d-sm-inline"> {t('RESOURCES_RESTRICTIONS:ToolbarTasksButton')}</span>
                                </button>
                                <button className={`btn btn-link btn-link-header ${mode === 'groups' ? 'active' : ''}`}
                                    onClick={() => setMode('groups')}
                                >
                                    <Icon name="layer-group" fas fw /> {' '}
                                    <span className="d-none d-sm-inline"> {t('RESOURCES_RESTRICTIONS:ToolbarGroupsButton')}</span>
                                </button>
                                <button className={`btn btn-link btn-link-header ${hasActiveFilters() ? 'active' : ''}`}
                                    onClick={() => setShowTaskFilters(true)}>
                                    <span className="icon-filtro"></span>
                                    <span className="d-none d-sm-inline"> {t('RESOURCES_RESTRICTIONS:ToolbarFilterButton')}</span>
                                </button>
                                <Nav.Item as="li">
                                    <Nav.Link active={tab === 'ABIERTAS'} onClick={() => changeTab('ABIERTAS')}>
                                        <span className="icon-aceptar"></span> {' '}
                                        <span className="hide-on-mobile"> {t('RESOURCES_RESTRICTIONS:ToolbarOpenButton')} </span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link active={tab === 'CERRADAS'} onClick={() => changeTab('CERRADAS')}>
                                        <span className="icon-cancelar"></span> {' '}
                                        <span className="hide-on-mobile"> {t('RESOURCES_RESTRICTIONS:ToolbarClosedButton')} </span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Tab.Container>
                    </div>
                </MainLayoutContentHeader>
                <MainLayoutContentBody>
                    <Tab.Content className="no-padding">
                        <Tab.Pane active={tab === 'ABIERTAS'}>
                            <Card className="table-card">
                                <div>
                                    <div className="table-responsive">
                                        <table className="table card-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-rigth" style={{ width: 100 }}>
                                                        {t('RESOURCES_RESTRICTIONS:ListPositionColumn')}
                                                    </th>
                                                    <th className="text-rigth" style={{ width: 250 }}>
                                                        {mode === 'tasks' && t('RESOURCES_RESTRICTIONS:ListModeTaskColumn')}
                                                        {mode === 'groups' && t('RESOURCES_RESTRICTIONS:ListModeGroupColumn')}
                                                    </th>
                                                    <th
                                                        className="text-rigth clickable"
                                                        onClick={() => handleSortByColumn('createdAt')}
                                                        style={{ width: 150 }}
                                                    >
                                                        {t('RESOURCES_RESTRICTIONS:ListCreatedAtColumn')} ⇅
                                                        {sortedColumn === 'createdAt' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                                                    </th>
                                                    <th
                                                        className="text-rigth clickable"
                                                        onClick={() => handleSortByColumn('description')}
                                                    >
                                                        {t('RESOURCES_RESTRICTIONS:ListRestrictionColumn')} ⇅
                                                        {sortedColumn === 'description' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                                                    </th>
                                                    <th
                                                        className="clickable"
                                                        onClick={() => handleSortByColumn('creator')}
                                                    >
                                                        {t('RESOURCES_RESTRICTIONS:ListCreatorColumn')} ⇅
                                                        {sortedColumn === 'creator' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                                                    </th>
                                                    <th style={{ width: 150 }}>
                                                        {t('RESOURCES_RESTRICTIONS:ListReleasesAtColumn')}
                                                    </th>
                                                    <th style={{ width: 150 }}>
                                                        {t('RESOURCES_RESTRICTIONS:ListDaysOpenColumn')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!open.length && restrictions.loadStatus === 'loading' && (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            {t('RESOURCES_RESTRICTIONS:ListLoading')}
                                                        </td>
                                                    </tr>
                                                )}
                                                {!open.length && restrictions.loadStatus === 'loaded' && (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            {t('RESOURCES_RESTRICTIONS:ListNoOpenRestrictions')}
                                                        </td>
                                                    </tr>
                                                )}
                                                {open.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {row.taskId}
                                                        </td>
                                                        <td>
                                                            <div onClick={() => handleEditItem(row)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                }}
                                                            >
                                                                <strong>{row.taskName}</strong><br />
                                                            </div>
                                                        </td>
                                                        <td>{row.createdAt && moment(row.createdAt).format('DD/MM/YYYY')}</td>
                                                        <td>{row.description}</td>
                                                        <td>
                                                            {row.creator}
                                                        </td>
                                                        <td>
                                                            {row.taskReleasesAt && moment.utc(row.taskReleasesAt).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td className="text-right">
                                                            {row.days}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Card>
                        </Tab.Pane>
                        <Tab.Pane active={tab === 'CERRADAS'}>
                            <Card className="table-card">
                                <div>
                                    <div className="table-responsive">
                                        <table className="table card-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-rigth" style={{ width: 100 }}>
                                                        {t('RESOURCES_RESTRICTIONS:ListPositionColumn')}
                                                    </th>
                                                    <th className="text-rigth" style={{ width: 250 }}>
                                                        {t('RESOURCES_RESTRICTIONS:ListNameColumn')}
                                                    </th>
                                                    <th
                                                        className="text-rigth clickable"
                                                        onClick={() => handleSortByColumn('createdAt')}
                                                        style={{ width: 150, verticalAlign: 'middle' }}
                                                    >
                                                        {t('RESOURCES_RESTRICTIONS:ListCreatedAtColumn')} ⇅
                                                        {sortedColumn === 'createdAt' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                                                    </th>
                                                    <th
                                                        className="text-rigth clickable"
                                                        onClick={() => handleSortByColumn('description')}
                                                        style={{ verticalAlign: 'middle' }}
                                                    >
                                                        {t('RESOURCES_RESTRICTIONS:ListRestrictionColumn')} ⇅
                                                        {sortedColumn === 'description' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                                                    </th>
                                                    <th
                                                        className="clickable"
                                                        onClick={() => handleSortByColumn('creator')}
                                                        style={{ verticalAlign: 'middle' }}
                                                    >
                                                        {t('RESOURCES_RESTRICTIONS:ListCreatorColumn')} ⇅
                                                        {sortedColumn === 'creator' && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                                                    </th>
                                                    <th style={{ width: 150, verticalAlign: 'middle' }}>
                                                        {t('RESOURCES_RESTRICTIONS:ListActualReleaseDateColumn')}
                                                    </th>
                                                    <th style={{ width: 150, verticalAlign: 'middle' }}>
                                                        {t('RESOURCES_RESTRICTIONS:ListDaysOpenColumn')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!close.length && restrictions.loadStatus === 'loading' && (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            {t('RESOURCES_RESTRICTIONS:ListLoading')}
                                                        </td>
                                                    </tr>
                                                )}
                                                {!close.length && restrictions.loadStatus === 'loaded' && (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            {t('RESOURCES_RESTRICTIONS:ListNoClosedRestrictions')}
                                                        </td>
                                                    </tr>
                                                )}
                                                {close.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{row.taskId}</td>
                                                        <td>
                                                            <div onClick={() => handleEditItem(row)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                }}
                                                            >
                                                                <strong>{row.taskName}</strong><br />
                                                            </div>
                                                        </td>
    <td>
                                                            {moment(row.createdAt).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td>{row.description}</td>
                                                        <td>
                                                            {row.creator}
                                                        </td>
                                                        <td>
                                                            {moment.utc(row.updatedAt).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td>
                                                            {row.days}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Card>
                        </Tab.Pane>
                    </Tab.Content>
                </MainLayoutContentBody>
            </MainLayout>

            <TaskFilters
                show={showTaskFilters}
                type="restrictions"
                filters={filters}
                onChange={handleChangeFilters}
                onHide={() => setShowTaskFilters(false)}
            />

            <TaskEditor
                show={showTaskEditor && (!tooManyRestrictions || shownTooManyRestrictionsModal)}
                task={task}
                group={group}
                taskList={restrictions.result}
                nonWorkingDays={nonWorkingDays}
                project={project}
                projectMembers={TeamsHelper.getAllMembersFromProject(teams)}
                projectStartDate={project.startsAt}
                projectEndDate={project.endsAt}
                onHide={() => handleCloseTaskEditor()}
                onUpdated={onTaskUpdated}
                onConfirmOrReject={onConfirmOrRejectTask}
                onDelete={(data: any) => onDeleteTask(data)}
                canConfirmOrReject={ability.can('confirm', 'task')}
                canDelete={ability.can('delete', 'task')}
                canUpdate={() => {

                const isPl4nner = ability.can('manage', 'all')
                const isAssistant = project.assistants?.includes(userData?.id)
                const isCollaborator = task?.responsibles.map((r: any) => r._id).includes(userData?.id)

                return isPl4nner || isAssistant || isCollaborator
                }}
            />

            <RestrictionsGroup
                show={showRestrictionsGroup}
                group={restrictionsGroup}
                openOrClosed={tab}
                onHide={() => {

                    setShowRestrictionsGroup(false)
                }}
            />
        </>
    );
};

export default ProjectResourcesRestrictionsPage;

