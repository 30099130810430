
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import config from "../../../config";

import useTranslation from "../../../Hooks/UseTranslation";

import TeamsService from "../../../Services/TeamsService";
import TeamsActions from "../../../Redux/Actions/TeamsActions";

import Icon from "../../Icon/Icon";

interface Props {
  show: boolean
  title: string;
  observers: any[];
  maxObserversAllowed: number;
  isMyPlan: boolean;
  onHide: () => void;
}

const TooManyObserversModal = (props: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const project = useSelector((state: any) => state.ProjectsReducer.rowData)

  const [ processing, setProcessing ] = useState<string[]>([])

  const handleRemove = async (user_id: any) => {

    setProcessing([ ...processing, user_id ])
    try {
      await TeamsService.removeUserFromAllTeams(user_id, project._id)

      await dispatch(TeamsActions.getResultAction(project._id))

      setProcessing(processing.filter((id) => id !== user_id))
    } catch (error) {
      console.error('Error eliminando al observador:', error)
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('TOO_MANY_OBSERVERS_MODAL:ContentA')} {props.maxObserversAllowed} {t('tTOO_MANY_OBSERVERS_MODAL:ContentB')}
        </p>
        {props.isMyPlan && (
          <>
            <p>
              <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
                <i className="fas fa-external-link-alt"></i> {t('TOO_MANY_OBSERVERS_MODAL:ContentC')}
              </a> {t('TOO_MANY_OBSERVERS_MODAL:ContentD')}
            </p>
            <p>
              {t('TOO_MANY_OBSERVERS_MODAL:ContentE')}
            </p>
            <div className="table-responsive">
              <div className="list-group">
                {props.observers.map((observer) => (
                  <div
                    key={observer._id}
                    className={`list-group-item`}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <strong>{observer.name} {observer.surname}</strong>
                      </div>
                      <div className="btns-group">
                        {processing.includes(observer._id) && (
                          <span>
                            <i className="fas fa-spinner fa-spin"></i>
                            <span className="d-none d-sm-inline"> {t('TOO_MANY_OBSERVERS_MODAL:Processing')}</span>
                          </span>
                        )}
                        {!processing.includes(observer._id) && (
                          <>
                            <button className="btn btn-danger"
                              onClick={() => handleRemove(observer._id)}
                            >
                              <Icon name="eliminar" />
                              <span className="d-none d-sm-inline"> {t('TOO_MANY_OBSERVERS_MODAL:Delete')}</span>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          {t('TOO_MANY_OBSERVERS_MODAL:Close')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TooManyObserversModal
