
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { Link, useLocation } from 'react-router-dom'

import { useAuth } from '../../Hooks/UseAuth'

import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'

import Toast from '../../Components/Toast/Toast'

import SessionActions from '../../Redux/Actions/SessionActions'
import useTranslation from '../../Hooks/UseTranslation'


const LoginPage = () => {

    const { t } = useTranslation()

    const { login, loginError } = useAuth()

    const dispatch = useDispatch()
    const location = useLocation()


    const [ dispatching, setDispatching ] = useState(false)

    const [ username, setUsername ] = useState('')
    const [ usernameError, setUsernameError ] = useState('')

    const [ password, setPassword ] = useState('')
    const [ passwordError, setPasswordError ] = useState('')

    const [ swalShwon, setSwalShown ] = useState(false)

    const handleChange = (name: string, value: string) => {

        if (name === 'username') {

            setUsername(value)

            if (value === '') {

                setUsernameError(t('LOGIN:EnterUsername'))
            } else {

                setUsernameError('')
            }
        } else if (name === 'password') {

            setPassword(value)

            if (value === '') {

                setPasswordError(t('LOGIN:EnterPassword'))
            } else {

                setPasswordError('')
            }
        }
    }

    const validateForm = () => {

        let isCorrect: boolean = true

        if (username === '') {

            setUsernameError(t('LOGIN:EnterUsername'))
            isCorrect = false
        }

        if (password === '') {

            setPasswordError(t('LOGIN:EnterPassword'))
            isCorrect = false
        }

        return isCorrect
    }

    const handleSubmit = async () => {

        if (!validateForm()) {

            return
        }

        setDispatching(true)

        const loginData = {
            username,
            password
        }

        await login(loginData)

        setDispatching(false)
    }

    const confirmed = new URLSearchParams(location.search).get('confirmed')

    if (confirmed === '' && !swalShwon) {

        Swal.fire({
            title: t('LOGIN:UserConfirmedTitle'),
            text: t('LOGIN:UserConfirmedContent'),
            icon: 'success',
            confirmButtonText: '¡Cerrar!',
            willClose: () => setSwalShown(true)
        })
    }

    useEffect(() => {

        const evalLoginError = () => {

            let toastTitle: string = ''
            let toastContent: string = ''
            let showToast: boolean = false

            if (loginError === 'not_verified') {

                Swal.fire({
                    title: t('LOGIN:UserConfirmedTitle'),
                    text: t('LOGIN:UserConfirmedContent'),
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: t('LOGIN:ResendEmail'),
                    cancelButtonText: t('LOGIN:Close'),
                    willClose: () => setSwalShown(true)
                }).then((result) => {
                    if (result.isConfirmed) {

                        const reConfirmData = {
                            username,
                        }

                        dispatch(SessionActions.reConfirmAction(reConfirmData))
                        Toast.show(
                            t('LOGIN:MailResentTitle'),
                            t('LOGIN:MailResentContent')
                        );
                    }
                });

            } else if (loginError === 'user_not_found' || loginError === 'wrong_credentials') {
                toastTitle = t('LOGIN:WrongCredentialsTitle');
                toastContent = t('LOGIN:WrongCredentialsContent');
                showToast = true;
            }

            if (!showToast) {

                return
            }

            Toast.show(toastTitle, toastContent)
        }

        evalLoginError()
    }, [ loginError ])

    return (
        <AuthLayout>
            <div className="card 0-hidden">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="p-4">
                            <div className="logo text-center mb-4">
                                <img src="/images/logo.svg" alt="" />
                            </div>
                            <div>
                                <h1 className="h1-title mb-1 text-14">{t('LOGIN:Login-title')}</h1>

                                <form action="">
                                    <div className="form-group">
                                        <input type="text"
                                            className="form-control"
                                            placeholder={t('LOGIN:Username')}
                                            value={username}
                                            onChange={(e: any) => handleChange('username', e.target.value)}
                                            autoFocus
                                        />
                                        {usernameError !== '' && (
                                            <span className="error-message">{usernameError}</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input type="password"
                                            className="form-control"
                                            placeholder={t('LOGIN:Password')}
                                            value={password}
                                            onChange={(e: any) => handleChange('password', e.target.value)}
                                        />
                                        {passwordError !== '' && (
                                            <span className="error-message">{passwordError}</span>
                                        )}
                                    </div>
                                    <button
                                        className="btn btn-block mt-2 btn-handle-login"
                                        onClick={() => handleSubmit()}
                                        disabled={dispatching}
                                    >
                                        {!dispatching ? t('LOGIN:LoginButtonIdle') : t('LOGIN:LoginButtonLogingIn')}
                                    </button>
                                    <Link
                                        to="/register"
                                        className="btn btn-block mt-2 btn-goto-register"
                                    >
                                        {t('LOGIN:RegisterButton')}
                                    </Link>
                                </form>
                                <div className="mt-3 text-center">
                                    <Link to="/forgot-password" className="btn btn-light btn-block">
                                        <u>{t('LOGIN:ForgotPasswordButton')}</u>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default LoginPage
