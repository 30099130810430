import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import useTranslation from "../../Hooks/UseTranslation";

import InputFormControl from "../InputFormControl/InputFormControl";
import InputMaskedControl from "../InputMaskedControl/InputMaskedControl";

const ProjectCreator = (props: any) => {

  const { t } = useTranslation()

  const [state, setState] = useState({
    canCreate: false,
    project: {
      name: "",
      shortName: "",
      address: "",
      cost: "",
      startsAt: "",
      endsAt: "",
      lookahead: 4,
      method: "Gantt",
    },
  });

  const [titleFocused, setTitleFocused] = useState(false);

  const handleChange = (field: string, value: any) => {
    const project: any = state.project;

    let canCreate = state.canCreate;

    if (field === "cost") {
      const cost = parseInt(value, 10);

      if (value === "" || value === "0" || isNaN(cost)) {
        canCreate = false;
      } else {
        canCreate = true;
      }
    }

    project[field] = value;

    setState({
      ...state,
      project,
      canCreate,
    });
  };

  const validate = () => {
    const project: any = state.project;

    if (project.name === "") {
      alert(t('PROJECT_CREATOR:NameMissingAlert'));
      return false;
    }

    if (project.shortName === "") {
      alert(t('PROJECT_CREATOR:ShortNameMissingAlert'));
      return false;
    }

    if (project.cost === "" || project.cost === "0") {
      alert(t('PROJECT_CREATOR:CostMissingAlert'));
      return false;
    }

    const cost = parseInt(project.cost, 10);

    if (isNaN(cost)) {
      alert(t('PROJECT_CREATOR:InvalidCostAlert'));
      return false;
    }

    if (project.startsAt === "") {
      alert(t('PROJECT_CREATOR:SelectStartDateAlert'));
      return false;
    }

    if (project.endsAt === "") {
      alert(t('PROJECT_CREATOR:SelectEndDateAlert'));
      return false;
    }

    return true;
  };

  const saveProject = () => {
    if (!validate()) {
      return;
    }

    props.onSave(state.project);
  };

  useEffect(() => {
    if (!titleFocused) {
      setTitleFocused(true);
    }
  }, [props.show]);

  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        onEntered={() => document.getElementById("subjectInput")?.focus()}>
        <Modal.Header>
          <Modal.Title>
            {t('PROJECT_CREATOR:Title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-md-8 mb-3">
              <label htmlFor="name">
                {t('PROJECT_CREATOR:InputNameLabel')}
              </label>
              <InputFormControl
                placeholder={t('PROJECT_CREATOR:InputNamePlaceholder')}
                value={state.project.name}
                onChange={(value: string) => handleChange("name", value)}
                onBlur={() => setTitleFocused(false)}
                readOnly={false}
                autoFocus={true}
                id="subjectInput"
              />
            </div>
            <div className="form-group col-md-4 mb-3">
              <label htmlFor="short-name">
                {t('PROJECT_CREATOR:InputShortNameLabel')}
              </label>
              <InputFormControl
                id="short-name"
                placeholder={t('PROJECT_CREATOR:InputShortNamePlaceholder')}
                value={state.project.shortName}
                onChange={(value: string) => handleChange("shortName", value)}
              />
            </div>
            <div className="form-group col-md-12 mb-3">
              <label htmlFor="address">
                {t('PROJECT_CREATOR:InputAddressLabel')}
              </label>
              <InputFormControl
                id="address"
                placeholder={t('PROJECT_CREATOR:InputAddressPlaceholder')}
                value={state.project.address}
                onChange={(value: string) => handleChange("address", value)}
              />
            </div>
            <div className="form-group col-md-3 mb-3">
              <label htmlFor="cost">
                {t('PROJECT_CREATOR:InputCostLabel')}
              </label>
              <InputMaskedControl
                id="cost"
                placeholder={t('PROJECT_CREATOR:InputCostPlaceholder')}
                value={state.project.cost}
                onChange={(e: any) => handleChange("cost", e.target.value)}
              />
            </div>
            <div className="col-md-3 form-group mb-3">
              <label htmlFor="startsAt">
                {t('PROJECT_CREATOR:InputStartLabel')}
              </label>
              <InputFormControl
                type="date"
                id="startsAt"
                placeholder={t('PROJECT_CREATOR:InputStartPlaceholder')}
                value={state.project.startsAt}
                onChange={(value: string) => handleChange("startsAt", value)}
              />
            </div>
            <div className="col-md-3 form-group mb-3">
              <label htmlFor="fin">
                {t('PROJECT_CREATOR:InputEndLabel')}
              </label>
              <InputFormControl
                type="date"
                id="endsAt"
                placeholder={t('PROJECT_CREATOR:InputEndPlaceholder')}
                value={state.project.endsAt}
                onChange={(value: string) => handleChange("endsAt", value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => props.onHide()}>
            <span className="icon-cancelar"></span> {' '}
            {t('PROJECT_CREATOR:CancelButton')}
          </button>
          &nbsp;
          <button
            className="btn btn-raised btn-link active"
            onClick={() => saveProject()}
            disabled={!state.canCreate}>
            <span className="icon-guardar"></span> {' '}
            {t('PROJECT_CREATOR:CreateButton')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectCreator;
