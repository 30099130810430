
import React, { useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import moment from 'moment';

import useTranslation from '../../Hooks/UseTranslation';

import { importFromExcel } from '../../Services/MacroService';

import DataGrid, { Row } from '../DataGrid/DataGrid';
import ExcelSheetPicker from '../ExcelSheetPicker/ExcelSheetPicker';

import './TasksImporter.scss'

type Props = {
  show: boolean
  projectId: string
  onHide: () => void
  onImported: () => void
}

const TasksImporter = (props: Props) => {

  const { t } = useTranslation()

  const columns = [
    {
      key: 'id',
      name: t('TASK_IMPORTER:IdColumn'),
      width: 60
    },
    {
      key: 'title',
      name: t('TASK_IMPORTER:TitleColumn'),
      width: 400
    },
    {
      key: 'startsAt',
      name: t('TASK_IMPORTER:StartsAtColumn'),
      width: 160
    },
    {
      key: 'endsAt',
      name: t('TASK_IMPORTER:EndsAtColumn'),
      width: 160
    },
  ]

  const [ rows, setRows ] = React.useState<Row[]>([])

  const [ fileSelected, setFileSelected ] = React.useState<boolean>(false)

  const [ importing, setImporting ] = useState<boolean>(false)

  const project = useSelector((state: any) => state.ProjectsReducer.rowData);

  const finProyecto = moment(project.endsAt);
  const empiezaProyecto = moment(project.startsAt);

  const getIdParts = (id: string) => {

    return id.replace(',', '.').split('.').map((part) => Number(part));
  }

  const validateStartDate = (fecha: string) => {
    const startDate = moment(fecha, 'DD/MM/YYYY');

    return startDate.isSameOrAfter(empiezaProyecto)
  }

  const validateEndDate = (fecha: string) => {
    const endDate = moment(fecha, 'DD/MM/YYYY');

    return finProyecto.isSameOrAfter(endDate)
  }

  const validateOrder = (id: string, prevId: string) => {

      const idParts = getIdParts(id);
      const prevIdParts = getIdParts(prevId);

      for (let i = 0; i < Math.min(idParts.length, prevIdParts.length); i++) {
        const num1 = idParts[i];
        const num2 = prevIdParts[i];

        if (num1 < num2) {
            return false;
        } else if (num1 > num2) {
            return true;
        }
    }

    return idParts.length >= prevIdParts.length;
  }

  function verifyConsecutive(id: string, nextId: string) {

    const idParts = getIdParts(id);
    const nextIdParts = getIdParts(nextId);

    const minLength = Math.min(idParts.length, nextIdParts.length);
    for (let i = 0; i < minLength; i++) {
        if (idParts[i] !== nextIdParts[i]) {
            if (i === minLength - 1 && nextIdParts[i] === idParts[i] + 1) {
                return true;
            }
            return false;
        }
    }

    if (nextIdParts.length === idParts.length + 1 && nextIdParts[nextIdParts.length - 1] === 1) {
        return true;
    }

    return false;
  }

  const validateData = (rows: Row[]): Row[] => {

    const groups: string[] = []

    const newRows = rows.map((row, rowIndex) => {

      const isGroup = !row.startsAt && !row.endsAt;

      if (!row.errors) row.errors = []

      if (!isGroup) {

        const startsAt = moment(row.startsAt, 'DD/MM/YYYY');
        const endsAt = moment(row.endsAt, 'DD/MM/YYYY');

        const isValidStartDate = validateStartDate(row.startsAt);
        const isValidEndDate = validateEndDate(row.endsAt);

        if (!isValidStartDate)
          row.errors.push({ field: 'startsAt', message: t('TASK_IMPORTER:InvalidStartDate') })

        if (!isValidEndDate)
          row.errors.push({ field: 'endsAt', message: t('TASK_IMPORTER:InvalidEndDate') })

        if (isValidStartDate && isValidEndDate) {

          if (endsAt.isBefore(startsAt)) {
            row.errors.push({
              field: 'endsAt',
              message: t('TASK_IMPORTER:EndDateIsBeforeStartDate')
            })
          }
        }
      }

      if (row.id) {

        const idParts = getIdParts(row.id);

        row.id = idParts.join('.');

        if (isGroup && !groups.includes(row.id))
          groups.push(row.id);

        const alreadyExists = rows.some((r, i) => i !== rowIndex && r.id === row.id);

        const isSubItem = idParts.length > 1;

        const parentId = idParts.slice(0, -1).join('.');

        const parent = groups.find((g) => g === parentId);

        if (alreadyExists) {
          row.errors.push({ field: 'id', message: t('TASK_IMPORTER:IdAlreadyExists') });
        }

        if (isSubItem && parentId && !parent) {
          row.errors.push({ field: 'id', message: t('TASK_IMPORTER:IdHasNotAParent') });
        }

        if (rowIndex > 0) {

          const isGreater = validateOrder(row.id, rows[ rowIndex - 1 ].id);

          const isConsecutive = verifyConsecutive(rows[ rowIndex - 1 ].id, row.id);

          if (!isGreater) {
            row.errors.push({ field: 'id', message: t('TASK_IMPORTER:IdIsLesserThanPrevious') });
          }

          if (!isConsecutive) {
            row.errors.push({ field: 'id', message: t('TASK_IMPORTER:IdISNotConsecutiveWithPrevious') });
          }
        }
      }

      return row;
    });

    return newRows;
  };

  const handleExcelRead = (content: any) => {

    const items: any = []

    content.forEach((row: any, index: number) => {
      // ignore column names
      if (index === 0) {
        return;
      }

      const [ id, title, startsAt, endsAt ] = row

      const comienza = startsAt ? moment(startsAt, 'DD/MM/YYYY') : null;
      const termina = endsAt ? moment(endsAt, 'DD/MM/YYYY') : null;

      if (!id || !title) return

      const item = {
        id,
        title,
        startsAt: comienza ? comienza?.format('DD/MM/YYYY') : '',
        endsAt: termina ? termina?.format('DD/MM/YYYY') : '',
        errors: [],
      };

      items.push(item)
    });

    validateData(items)

    setRows(items)

    setFileSelected(true);
  };

  const handleCancel = () => {

    setRows([])
    setFileSelected(false);
    props.onHide()
  }

  const handleImport = async () => {

    setImporting(true)

    await importFromExcel(props.projectId, rows)

    setImporting(false)

    props.onImported()
  }

  const onDataModified = (data: Row[]) => {
    const validData = validateData(data);
    setRows(validData);
  };

  const isAnyTaskInvalid = useMemo(() => {
    const invalid = rows.filter((item: any) => item.errors.length)

    return !!invalid.length
  }, [ rows ])

  return (
    <>
      <Modal
        show={props.show && fileSelected}
        onHide={props.onHide}
        dialogClassName='modal-tasks-importer'
        backdrop='static'
        keyboard
      >
        <Modal.Body>
          <div className="ul-widget__head">
            <div className="ul-widget__head-label flex-grow-1">
              <h4 className="ul-widget__head-title">
                {t('TASK_IMPORTER:Title')}
              </h4>
            </div>
          </div>
          <div className="ul-widget__head-divisor"></div>
          <div className="ul-widget__body">
            <DataGrid
              columns={columns}
              rows={rows}
              onRowsChange={(rows) => onDataModified(rows)}
              hasErrors={isAnyTaskInvalid}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            {t('TASK_IMPORTER:CancelButton')}
          </Button>
          <Button variant="primary" onClick={handleImport} disabled={isAnyTaskInvalid || importing}>
            {!importing && t('TASK_IMPORTER:ImportButton')}
            {importing && t('TASK_IMPORTER:ImportingButton')}
          </Button>
        </Modal.Footer>
      </Modal>

      <ExcelSheetPicker
        show={props.show && !fileSelected}
        title={t('TASK_IMPORTER:SelectFile')}
        onHide={handleCancel}
        onFileRead={handleExcelRead}
      />
    </>
  )
}

export default TasksImporter
