import React, { ReactNode } from "react"
import { Modal } from "react-bootstrap"

import useTranslation from "../../Hooks/UseTranslation"

import './OptionsDialog.scss'

export interface Option {
  icon?: ReactNode
  label: string
  color?: string
  active?: boolean
  disabled?: boolean
  onClick: () => void
}

export interface OptionsDialogProps {
  show: boolean
  closeButton?: boolean
  title: string
  options: Option[]
  onHide: () => void
}

const OptionsDialog = (props: OptionsDialogProps) => {

  const { t } = useTranslation()

  return (
    <Modal show={props.show} onHide={props.onHide} size="sm" centered>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="options-dialog-list">
          {props.options.map((option: Option, index: number) => (
            <button
              key={index}
              className={`option-item ${option.active ? 'active' : ''}`}
              onClick={() => {
                option.onClick()
                props.onHide()
              }}
              style={{
                color: option.color
              }}
              disabled={option.disabled}
            >
              <div className="icon">
                {option.icon}
              </div>
              <div className="text">
                {option.label}
              </div>
            </button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => props.onHide()}>
          <span className="icon-cancelar"></span> {' '}
          {t('OPTIONS_DIALOG:CancelButton')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default OptionsDialog

