
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, FormCheck, FormControl, FormGroup, FormLabel, Modal, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useDispatch } from "react-redux";

import useTranslation from "../../Hooks/UseTranslation";

import { addWorkableDays, getDiffDays } from "../../Helpers/DateFunctions";

import TasksActions from "../../Redux/Actions/TasksActions";

interface Props {
  show: boolean
  tasks: any[]
  nonWorkingDays: string[]
  onCancel: () => void
  onSaved: () => void
}

const ReScheduleMany = (props: Props) => {

  const { t } = useTranslation()

  const daysRef: any = useRef<HTMLInputElement>(null)

  const dispatch = useDispatch()

  const [ bodyHeight, setBodyHeight ] = React.useState<number>(0)

  const [ mode, setMode ] = useState<'all' | 'edit'>('all')

  const [ tasks, setTasks ] = useState<any[]>([])

  const [ days, setDays ] = useState<number>(0)

  const [ saving, setSaving ] = useState(false)

  const handleCancel = () => {

    setMode('all')
    props.onCancel()
  }

  const getExecuteDays = (start: string, end: string) => {

    return getDiffDays(start, end, props.nonWorkingDays)
  }

  const changeTaskStartDate = (task_id: string, startDate: string) => {

    const newTasks = tasks.map((task: any) => {

      if (task._id === task_id) {

        task.startsAt = startDate

        task.endsAt = addWorkableDays(task.startsAt, task.executeDays - 1, props.nonWorkingDays)
      }

      return task
    })

    setTasks(newTasks)
  }

  const changeTaskEndDate = (task_id: string, endDate: string) => {

    const newTasks = tasks.map((task: any) => {

      if (task._id === task_id) {

        task.endsAt = endDate

        task.executeDays = getDiffDays(task.startsAt, task.endsAt, props.nonWorkingDays)
      }

      return task
    })

    setTasks(newTasks)
  }

  const handleReSchedule = async () => {

    await dispatch(TasksActions.reScheculeMany(tasks))

    props.onSaved()
  }

  useEffect(() => {

    setTasks(JSON.parse(JSON.stringify(props.tasks)))

    setDays(0)
  }, [ props.tasks, mode ])

  useEffect(() => {

    if (!daysRef.current) return

    if (mode === 'all') {

      daysRef.current.value = ''
      daysRef.current.focus()
    }
  }, [ mode ])

  useEffect(() => {

    const reScheduleAll = (days = 0) => {

      const rescheduled = props.tasks.map((task: any) => {

        const startsAt = addWorkableDays(task.startsAt, days, props.nonWorkingDays)
        const endsAt = addWorkableDays(task.endsAt, days, props.nonWorkingDays)

        return {
          ...task,
          startsAt,
          endsAt,
        }
      })

      setTasks(rescheduled)
    }

    if (props.tasks.length)
      reScheduleAll(days)
  }, [ days ])

  useEffect(() => {

    const getBodyHeight = () => {

      const modalHeight = window.innerHeight - 56
      const modalFooterHeight = 64

      const modalTitleHeight = 40

      const gridHeaderHeight = 0

      return modalHeight - modalFooterHeight - modalTitleHeight - gridHeaderHeight - 65
    }

    const handleResize = () => {

      const bodyHeight = getBodyHeight()

      setBodyHeight(bodyHeight)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Modal
      show={props.show}
      size="lg"
      backdrop
      keyboard={false}
      onShow={() => {

        if (!daysRef.current) return

        daysRef.current.select()
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {t('RESCHEDULE_MANY:Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{
          overflowY: 'auto',
          maxHeight: bodyHeight,
        }}>
          <FormGroup as={Row}>
            <Col md={4}>
              <FormCheck
                type="radio"
                label={t('RESCHEDULE_MANY:DaysLabel')}
                checked={mode === 'all'}
                onChange={() => setMode('all')}
              />
            </Col>
            <Col md={2}>
              <FormControl
                ref={daysRef}
                type="number"
                autoFocus={mode === 'all'}
                disabled={mode !== 'all'}
                placeholder="0"
                className="text-right"
                value={days || ''}
                onChange={(e: any) => setDays(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <FormCheck
              type="radio"
              label={t('RESCHEDULE_MANY:IndividuallyLabel')}
              checked={mode === 'edit'}
              onChange={() => setMode('edit')}
            />
          </FormGroup>
          <Table>
            <thead>
              <tr>
                <th>
                  {t('RESCHEDULE_MANY:TaskColumn')}
                </th>
                <th>
                  {t('RESCHEDULE_MANY:DaysColumn')}
                </th>
                <th style={{ width: 160 }}>
                  {t('RESCHEDULE_MANY:StartColumn')}
                </th>
                <th style={{ width: 160 }}>
                  {t('RESCHEDULE_MANY:EndColumn')}
                </th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, i) => (
                <tr key={task._id}>
                  <td>
                    {task.name}
                  </td>
                  <td>
                    {getExecuteDays(task.startsAt, task.endsAt)}
                  </td>
                  <td>
                    {mode === 'all' && moment(task.startsAt).format('DD/MM/YYYY')}
                    {mode === 'edit' && (
                      <FormControl
                        type="date"
                        value={task.startsAt}
                        onChange={(e: any) => changeTaskStartDate(task._id, e.target.value)}
                      />
                    )}
                  </td>
                  <td>
                    {mode === 'all' && moment(task.endsAt).format('DD/MM/YYYY')}
                    {mode === 'edit' && (
                      <FormControl
                        type="date"
                        value={task.endsAt}
                        onChange={(e: any) => changeTaskEndDate(task._id, e.target.value)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-secondary" onClick={handleCancel}>
          <span className="icon-cancelar"></span> {' '}
          {t('RESCHEDULE_MANY:CancelButton')}
        </Button>
        <Button className="btn-primary" onClick={() => handleReSchedule()} disabled={saving}>
          <span className="icon-guardar" ></span> {' '}
          {saving ? t('RESCHEDULE_MANY:ReschedulingButton') : t('RESCHEDULE_MANY:RescheduleButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReScheduleMany
